import api from '../utils/functions/api/index';
import endpoints from '../utils/functions/api/endpoints';

const {
  get, post, put, deletes
} = api;

export const GET_SELLERS = 'GET_SELLERS';
export const SINGLE_SELLER = 'SINGLE_SELLER';
export const UPDATE_SELLER = 'UPDATE_SELLER';
export const ADD_SELLER = 'ADD_SELLER';

export const getAllSellers = () => ({
  type: GET_SELLERS,
  payload: get({ url: endpoints.sellersAll })
    .then((res) => ({ allSellers: res })),
});

export const singleSeller = (id) => ({
  type: SINGLE_SELLER,
  payload: get({ url: endpoints.sellersSingle(id) })
    .then((res) => ({ singleSeller: res }))
});

export const addSeller = (body) => ({
  type: ADD_SELLER,
  payload: post({ url: endpoints.createSellers, body })
});

export const updateSeller = (id, body) => (
  put({ url: endpoints.editSeller(id), body })
);

export const changeStatusSeller = (id) => async (dispatch) => {
  const fetch = await deletes({ url: endpoints.deleteSeller(id) });
  const request = get({ url: endpoints.sellersAll })
    .then((res) => ({ allSellers: res }));

  request
    .then(() => {
      dispatch({
        type: GET_SELLERS,
        payload: request,
      });
    });

  return fetch;
};
