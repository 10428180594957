import {
  GET_BINGOS, SINGLE_BINGO, UPDATE_BINGO
} from '../actions/bingoActions';

const INITIAL_STATE = {
  isFetching: false,
  didInvalidate: false,
  bingosAll: [],
  codeError: null,
  isFetchingSingle: false,
  didInvalidateSingle: false,
  singleBingo: {},
  updateBingo: {},
};

const bingoReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {

  case `${GET_BINGOS}_REJECTED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: true,
      codeError: action.payload.codeError
    };
  case `${GET_BINGOS}_PENDING`:
    return {
      ...state,
      isFetching: true,
      didInvalidate: false,
    };
  case `${GET_BINGOS}_FULFILLED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: false,
      bingosAll: action.payload.bingosAll
    };


  case `${SINGLE_BINGO}_REJECTED`:
    return {
      ...state,
      isFetchingSingle: false,
      didInvalidateSingle: true,
      codeError: action.payload.codeError
    };
  case `${SINGLE_BINGO}_PENDING`:
    return {
      ...state,
      isFetchingSingle: true,
      didInvalidateSingle: false,
    };
  case `${SINGLE_BINGO}_FULFILLED`:
    return {
      ...state,
      isFetchingSingle: false,
      didInvalidateSingle: false,
      singleBingo: action.payload.singleBingo
    };

  case `${UPDATE_BINGO}_REJECTED`:
    return {
      ...state,
      isFetchingSingle: false,
      didInvalidateSingle: true,
      codeError: action.payload.codeError
    };
  case `${UPDATE_BINGO}_PENDING`:
    return {
      ...state,
      isFetchingSingle: true,
      didInvalidateSingle: false,
    };
  case `${UPDATE_BINGO}_FULFILLED`:
    return {
      ...state,
      isFetchingSingle: false,
      didInvalidateSingle: false,
      updateBingo: action.payload.updateBingo
    };

  default:
    return state;
  }
};

export default bingoReducer;
