import moment from "moment";

export const parseDate = (date) => moment(date).format("DD/MM/YYYY");

export const filterDate = (term, date) =>
  !!moment(date).format("DD/MM/YYYY").includes(term);

export function fromToday() {
  const date = new Date();
  date.setHours(0);
  date.setMinutes(0);

  date.setDate(date.getDate() - date.getDay());
  // console.log("Return de funcion fromToday(): ", date.getTime());
  return date.getTime();
}
