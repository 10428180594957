import React, { useState } from 'react';

import {
  TextField, Button, Card, CardContent, IconButton, CircularProgress
} from '@material-ui/core';

import { ArrowBack } from '@material-ui/icons';
import { useForm, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import Loading from '../../../../commons/loading';
import colors from '../../../../../assets/colors';
import UploadExcel from '../../../../commons/excelBingo';


const useStyles = makeStyles((theme) => ({
  errorInput: {
    color: 'red',
    fontSize: 12,
    margin: 0,
    paddingLeft: '10px',
    paddingBottom: '10px',
    paddingTop: 0
  },
  input: {
    width: '100vh',
    margin: theme.spacing(1),
  },
  fatherDiv: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '3%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  title: {
    height: '10vh',
    fontSize: 'x-large',
    fontFamily: 'unset'
  },
  button: {
    width: '30vh',
    background: colors.palette.primary,
    fontFamily: 'unset',
    color: '#fafafa',
    '&:hover': {
      background: colors.palette.secondary,
      color: colors.palette.dark
    }
  },
  root: {
    minWidth: 255,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },

  missionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center' 
  },
  
  missionQuadrant: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
}));

// name, quadrants, clients, dateExpiry

export default ({
  title,
  onSubmit,
  bingo,
  loading,
  history,
  loadingSubmit,
  getExcel
}) => {
  const classes = useStyles();
  const [clientsBliveError, setClientsBliveError] = useState(false);
  const [clientsBlive, setClientsBlive] = useState([]);
  const [loadingImg, setLoadingImg] = useState(false);
  const [errorImg, setErrorImg] = useState(false);
  const [uploadedMainPicture, setUploadedMainPicture] = useState(false);

  const {
    control, handleSubmit, errors,
  } = useForm();

  if (loading) {
    return (<Loading />);
  }

  const handleSubmitForm = async (values) => {
    if (!clientsBliveError) {
      onSubmit({
        ...values,
        clients: clientsBlive,
      });
    } 
}
 
  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <IconButton
          onClick={() => history.goBack()}
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
        >
          <ArrowBack className={classes.icon} />
        </IconButton>
        <div className={classes.fatherDiv}>
          <div className={classes.title}>{title}</div>
          <form onSubmit={handleSubmit(handleSubmitForm)}>
            
            <Controller
              className={classes.input}
              as={(
                <TextField
                  placeholder="Nombre"
                  label="Nombre del bingo"
                  variant="outlined"
                  error={!!errors?.name?.message}
                />
              )}
              control={control}
              name="name"
              defaultValue={bingo.name}
              rules={{
                required: 'Campo requerido',
              }}
            />
            <p className={classes.errorInput}>{errors?.name?.message}</p>

            <Controller
              className={classes.input}
              as={(
                <TextField
                  variant="outlined"
                  label="Fecha de Vencimiento"
                  type="date"
                  error={!!errors?.expirationDateBingo?.message}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              control={control}
              name="dateExpiry"
              defaultValue={moment(bingo.dateExpiry).format('yyyy-MM-DD')}
              rules={{
                required: 'Ingrese una fecha válida',
              }}
            />
            <p className={classes.errorInput}>{errors?.passwordConfirm?.message}</p>
                        
            <div className={classes.inputsRow}>

              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <p className={classes.imgTitle}>Clientes Blive</p>
                {clientsBliveError && (<p className={classes.clentsBliveError}>{clientsBliveError}</p>)}
                <UploadExcel initialClients={clientsBlive} onChange={setClientsBlive} setError={setClientsBliveError} getDataExcel={getExcel} />                  
                </div>
              </div>



            <div className={classes.fatherDiv}>
              <Button
                className={classes.button}
                variant="outlined"
                size="large"
                type="submit"
                style={{ marginTop: 32 }}
                disabled={loadingSubmit}
              >
                { loadingSubmit ? <CircularProgress size={20} color="inherit" /> : 'Guardar' }
              </Button>
            </div>
          </form>
        </div>

      </CardContent>
    </Card>

  );
};
