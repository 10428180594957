import React from 'react';

import {
  TextField, Button, Card, CardContent, IconButton, CircularProgress
} from '@material-ui/core';

import { ArrowBack } from '@material-ui/icons';
import { useForm, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';

import Loading from '../../../../commons/loading';
import Select from '../../../puntosVenta/components/selects/select';
import colors from '../../../../../assets/colors';

const useStyles = makeStyles((theme) => ({
  errorInput: {
    color: 'red',
    fontSize: 12,
    margin: 0,
    paddingLeft: '10px',
    paddingBottom: '10px',
    paddingTop: 0
  },
  input: {
    width: '100vh',
    margin: theme.spacing(1),
  },
  fatherDiv: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '3%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  title: {
    height: '10vh',
    fontSize: 'x-large',
    fontFamily: 'unset'
  },
  button: {
    width: '30vh',
    background: colors.palette.primary,
    fontFamily: 'unset',
    color: '#fafafa',
    '&:hover': {
      background: colors.palette.secondary,
      color: colors.palette.dark
    }
  },
  root: {
    minWidth: 255,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
}));


export default ({
  title,
  onSubmit,
  user,
  loading,
  isEdit,
  history,
  loadingSubmit
}) => {
  const classes = useStyles();
  const {
    control, handleSubmit, errors, getValues
  } = useForm();

  if (loading) {
    return (<Loading />);
  }

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <IconButton
          onClick={() => history.goBack()}
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
        >
          <ArrowBack className={classes.icon} />
        </IconButton>
        <div className={classes.fatherDiv}>
          <div className={classes.title}>{title}</div>
          <form onSubmit={handleSubmit(onSubmit)}>


            <Controller
              className={classes.input}
              as={(
                <TextField
                  placeholder="Nombre Completo"
                  label="Nombre Completo"
                  variant="outlined"
                  error={!!errors?.fullName?.message}
                />
              )}
              control={control}
              name="fullName"
              defaultValue={isEdit ? user.fullName : ''}
              rules={{
                required: 'Campo requerido',
              }}
            />
            <p className={classes.errorInput}>{errors?.fullName?.message}</p>


            <Controller
              className={classes.input}
              as={(
                <TextField
                  label="Email"
                  variant="outlined"
                />
              )}
              control={control}
              name="email"
              defaultValue={isEdit ? user.email : ''}
              rules={{
                required: 'Campo requerido',
              }}
              disabled={isEdit}
            />
            <p className={classes.errorInput}>{errors?.email?.message}</p>


            <Controller
              className={classes.input}
              as={(
                <TextField
                  placeholder="Contraseña"
                  label="Contraseña"
                  variant="outlined"
                  error={!!errors?.password?.message}
                />
              )}
              control={control}
              name="password"
              defaultValue=""
              type="password"
              rules={{
                required: isEdit ? false : 'Campo requerido',
                minLength: { value: 5, message: 'Password must have at least 5 characters.' },
              }}
            />
            <p className={classes.errorInput}>{errors?.password?.message}</p>

            <Controller
              className={classes.input}
              as={(
                <TextField
                  placeholder="Confirmar contraseña"
                  label="Confirmar contraseña"
                  variant="outlined"
                  error={!!errors?.passwordConfirm?.message}
                />
              )}
              control={control}
              name="passwordConfirm"
              defaultValue=""
              type="password"
              rules={{
                required: isEdit ? false : 'Campo requerido',
                validate: (value) => {
                  if (value === getValues().password) {
                    return true;
                  } return 'Las contraseñas no coinciden.';
                }
              }}
            />
            <p className={classes.errorInput}>{errors?.passwordConfirm?.message}</p>

            <div style={{ maxWidth: 100 }}>
              <Select
                control={control}
                defaultValue={isEdit ? user.role : ''}
                errors={errors}
                title="Rol"
                name="role"
                rules={{
                  required: isEdit ? false : 'Ingrese un rol válido',
                }}
                values={['admin']}
              />
            </div>

            <div className={classes.fatherDiv}>
              <Button
                className={classes.button}
                variant="outlined"
                size="large"
                type="submit"
                style={{ marginTop: 32 }}
                disabled={loadingSubmit}
              >
                { loadingSubmit ? <CircularProgress size={20} color="inherit" /> : 'Guardar' }
              </Button>
            </div>
          </form>
        </div>

      </CardContent>
    </Card>

  );
};
