import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import {
  makeStyles, Typography, Grid, Box, Paper, Link, TextField, CssBaseline, Button, Avatar
} from '@material-ui/core';

import { loginUser } from '../../../actions/loginActions';
import Loading from '../../commons/loading/loadingButton';
import colors from '../../../assets/colors';

const Copyright = () => (
  <Typography variant="body2" style={{ color: colors.palette.white }} align="center">
    {'Copyright © '}
    <Link style={{ color: colors.palette.white }}>backoffice-cuponera</Link>
    {' '}{new Date().getFullYear()}{'.'}
  </Typography>
);

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    color: colors.palette.white
  },
  image: {
    backgroundImage: 'url(https://images.pexels.com/photos/3819967/pexels-photo-3819967.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: colors.palette.primary,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: colors.palette.primary
  },
  error: {
    color: colors.palette.primary,
    margin: 0,
    marginLeft: '12px',
    paddingBottom: '6px'
  },
  links: {
    display: 'flex',
    fontSize: 16,
    justifyContent: 'space-around'
  },
  main: {
    backgroundColor: colors.palette.dark
  },
  cssLabel: {
    color: colors.palette.white,
  },
  cssFocused: {
    color: 'white !important',
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: 'white !important'
  },
  input: {
    '& input:-webkit-autofill': {
      WebkitTextFillColor: 'white !important',
      boxShadow: '0 0 0 30px transparent inset !important',
      WebkitBoxShadow: '0 0 0 30px #231f20 inset !important',
    },
    '& input:-webkit-autofill:hover': {
      boxShadow: '0 0 0 30px white transparent inset !important',
      WebkitBoxShadow: '0 0 0 30px #231f20 inset !important',
    },
    '& input:-webkit-autofill:focus': {
      boxShadow: '0 0 0 30px white transparent inset !important',
      WebkitBoxShadow: '0 0 0 30px #231f20 inset !important',
    },
    '& input:-webkit-autofill:active': {
      boxShadow: '0 0 0 30px white transparent inset !important',
      WebkitBoxShadow: '0 0 0 30px #231f20 inset !important',
    }
  },
  cssOutlinedInput: {
    color: colors.palette.white,
  }

}));

export default function Login({ history }) {
  const [loginError, setLoginError] = useState(false);
  const loginReducer = useSelector((state) => state.loginReducer);

  const classes = useStyles();
  const { handleSubmit, control, errors } = useForm();

  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    setLoginError(false);
    dispatch(loginUser(data))
      .then((res) => {
        if (res.value.userLogueado.length) {
          return history.push('/private/user');
        }
        return setLoginError(true);
      })
      .catch(() => {
        setLoginError(true);
      });
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={6} md={8} xl={9} className={classes.image} />
      <Grid item xs={12} sm={6} md={4} component={Paper} elevation={6} xl={3} square className={classes.main}>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" style={{ color: colors.palette.white }}>Iniciar Sesión</Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>

            <Controller
              name="email"
              as={(
                <TextField
                  className={classes.input}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Correo Electrónico"
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline,
                    }
                  }}
                />
              )}
              control={control}
              rules={{
                required: 'Correo electrónico inválido.',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'Dirección de correo inválida.'
                }
              }}
              defaultValue=""
            />
            {errors.email && errors.email.message && (
              <p className={classes.error}>{errors.email.message}</p>
            )}

            <Controller
              name="password"
              type="password"
              as={(
                <TextField
                  className={classes.input}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Contraseña"
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline,
                    }
                  }}
                />
              )}
              control={control}
              rules={{
                required: 'Contraseña inválida.',
              }}
              defaultValue=""
            />
            {errors.password && errors.password.message && (
              <p className={classes.error}>{errors.password.message}</p>
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={loginReducer.isFetching}
              className={classes.submit}
            >
              {loginReducer.isFetching ? <Loading /> : 'Ingresar' }
            </Button>

            {loginError && (
              <div className={classes.links}>
                <p className={classes.error}>¡Datos incorrectos, intente nuevamente!</p>
              </div>
            )}

            <Box mt={4}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}
