import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers } from '../../../actions/userActions';

import Add from '../../commons/button/add';
import Table from '../../commons/tables/userTable';
import ErrorHandling from '../../commons/errorHandling';
import useFetchData from '../../../utils/hooks/useFetchData';

export default function ({
  history,
}) {
  const userState = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();

  useFetchData(getUsers, userState.usersAll.length);

  if (userState.didInvalidate) {
    return <ErrorHandling error="network" />;
  }

  return (
    <div>
      <Table
        data={userState.usersAll.length !== 0 ? userState.usersAll : []}
        loading={userState.isFetching}
        fetchData={() => dispatch(getUsers())}
        history={history}
        title="Usuarios"
      />
      <Add onClick={() => history.push('/private/addUser')} />
    </div>
  );
}
