import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  CircularProgress,
  TextField,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";

import CSVDownloadButton from "../../commons/CVSDownloadButton";
import {
  getPuntosVentas,
  sendCredentialsToPointSales,
  getPuntosVentasPorId,
} from "../../../actions/puntosVentaActions";
import { LogContainer, LogCard } from "../../commons/logs";
import { fetchPoinSaleLogs } from "../../../actions/logsActions";
import { getCustomerStatistics } from "../../../actions/statsAction";
import styles from "./styles";

import Table from "../../ui/puntosVenta/PointSaleBoard/components/Table/index";
import Add from "../../commons/button/add";
import Dialog from "../../commons/button/dialog";
import useToast from "../../commons/toast";
import ErrorHandling from "../../commons/errorHandling";
import useFetchData from "../../../utils/hooks/useFetchData";
import Loading from "../../commons/loading/index";

// const headerPointSaleTable =
//   "Cliente FEMSA ," +
//   "Cuit ," +
//   "Email ," +
//   "Razón Social ," +
//   "Fecha creación ," +
//   "Domicilio ," +
//   "Localidad ," +
//   "Franquicia ," +
//   "Número ," +
//   "Ruta ," +
//   "Tipo ," +
//   "Canal ," +
//   "Unidad operativa ," +
//   "Segmento ," +
//   "GEC";
// const keysDataTable = [
//   "clientFemsa",
//   "cuit",
//   "emailUser",
//   "businessName",
//   "date",
//   "address",
//   "localidad",
//   "franchise",
//   "numberPhone",
//   "route",
//   "atentionType",
//   "channel",
//   "operativeUnit",
//   "nse",
//   "gec",
// ];
const headerTable = [
  "Cliente FEMSA",
  "Cliente Blive",
  "Fecha creación",
  "Fecha registro",
];
const keyTable = ["clientFemsa", "clientBlive", "date", "registrationDate"];

export default function ({ history }) {
  const classes = styles();
  const dispatch = useDispatch();
  const [sendingCredentials, setSendingCredentials] = useState(false);
  const puntosVentaReducer = useSelector((state) => state.puntosVentaReducer);
  const logsState = useSelector((state) => state.logsReducer);
  const { customers, unregisteredCustomers, registeredCustomers } = useSelector(
    (state) => state.statsReducer
  );
  const { handleSubmit, control, errors } = useForm();

  const [radioValue, setRadioValue] = useState("clientBlive");

  const [pageNumber, setPageNumber] = useState(1);
  const [showPagination, setShowPagination] = useState(true);

  const pages = new Array(puntosVentaReducer.totalPages)
    .fill(null)
    .map((v, i) => i);
  // useFetchData(getPuntosVentas, puntosVentaReducer.puntosDeVenta.length);

  useEffect(() => {
    dispatch(getPuntosVentas(pageNumber)).then(() => setShowPagination(true));
  }, [pageNumber]);

  useEffect(() => {
    dispatch(getCustomerStatistics());
  }, []);

  // useEffect(() => {
  //   // dispatch(fetchPoinSaleLogs());
  // }, []);

  const onSubmit = async ({ id, radio }) => {
    if (id) {
      dispatch(getPuntosVentasPorId(`${radio}=${id}`)).then(() =>
        setShowPagination(false)
      );
    } else {
      dispatch(getPuntosVentas(pageNumber)).then(() => setShowPagination(true));
    }
  };

  if (puntosVentaReducer.didInvalidate) {
    return <ErrorHandling error={400} />;
  }

  const { Toast, call } = useToast();

  function handleSendCredentials() {
    if (sendingCredentials) return;
    setSendingCredentials(true);
    sendCredentialsToPointSales()
      .then(() => call("Credenciales enviadas con éxito.", "success"))
      .catch(() => call("Hubo un error enviando las credenciales", "error"))
      .then(() => setSendingCredentials(false));
  }

  const goToPrevious = () => {
    setPageNumber(Math.max(1, pageNumber - 1));
  };
  const goToNext = () => {
    setPageNumber(Math.min(puntosVentaReducer.totalPages, pageNumber + 1));
  };

  if (puntosVentaReducer.isFetching) return <Loading />;

  return (
    <>
      <h2 className={classes.title}>Puntos de venta</h2>

      <FormLabel component='legend'>Buscar por</FormLabel>
      <form className={classes.form} noValidate autoComplete='off'>
        <div className={classes.radioBox}>
          <Controller
            name='radio'
            as={
              <RadioGroup name='clientId'>
                <FormControlLabel
                  value='clientBlive'
                  control={<Radio />}
                  label='Cliente Blive'
                />
                <FormControlLabel
                  value='clientFemsa'
                  control={<Radio />}
                  label='Cliente FEMSA'
                />
              </RadioGroup>
            }
            control={control}
            defaultValue='clientBlive'
          />
        </div>
        <div className={classes.inputBox}>
          <div>
            <Controller
              name='id'
              as={
                <TextField
                  id='outlined-basic'
                  label='ID'
                  style={{ marginRight: "20px" }}
                  variant='outlined'
                />
              }
              control={control}
              rules={{
                validate: (value) =>
                  !isNaN(value) || "El id debe ser un número",
              }}
              defaultValue=''
            />
            {errors.id?.message && (
              <div className={classes.error}>{errors.id.message}</div>
            )}
          </div>

          <Button
            onClick={handleSubmit(onSubmit)}
            variant='contained'
            color='primary'
            style={{ height: "40px", display: "flex", alignSelf: "center" }}
          >
            Buscar
          </Button>
        </div>
      </form>

      <Table data={puntosVentaReducer.puntosDeVenta} history={history} />
      {showPagination && (
        <div className={classes.pagination}>
          <div
            onClick={goToPrevious}
            className={classes.paginationButton}
            style={
              pageNumber > 1 ? { fontWeight: "bold" } : { fontWeight: "normal" }
            }
          >
            Previous
          </div>
          {/* {pages.map((pageIndex) => (
          <button
            key={pageIndex}
            onClick={() => {
              setPageNumber(pageIndex + 1);
            }}
            >
            {pageIndex + 1}
          </button>
        ))} */}
          <div
            className={classes.box}
            style={{ fontWeight: "bold", fontSize: "110%" }}
          >
            Page {pageNumber} of {puntosVentaReducer.totalPages}
          </div>
          <div
            onClick={goToNext}
            className={classes.paginationButton}
            style={
              pageNumber < puntosVentaReducer.totalPages
                ? { fontWeight: "bold" }
                : { fontWeight: "normal" }
            }
          >
            Next
          </div>
        </div>
      )}

      <Dialog
        title='Enviar credenciales a los puntos de venta'
        description='Se enviará un mensaje por whatsapp a cada uno de los teléfonos registrados de los puntos de venta con sus credenciales.'
        button={(onClick) => (
          <Button
            onClick={() => !sendingCredentials && onClick()}
            variant='contained'
            color='primary'
            style={{ width: 215, marginTop: 20 }}
          >
            {sendingCredentials ? (
              <CircularProgress color='inherit' size={20} />
            ) : (
              "Enviar credenciales"
            )}
          </Button>
        )}
        action={handleSendCredentials}
      />

      <Add onClick={() => history.push("/private/addPointSale")} />
      <LogContainer>
        {/* <LogCard
          title={`${
            logsState.pointSale.logs.filter(
              (rep) => rep.action === "AddFavorite"
            ).length
          } promociones`}
          subtitle='añadidos en total'
        /> */}
        <div style={{ flexDirection: "column" }}>
          {/* <CSVDownloadButton
            headersTable={headerTable}
            dataTable={customers}
            keyTable={keyTable}
            nameCSV='puntos-de-venta'
            text='Exportar puntos de venta en CSV'
            disabled={!customers.length}
          /> */}

          <CSVDownloadButton
            headersTable={headerTable}
            dataTable={registeredCustomers}
            keyTable={keyTable}
            nameCSV='puntos-de-venta-registrados'
            text='Exportar puntos de venta registrados en CSV'
            disabled={!registeredCustomers?.length}
          />
          {/*  <CSVDownloadButton
            headersTable={headerTable}
            dataTable={unregisteredCustomers}
            keyTable={keyTable}
            nameCSV='puntos-de-venta-no-registrados'
            text='Exportar puntos de venta no registrados en CSV'
            disabled={!unregisteredCustomers.length}
          />*/}
        </div>
      </LogContainer>

      <Toast />
    </>
  );
}
