import React from 'react';
import {
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, makeStyles
} from '@material-ui/core';

import colors from '../../../assets/colors';
import ButtonCustom from './index';

const useStyles = makeStyles({
  root: {
    color: colors.palette.primary
  }
});

export default function AlertDialog({
  action, title, description, button, active
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAction = async () => {
    await action();
    setOpen(false);
  };

  return (
    <div>
      {
        button && typeof button === 'function'
          ? button(handleClickOpen)
          : (
            <ButtonCustom
              text={active ? 'Deshabilitar' : 'Habilitar'}
              color="secondary"
              action={handleClickOpen}
              active={active}
            />
          )
      }
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            Cancelar
          </Button>
          <Button onClick={handleAction} className={classes.root} autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
