import {
  LOGIN_USER
} from '../actions/loginActions';

const INITIAL_STATE = {
  isFetching: false,
  didInvalidate: false,
  user: {},
};

const providers = (state = INITIAL_STATE, action) => {
  switch (action.type) {

  case `${LOGIN_USER}_REJECTED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: true,
    };
  case `${LOGIN_USER}_PENDING`:
    return {
      ...state,
      isFetching: true,
      didInvalidate: false,
    };
  case `${LOGIN_USER}_FULFILLED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: false,
      user: action.payload.userLogueado
    };

  default:
    return state;
  }
};

export default providers;
