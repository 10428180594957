import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { singleUser, updateUser, getUsers } from '../../../actions/userActions';

import Form from './components/forms';

export default function ({ history, match }) {
  const user = useSelector((state) => state.userReducer);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(singleUser(match.params.id));
  }, [dispatch, match]);

  const onSubmit = (values) => {
    setLoadingSubmit(true);
    dispatch(updateUser(match.params.id, values))
      .then(() => {
        history.push('/private/user');
        dispatch(getUsers());
        setLoadingSubmit(false);
      })
      .catch(() => setLoadingSubmit(false));
  };

  return (
    <Form
      history={history}
      onSubmit={onSubmit}
      user={user.singleUser}
      title="Editando Usuario"
      loading={user.isFetchingSingle}
      isEdit
      loadingSubmit={loadingSubmit}
    />
  );
}
