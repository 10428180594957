import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  makeStyles,
  Card,
  CardActions,
  CardContent,
  Typography,
  IconButton,
  CircularProgress,
} from "@material-ui/core";

import { ArrowBack } from "@material-ui/icons";

import moment from "moment";
import { fetchSinglePoinSaleLogs } from "../../../actions/logsActions";

import {
  getOnePuntoVenta,
  deletePuntoVenta,
  getPuntosVentas,
} from "../../../actions/puntosVentaActions";
import { getDownloadUrl } from "../../../actions/firebase";

import ErrorHandling from "../../commons/errorHandling";
import Loading from "../../commons/loading";
import ButtonCustom from "../../commons/button";
import ButtonDialog from "../../commons/button/dialog";
import img_error from "../../../assets/images/err/error_image.jpg";

import { LogContainer, LogCard } from "../../commons/logs";

const useStyles = makeStyles({
  root: {
    minWidth: 900,
    maxWidth: 1300,
    margin: "0 auto",
  },
  title: {
    fontSize: 24,
    textAlign: "center",
  },
  btnEdit: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 18,
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
    marginTop: 12,
    padding: 6,
  },
  img: {
    maxHeight: 198,
    maxWidth: 198,
  },
  infoContainer: {
    display: "flex",
    margin: "0 auto",
    width: "80%",
    marginTop: 24,
    justifyContent: "space-evenly",
  },
  column: {
    fontSize: 18,
  },
  description: {
    color: "hsla(0, 0%, 0%, 0.54)",
    fontSize: 16,
    textTransform: "capitalize",
  },
  noCapitalize: {
    color: "hsla(0, 0%, 0%, 0.54)",
    fontSize: 16,
    textTransform: "none",
  },
  info: {
    margin: 12,
  },
  menuButton: {
    marginLeft: 12,
  },
});

export default function SingleViewPointsSale({ match, history }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [image, setImage] = useState(null);
  const [loadingImage, setLoadingImage] = useState(true);

  const singlePuntoVenta = useSelector(
    (state) => state.puntosVentaReducer.singlePuntoVenta
  );
  const puntoVenta = useSelector((state) => state.puntosVentaReducer);
  const logsState = useSelector((state) => state.logsReducer);

  useEffect(() => {
    dispatch(getOnePuntoVenta(match.params.id));
    // TODO: Rompe el backend al buscar estos logs
    // dispatch(fetchSinglePoinSaleLogs(match.params.id));
    // eslint-disable-next-line
    console.log("Cargando vista");
  }, []);

  useEffect(() => {
    if (singlePuntoVenta.qrUrl) {
      getDownloadUrl(singlePuntoVenta.qrUrl)
        .then((img) => {
          setImage(img);
          setLoadingImage(false);
        })
        .catch(() => {
          setLoadingImage(false);
        });
    }

    return function cleanUp() {
      setImage(null);
    };
  }, [singlePuntoVenta]);

  if (puntoVenta.didInvalidate) {
    return <ErrorHandling error='network' />;
  }

  if (puntoVenta.isFetchingSingle) return <Loading />;

  const handleEdit = () =>
    history.push(`/private/editPointSale/${singlePuntoVenta._id}`);
  const handleDelete = async () => {
    await deletePuntoVenta(match.params.id);
    await dispatch(getPuntosVentas());
    history.push("/private/pointsSale");
  };

  return (
    <>
      {loadingImage ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Card className={classes.root}>
          <CardContent>
            <IconButton
              onClick={() => history.push("/private/pointsSale")}
              edge='start'
              className={classes.menuButton}
              color='inherit'
              aria-label='menu'
            >
              <ArrowBack />
            </IconButton>

            <Typography
              className={classes.title}
              color='textSecondary'
              gutterBottom
            >
              Detalle de Punto de Venta
            </Typography>
            <div className={classes.imgContainer}>
              <img
                className={classes.img}
                src={!image && !loadingImage ? img_error : image}
                alt='img'
              />
            </div>

            <div className={classes.infoContainer}>
              <div className={classes.column}>
                <div className={classes.info}>
                  Cliente BLIVE: &nbsp;
                  <span className={classes.description}>
                    {singlePuntoVenta.clientBlive}
                  </span>
                </div>

                <div className={classes.info}>
                  Cliente FEMSA: &nbsp;
                  <span className={classes.description}>
                    {singlePuntoVenta.clientFemsa}
                  </span>
                </div>

                <div className={classes.info}>
                  CUIT: &nbsp;
                  <span className={classes.description}>
                    {singlePuntoVenta.cuit}
                  </span>
                </div>

                <div className={classes.info}>
                  Domicilio: &nbsp;
                  <span className={classes.description}>
                    {singlePuntoVenta.address}
                  </span>
                </div>

                <div className={classes.info}>
                  Localidad: &nbsp;
                  <span className={classes.description}>
                    {singlePuntoVenta.localidad}
                  </span>
                </div>

                <div className={classes.info}>
                  Teléfono: &nbsp;
                  <span className={classes.description}>
                    {singlePuntoVenta.numberPhone !== "none" &&
                      singlePuntoVenta.numberPhone}
                  </span>
                </div>

                <div className={classes.info}>
                  Email: &nbsp;
                  <span className={classes.noCapitalize}>
                    {singlePuntoVenta.emailUser}
                  </span>
                </div>

                <div className={classes.info}>
                  NSE: &nbsp;
                  <span className={classes.description}>
                    {singlePuntoVenta.nse}
                  </span>
                </div>

                <div className={classes.info}>
                  Segmento: &nbsp;
                  <span className={classes.description}>
                    {singlePuntoVenta.segment}
                  </span>
                </div>

                <div className={classes.info}>
                  Días de Entrega: &nbsp;
                  <span className={classes.description}>
                    {singlePuntoVenta.deliveryDays.join(" - ")}
                  </span>
                </div>
              </div>

              <div className={classes.column}>
                <div className={classes.info}>
                  Razón Social: &nbsp;
                  <span className={classes.description}>
                    {singlePuntoVenta.businessName}
                  </span>
                </div>
                <div className={classes.info}>
                  Fecha de Creación: &nbsp;
                  <span className={classes.description}>
                    {moment(singlePuntoVenta.date).format("DD/MM/YYYY")}
                  </span>
                </div>
                <div className={classes.info}>
                  Fecha de Registro: &nbsp;
                  <span className={classes.description}>
                    {singlePuntoVenta.registrationDate !== null &&
                      moment(singlePuntoVenta.registrationDate).format(
                        "DD/MM/YYYY"
                      )}
                  </span>
                </div>
                <div className={classes.info}>
                  Código Canje: &nbsp;
                  <span className={classes.description}>
                    {singlePuntoVenta.qrCode}
                  </span>
                </div>
                <div className={classes.info}>
                  Franquicia: &nbsp;
                  <span className={classes.description}>
                    {singlePuntoVenta.franchise}
                  </span>
                </div>

                <div className={classes.info}>
                  Tipo de Atención: &nbsp;
                  <span className={classes.description}>
                    {singlePuntoVenta.atentionType}
                  </span>
                </div>

                <div className={classes.info}>
                  Canal: &nbsp;
                  <span className={classes.description}>
                    {singlePuntoVenta.channel}
                  </span>
                </div>

                <div className={classes.info}>
                  Unidad Operativa: &nbsp;
                  <span className={classes.description}>
                    {singlePuntoVenta.operativeUnit}
                  </span>
                </div>

                <div className={classes.info}>
                  GEC: &nbsp;
                  <span className={classes.description}>
                    {singlePuntoVenta.gec}
                  </span>
                </div>

                <div className={classes.info}>
                  Ruta: &nbsp;
                  <span className={classes.description}>
                    {singlePuntoVenta.route}
                  </span>
                </div>
                <div className={classes.info}>
                  Registrado: &nbsp;
                  <span className={classes.description}>
                    {singlePuntoVenta.isAutenticated ? "Si" : "No"}
                  </span>
                </div>
              </div>
            </div>
          </CardContent>
          <CardActions className={classes.btnEdit}>
            <ButtonCustom
              text='Editar'
              color='default'
              edit
              action={handleEdit}
            />
            {/* <ButtonDialog
              action={handleDelete}
              title={
                singlePuntoVenta.active
                  ? "Deshabilitar Punto de Venta para canje de cupones?"
                  : "Habilitar Punto de Venta para canje de cupones?"
              }
              description='Tenga en cuenta que una vez borrado, no habrá vuelta atrás!'
              active={singlePuntoVenta.active}
            /> */}
          </CardActions>
        </Card>
      )}
      {/* // TODO: Rompe en el backend al buscar los logs */}
      {/* <LogContainer isFetching={logsState.isSingleFetching}>
        <LogCard
          title={`${
            logsState.singlePointSale.logs.filter(
              (rep) => rep.action === "AddFavorite"
            ).length
          } promociones`}
          subtitle='añadidos en total'
        />
        <LogCard
          title={`${(singlePuntoVenta.canjes || []).length} canjes`}
          subtitle='realizados'
        />
      </LogContainer> */}
    </>
  );
}
