/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react';
import XLSX from 'xlsx';

import { makeStyles } from '@material-ui/core';
import uploadFile from './upload.svg';

const useStyles = makeStyles(() => ({
  fileInput: {
    fontSize: 0,
    width: 0,
  },
  fileLabel: {
    padding: '6px 23px',
    background: '#3f4257',
    borderRadius: 30,
    color: 'white',
    fontWeight: 300,
    fontSize: 14,
    margin: '10px 0',
    transition: 'all 0.2s ease-in',
    cursor: 'pointer',
    outline: 'none',
    border: 'none',
    display: 'inline-block',
    lineHeight: '14px',
    '&:hover': {
      background: '#545972',
    }
  },
  fileImage: {
    width: 50,
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
    flexDirection: 'column',
    background: '#fff',
    boxShadow: '2px 2px 3px 0 rgba(0, 0, 0, 0.05)',
    position: 'relative',
    borderRadius: 10,
    padding: '20px',
    transition: 'all 0.3s ease-in',
    margin: '10px 0',
  },
  acceptedFiles: {
    fontSize: 12,
    margin: '8px 0 4px',
  },
  errorText: {
    margin: 0,
    marginTop: 32,
    fontWeight: 'bold',
    fontSize: 14,
    color: 'red',
    whiteSpace: 'pre-wrap',
  },
  codesPreview: {
    alignSelf: 'center',
    maxWidth: 170,
    fontSize: 10,
    textAlign: 'center'
  }
}));

export default function ({ onChange, initialCodes, setError }) {
  const classes = useStyles();
  const [codes, setCodes] = useState(null);

  useEffect(() => {
    setCodes(initialCodes);
  }, [initialCodes]);

  function validate(codigos) {
    console.log('codigos', codigos)
    setError('');
    let errors = '';

    // const newCodes = [...new Set(codigos)];
    const newCodes = [...codigos];
    const index = newCodes.indexOf(undefined);

    if (index !== -1) {
      newCodes.splice(index, 1);
    }

    for (let i = 0; i < newCodes.length; i++) {
      const code = newCodes[i];
      // if (typeof (code) !== 'number') errors += `\nEn la fila ${i + 1} el código "${code}" contiene caracteres no numéricos.`;
      if (String(code).includes('.')) errors += `\nEn la fila ${i + 1} el código "${code}" contiene caracteres no aceptados.`;
      if (!Number(code)) errors += `\nEn la fila ${i + 1} el código "${code}" contiene caracteres no aceptados.`;
      // else if (typeof (code) === 'undefined') errors += `\nLa fila ${i + 1} no contiene un valor.`;

      // for (let h = 0; h < 9; h++) {
      //   const char = code.toString()[h];
      //   if (!char) errors += `\nLa fila ${i + 1} no tiene valor`;
      //   if (isNaN(char)) errors += `\nEn la fila ${i + 1} hay letras`;
      // }
      // if (code.toString().length < 9) errors += `\nEn la fila ${i + 1}, no tiene todas las categorias`;
    }

    if (errors) {
      setError(errors);
      return;
    }

    onChange(newCodes);
    setCodes(newCodes);
  }

  function onUpload(e) {
    e.preventDefault();
    const { files, value } = e.target;
    if (!value.match(/.xlsx/g)) return setError('Archivo no permitido');
    const reader = new FileReader();

    reader.onload = function (ev) {
      const readedData = XLSX.read(ev.target.result, { type: 'binary' });
      console.log('evvvvvv', ev.target.result)
      console.log('readedData', readedData)
      const ws = readedData.Sheets[readedData.SheetNames[0]];
      console.log('ws', readedData.Sheets[readedData.SheetNames[0]])
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      console.log('dataParse', dataParse)
      validate(dataParse.map((a) => a[0]));
    };
    reader.readAsBinaryString(files[0]);
  }

  return (
    <>
      <div className={classes.inputContainer}>

        <img src={uploadFile} alt="Subir un archivo" className={classes.fileImage} />
        <p className={classes.acceptedFiles}>{ codes && codes.length ? 'Archivo seleccionado' : 'Acepted: .xlsx' }</p>

        <label htmlFor="inputFile" className={classes.fileLabel}>
          <input type="file" id="inputFile" onChange={onUpload} className={classes.fileInput} />
          Elegir archivo
        </label>
      </div>
    </>
  );
}

