import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import { MoodBad, SignalCellularConnectedNoInternet0Bar, Error } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    height: 400,
    alignItems: 'center'
  },
  iconContainer: {
    display: 'flex',
    width: 200
  },
  icon: {
    fontSize: 150,
    color: '#E5006A'
  },
  content: {
    maxWidth: 500,
    marginLeft: 16
  },
  title: {
    color: '#E5006A',
    fontFamily: "'Sarabun', sans-serif",
    fontStyle: 'normal',
    fontWeight: '600',
    letterSpacing: 0.5
  },
  text: {
    color: '#171D33',
    fontFamily: "'Sarabun', sans-serif",
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: 0.2
  },
  buttonContainer: {
    marginTop: 32,
  },
}));

export default ({
  error
}) => {
  const classes = useStyles();

  const getErrorTitle = () => {
    switch (error) {
    case 'network':
      return 'Error de Conexión';
    case 400:
      return 'Upss...';
    default:
      return 'Error...';
    }
  };

  const getErrorText = () => {
    switch (error) {
    case 'network':
      return 'Verifique su conexión de red e intente nuevamente. De persistir el problema contactese con su administrador.';
    case 400:
      return 'Lo sentimos, algo salió mal, por favor, intente nuevamente.';
    default:
      return 'Lo sentimos, algo salió mal, por favor, intente nuevamente.';
    }
  };

  const getIcon = () => {
    switch (error) {
    case 'network':
      return <SignalCellularConnectedNoInternet0Bar className={classes.icon} />;
    case 400:
      return <MoodBad className={classes.icon} />;
    default:
      return <Error className={classes.icon} />;
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.iconContainer}>
        {getIcon()}
      </div>
      <div className={classes.content}>

        <h1 className={classes.title}>
          {getErrorTitle()}
        </h1>

        <p className={classes.text}>
          {getErrorText()}
        </p>

        <div className={classes.buttonContainer}>
          <Button variant="contained" color="primary" disableElevation onClick={() => window.location.reload()}>
            Refrescar
          </Button>
        </div>

      </div>
    </div>
  );
};
