import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Add from '../../commons/button/add';
import Table from '../../commons/tables/newsTable';
import ErrorHandling from '../../commons/errorHandling';
import useFecthData from '../../../utils/hooks/useFetchData';

import { getAllNotices } from '../../../actions/noticesAction';

export default ({
  history,
}) => {
  const noticesReducer = useSelector((state) => state.noticesReducer);
  const dispatch = useDispatch();

  useFecthData(getAllNotices, noticesReducer.allNotices.length);

  if (noticesReducer.didInvalidate) {
    return <ErrorHandling error={400} />;
  }

  return (
    <div>
      <Table
        data={noticesReducer.allNotices.length !== 0 ? noticesReducer.allNotices : []}
        loading={noticesReducer.isFetching}
        fetchData={() => dispatch(getAllNotices())}
        history={history}
        title="Novedades"
      />
      <Add onClick={() => history.push('/private/addNew')} />
    </div>
  );
};
