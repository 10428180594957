import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { LogContainer, LogCard } from "../../commons/logs";
import { allPromotions } from "../../../actions/promotionsActions";
import { fetchPromotionsLogs } from "../../../actions/logsActions";

import Add from "../../commons/button/add";
import Table from "../../commons/tables/promotionTable";
import ErrorHandling from "../../commons/errorHandling";
import useFetchData from "../../../utils/hooks/useFetchData";

export default ({ history }) => {
  const promotionState = useSelector((state) => state.promotionsReducer);
  const logsState = useSelector((state) => state.logsReducer);
  const dispatch = useDispatch();

  // useFetchData(allPromotions, promotionState.allPromotions.length);

  useEffect(() => {
    dispatch(allPromotions());
  }, [dispatch]);

  // useEffect(() => {
  //   dispatch(fetchPromotionsLogs());
  // }, [dispatch]);

  if (promotionState.didInvalidate) {
    return <ErrorHandling error={400} />;
  }

  return (
    <div>
      <Table
        data={
          promotionState.allPromotions.length !== 0
            ? promotionState.allPromotions
            : []
        }
        loading={promotionState.isFetching}
        fetchData={() => dispatch(allPromotions())}
        history={history}
        title='Promociones'
      />
      <Add onClick={() => history.push("/private/addPromotion")} />
      <LogContainer isFetching={logsState.isFetching}>
        <LogCard
          title={`${
            logsState.promotionLogs.logs.filter(
              (log) => log.action === "AddFavorite"
            ).length
          } promociones`}
          subtitle='añadidas por puntos de venta en total'
        />
        <LogCard
          title={`${
            logsState.promotionLogs.daily.filter(
              (log) => log.action === "AddFavorite"
            ).length
          } promociones`}
          subtitle='añadidas por puntos de venta esta semana'
        />
      </LogContainer>
    </div>
  );
};
