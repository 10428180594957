import React, { useState } from 'react';
import { Person } from '@material-ui/icons';
import { Menu, IconButton, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';

import { signoutUser } from '../../../actions/loginActions';

const useStyles = makeStyles(() => ({
  text: {
    fontFamily: "'Sarabun', sans-serif",
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: 0.2,
  },
}));

const IconUser = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignout = () => {
    dispatch(signoutUser());
  };

  return (
    <>
      <IconButton color="inherit" style={{ marginLeft: 4, color: '#FFF' }} onClick={handleClick}>
        <Person />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleSignout} className={classes.text}>Cerrar sessión</MenuItem>
      </Menu>
    </>
  );
};

export default IconUser;
