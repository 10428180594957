import React, { memo } from 'react';

import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import Text from '../text/text';

const useStyles = makeStyles(() => ({
  nested: {
    backgroundColor: 'white',
    paddingLeft: 40
  },
  listIcon: {
    minWidth: 40,
    color: '#171D33'
  },
}));

const ListItemComponent = ({
  path,
  selected,
  title,
  icon: Icon
}) => {
  const classes = useStyles({ selected });

  return (
    <ListItem
      button
      className={classes.nested}
      component={Link}
      to={path}
    >
      {Icon && (
        <ListItemIcon className={classes.listIcon}>
          {Icon}
        </ListItemIcon>
      )}
      <ListItemText
        primary={(
          <Text
            selected={selected}
            text={title}
            size="1rem"
            color="#171D33"
            type="secondary"
          />
        )}
      />

    </ListItem>
  );
};

export default memo(ListItemComponent);
