import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Form from './components/form';
import ErrorHandling from '../../commons/errorHandling';
import useToast from '../../commons/toast';

import { postPuntoVenta, getPuntosVentas } from '../../../actions/puntosVentaActions';

export default function ({ history }) {
  const dispatch = useDispatch();
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const pointSaleReduceer = useSelector((state) => state.puntosVentaReducer);

  const { Toast, call } = useToast();

  // const onSubmit = async (inputs) => {
  //   setLoadingSubmit(true);
  //   const action = await dispatch(postPuntoVenta(inputs));
  //   if (action.action.type === 'CREATE_PUNTO_VENTA_FULFILLED') {
  //     setLoadingSubmit(false);
  //     dispatch(getPuntosVentas());
  //     history.push('/private/pointsSale');
  //   } else {
  //     console.log('erro 1')
  //     setLoadingSubmit(false);
  //     return <ErrorHandling error="network" />;
  //   }
  //   console.log('entro')

  // };

  const onSubmit = async (inputs) => {
    setLoadingSubmit(true);
    dispatch(postPuntoVenta(inputs))
      .then((res) => {
        setLoadingSubmit(false);
        // dispatch(getPuntosVentas());
        history.push('/private/pointsSale');
      })
      .catch((err) => {
        setLoadingSubmit(false);
        call('Ha ocurrido un error, verifique los datos e intente nuevamente.', 'warning');
      });
  };

  return (
    <>
      <Form
        onSubmit={onSubmit}
        history={history}
        title="Agregar Punto de Venta"
        reducer={pointSaleReduceer}
        loadingSubmit={loadingSubmit}
      />
      <Toast />
    </>
  );
}
