import React from 'react';
import { Loop } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  icon: {
    color: '#171D33',
    cursor: 'pointer',
    '&:hover, &:focus': {
      color: '#4a148c',
    }
  },
  container: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  title: {
    fontFamily: "'Sarabun', sans-serif",
    fontStyle: 'normal',
    fontWeight: '600',
    letterSpacing: 0.5,
    fontSize: '1.25rem',
    color: '#171D33',
    lineHeight: 1.6,
    marginRight: 16,
  },

});

export default ({
  title,
  fetchData
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.title}>{title}</div>
      <Loop onClick={fetchData} className={classes.icon} />
    </div>
  );
};
