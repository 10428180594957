import React from 'react';

import { Route, Switch, Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import Login from '../layout/ui/login';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    backgroundColor: '#f5f5f5'
  },
  root: {
    display: 'flex',
    flex: 1
  },
  toolbar: theme.mixins.toolbar,
}));

const AuthRoutes = function () {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />

      <main className={classes.content}>
        <Switch>
          <Route path="/account/login" component={Login} />
          <Route path="*">
            <Redirect to="/user" />
          </Route>
        </Switch>
      </main>
    </div>
  );
};

export default AuthRoutes;
