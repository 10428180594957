/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const privateRouteComponent = ({
  isAuth,
  pathnameRedirect,
  path,
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    exact
    path={path}
    render={() => (
      isAuth
        ? (<Component />)
        : (<Redirect to={pathnameRedirect} />)
    )}
  />
);

export default privateRouteComponent;
