import api from '../utils/functions/api/index';
import endpoints from '../utils/functions/api/endpoints';

const {
  get, post, put, deletes
} = api;

export const GET_ROUTES = 'GET_ROUTES';
export const SINGLE_ROUTE = 'SINGLE_ROUTE';
export const UPDATE_ROUTE = 'UPDATE_ROUTE';
export const ADD_ROUTE = 'ADD_ROUTE';

export const getAllRoutes = () => ({
  type: GET_ROUTES,
  payload: get({ url: endpoints.routesAll })
    .then((res) => ({ allRoutes: res })),
});

export const singleRoute = (id) => ({
  type: SINGLE_ROUTE,
  payload: get({ url: endpoints.routesSingle(id) })
    .then((res) => ({ singleRoute: res }))
});

export const addRoute = (body) => ({
  type: ADD_ROUTE,
  payload: post({ url: endpoints.createRoute, body })
});

export const updateRoute = (id, body) => (
  put({ url: endpoints.editRoute(id), body })
);

export const changeStatusRoute = (id) => async (dispatch) => {
  const fetch = await deletes({ url: endpoints.deleteRoute(id) });
  const request = get({ url: endpoints.routesAll })
    .then((res) => ({ allRoutes: res }));

  request
    .then(() => {
      dispatch({
        type: GET_ROUTES,
        payload: request,
      });
    });

  return fetch;
};
