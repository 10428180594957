import { ALL_COUPONS, SINGLE_COUPON } from '../actions/couponsActions';

const INITIAL_STATE = {
  isFetching: false,
  didInvalidate: false,
  allCoupons: [],
  isFetchingSingle: false,
  didInvalidateSingle: false,
  singleCoupon: {},
};

const providers = (state = INITIAL_STATE, action) => {
  switch (action.type) {

  case `${ALL_COUPONS}_REJECTED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: true,
    };
  case `${ALL_COUPONS}_PENDING`:
    return {
      ...state,
      isFetching: true,
      didInvalidate: false,
    };
  case `${ALL_COUPONS}_FULFILLED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: false,
      allCoupons: action.payload.allCoupons
    };

  case `${SINGLE_COUPON}_REJECTED`:
    return {
      ...state,
      isFetchingSingle: false,
      didInvalidateSingle: true,
    };
  case `${SINGLE_COUPON}_PENDING`:
    return {
      ...state,
      isFetchingSingle: true,
      didInvalidateSingle: false,
    };
  case `${SINGLE_COUPON}_FULFILLED`:
    return {
      ...state,
      isFetchingSingle: false,
      didInvalidateSingle: false,
      singleCoupon: action.payload.singleCoupon
    };

  default:
    return state;
  }
};

export default providers;
