/* eslint-disable no-nested-ternary */
import React from 'react';

import { Button, makeStyles } from '@material-ui/core';
import { Delete, Edit, RestorePage } from '@material-ui/icons';

import colors from '../../../assets/colors';

const useStyles = makeStyles({
  root: {
    margin: 12
  },
  btn: {
    backgroundColor: colors.palette.primary,
    '&:hover': {
      backgroundColor: '#e0e0e0',
      color: colors.palette.dark
    }
  },
  btnEdit: {
    backgroundColor: colors.palette.secondary,
    '&:hover': {
      backgroundColor: '#e0e0e0',
    }
  }
});

export default function ButtonCustom({
  text, color, action, edit, active
}) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Button
        color={color}
        className={edit ? classes.btnEdit : classes.btn}
        variant="contained"
        startIcon={edit ? <Edit /> : (active ? <Delete /> : <RestorePage />)}
        onClick={action}
      >
        {text}
      </Button>
    </div>
  );
}
