export default {
  //API_URL: 'http://localhost:5000/canal-coca-cola/us-central1/app/api',
  API_URL: "https://canal-coca-cola.web.app/api",
  // API_URL: "http://localhost:5100/api",
  API_VERSION: "/v1",
  firebaseConfig: {
    apiKey: "AIzaSyDKM28_P9sNagslLSYMudJz-G_EHCzjn7I",
    authDomain: "canal-coca-cola.firebaseapp.com",
    databaseURL: "https://canal-coca-cola.firebaseio.com",
    projectId: "canal-coca-cola",
    storageBucket: "canal-coca-cola.appspot.com",
    messagingSenderId: "885449046165",
    appId: "1:885449046165:web:ce3a3288ead59d923e839c",
    measurementId: "G-VYD9JFJL8T",
  },
};
