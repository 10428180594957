import {
  GET_NOTICES, SINGLE_NOTICE, UPDATE_NOTICE
} from '../actions/noticesAction';

const INITIAL_STATE = {
  isFetching: false,
  didInvalidate: false,
  allNotices: [],
  codeError: null,
  isFetchingSingle: false,
  didInvalidateSingle: false,
  singleNotice: {},
  updateNotice: {},
};

const noticeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {

  case `${GET_NOTICES}_REJECTED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: true,
      codeError: action.payload.codeError
    };
  case `${GET_NOTICES}_PENDING`:
    return {
      ...state,
      isFetching: true,
      didInvalidate: false,
    };
  case `${GET_NOTICES}_FULFILLED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: false,
      allNotices: action.payload.allNotices
    };


  case `${SINGLE_NOTICE}_REJECTED`:
    return {
      ...state,
      isFetchingSingle: false,
      didInvalidateSingle: true,
      codeError: action.payload.codeError
    };
  case `${SINGLE_NOTICE}_PENDING`:
    return {
      ...state,
      isFetchingSingle: true,
      didInvalidateSingle: false,
    };
  case `${SINGLE_NOTICE}_FULFILLED`:
    return {
      ...state,
      isFetchingSingle: false,
      didInvalidateSingle: false,
      singleNotice: action.payload.singleNotice
    };

  case `${UPDATE_NOTICE}_REJECTED`:
    return {
      ...state,
      isFetchingSingle: false,
      didInvalidateSingle: true,
      codeError: action.payload.codeError
    };
  case `${UPDATE_NOTICE}_PENDING`:
    return {
      ...state,
      isFetchingSingle: true,
      didInvalidateSingle: false,
    };
  case `${UPDATE_NOTICE}_FULFILLED`:
    return {
      ...state,
      isFetchingSingle: false,
      didInvalidateSingle: false,
      updateNotice: action.payload.updateNotice
    };

  default:
    return state;
  }
};

export default noticeReducer;
