import api from '../utils/functions/api/index';
import endpoints from '../utils/functions/api/endpoints';

const {
  get, post, put, deletes
} = api;

export const GET_USERS = 'GET_USERS';
export const SINGLE_USER = 'SINGLE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const ADD_USER = 'ADD_USER';

export const getUsers = () => ({
  type: GET_USERS,
  payload: get({ url: endpoints.userAll })
    .then((res) => ({ usersAll: res })),
});

export const singleUser = (id) => ({
  type: SINGLE_USER,
  payload: get({ url: endpoints.userSingle(id) })
    .then((res) => ({ singleUser: res }))
});

export const updateUser = (id, body) => ({
  type: UPDATE_USER,
  payload: put({ url: endpoints.editUser(id), body })
    .then((response) => ({ User: response.data }))
});

export const addUser = (body) => ({
  type: ADD_USER,
  payload: post({ url: endpoints.createUser, body })
});

export const deleteUser = async (id) => {
  const fetch = await deletes({ url: endpoints.deleteUser(id) });
  return fetch;
};
