import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { signoutUser } from '../actions/loginActions';

import PrivateRoutes from './privateRoutes';
import AccountRoutes from './accountRoutes';
import PrivateRoutesComponent from './utils/privateRouteComponent';

const RoutesContainer = function () {
  const token = useSelector((state) => state.loginReducer.user);

  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (token && token.length) {
      axios.defaults.headers.common = { 'auth-token': token };
    } else {
      // Si no existe el user, tenemos que borrar toda la informacion
      dispatch(signoutUser());
    }

    setLoading(false);
  }, [dispatch, token]);

  if (loading) return null;

  return (
    <Switch>
      <PrivateRoutesComponent
        pathnameRedirect="/private/user"
        isAuth={!token.length}
        component={AccountRoutes}
        path="/account"
      />
      <PrivateRoutesComponent
        pathnameRedirect="/account/login"
        isAuth={token.length}
        component={PrivateRoutes}
        path="/"
      />
    </Switch>
  );
};

export default RoutesContainer;
