import {
    GET_CUSTOMER_STATISTICS,
  } from '../actions/statsAction';

  const INITIAL_STATE = {
    isFetching: false,
    didInvalidate: false,
    customers: [],
    unregisteredCustomers: [],
    registeredCustomers: []
  };

  const pointSaleStats = (state = INITIAL_STATE, action) => {

    switch (action.type) {
  
      case `${GET_CUSTOMER_STATISTICS}_REJECTED`:
        return {
          ...state,
          isFetching: false,
          didInvalidate: true,
        };
      case `${GET_CUSTOMER_STATISTICS}_PENDING`:
        return {
          ...state,
          isFetching: true,
          didInvalidate: false,
        };
      case `${GET_CUSTOMER_STATISTICS}_FULFILLED`:
        const { customers, unregisteredCustomers, registeredCustomers } = action.payload
  
        return {
          ...state,
          isFetching: false,
          didInvalidate: false,
          customers,
          unregisteredCustomers,
          registeredCustomers
        };
  

      default:
        return state;
    }
  };
  
  export default pointSaleStats;