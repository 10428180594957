import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateNotification, getNotifications, singleNotification } from '../../../actions/notificationActions';

import ErrorHandling from '../../commons/errorHandling';
import Form from './components/form';

export default function ({ history, match }) {
  const dispatch = useDispatch();
  const notifications = useSelector(((state) => state.notificationsReducer));

  const notificationId = match.params.id;
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  useEffect(() => {
    dispatch(singleNotification(notificationId));
  }, [dispatch, notificationId]);

  const onSubmit = (values) => {
    setLoadingSubmit(true);
    updateNotification(notificationId, values)
      .then(() => {
        history.push('/private/notifications');
        dispatch(getNotifications());
        setLoadingSubmit(false);
      })
      .catch(() => setLoadingSubmit(false));
  };

  if (notifications.didInvalidateSingle) {
    return <ErrorHandling />;
  }

  return (
    <Form
      onSubmit={onSubmit}
      history={history}
      isEdit
      notice={notifications.single}
      title="Editando Notificación"
      loading={notifications.isFetchingSingle}
      loadingSubmit={loadingSubmit}
    />
  );
}
