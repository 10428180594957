import axios from "axios";
import api from "../utils/functions/api/index";
import endpoints from "../utils/functions/api/endpoints";
import { uploadImage } from "./firebase";

const { get, post, put, deletes } = api;

export const ALL_PUNTOS_VENTA = "ALL_PUNTOS_VENTA";
export const ONE_PUNTO_VENTA = "ONE_PUNTO_VENTA";
export const CREATE_PUNTO_VENTA = "CREATE_PUNTO_VENTA";

export const getPuntosVentas = (page, search) => ({
  type: ALL_PUNTOS_VENTA,
  payload: get({ url: endpoints.psAll(page, search) }).then((res) => ({
    puntosDeVenta: res.puntosDeVenta,
    totalPages: res.totalPages,
    currentPage: res.currentPage,
  })),
});

export const getPuntosVentasPorId = (search) => ({
  type: ALL_PUNTOS_VENTA,
  payload: get({ url: endpoints.psAllById(search) }).then((res) => ({
    puntosDeVenta: res.puntosDeVenta,
  })),
});

export const getOnePuntoVenta = (id) => ({
  type: ONE_PUNTO_VENTA,
  payload: get({ url: endpoints.psSingle(id) }).then((res) => ({
    puntoVenta: res,
  })),
});

export const postPuntoVenta = (body) => ({
  type: CREATE_PUNTO_VENTA,
  payload: post({ url: endpoints.createPs, body })
    .then(async (res) => {
      const base64 = res.imgQr.slice(22);
      const imgGuardada = await uploadImage(base64);
      await axios.post(`/upload/generate-qr/${res.id}`, {
        codigoImg: imgGuardada.metadata.fullPath,
      });
    })
    .catch((error) => Promise.reject(error)),
});

export const editPuntoVenta = (id, body) => ({
  type: ONE_PUNTO_VENTA,
  payload: put({ url: endpoints.editPs(id), body }).then((res) => ({
    puntoVenta: res,
  })),
});

export const deletePuntoVenta = async (id) => {
  const fetch = await deletes({ url: endpoints.deletePS(id) });
  return fetch;
};

export const sendCredentialsToPointSale = async (id) => {
  const fetch = await get({ url: endpoints.sendPsWsSingle(id) });
  return fetch;
};

export const sendCredentialsToPointSales = async () => {
  const fetch = await get({ url: endpoints.sendPsWs });
  return fetch;
};
