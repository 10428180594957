import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ErrorHandling from '../../commons/errorHandling';
import Form from './components/form';


import Loading from '../../commons/loading';

import { editPromotion, allPromotions, singlePromotion as fetchSinglePromotion } from '../../../actions/promotionsActions';

export default function ({ history, match }) {
  const promId = match.params.id;
  //const promotionSate = useSelector(((state) => state.promotionsReducer));
  const didInvalidateSingle = useSelector(((state) => state.promotionsReducer.didInvalidateSingle));
  const isFetchingSingle = useSelector(((state) => state.promotionsReducer.isFetchingSingle));
  const singlePromotion = useSelector(((state) => state.promotionsReducer.singlePromotion));
  
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSinglePromotion(promId));
    // eslint-disable-next-line
  }, []);

  const onSubmit = (values) => {
    setLoadingSubmit(true);
    const {
      name, imgUrl, clientsBlive, expirationDate, imgSecondary, numberCategorization
    } = values;

    editPromotion(match.params.id, {
      name, imgUrl, clientsBlive, expirationDate, imgSecondary, numberCategorization
    })
      .then(() => {
        history.push('/private/promotions');
        dispatch(allPromotions());
        setLoadingSubmit(false);
      })
      .catch(() => setLoadingSubmit(false));
  };

  if (didInvalidateSingle) {
    return <ErrorHandling />;
  }

  if (isFetchingSingle) return <Loading />;

  return (
    <Form
      onSubmit={onSubmit}
      history={history}
      isEdit
      promotion={singlePromotion}
      title="Editando Promoción"
      loading={isFetchingSingle}
      loadingSubmit={loadingSubmit}
    />
  );
}
