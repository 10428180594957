import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { loadCSS } from 'fg-loadcss';

import {
  List,
  Icon
} from '@material-ui/core';

import {
  CardGiftcard, StoreMallDirectory, AccountCircle, Announcement, NotificationsActive, Explore, ViewCarouselOutlined, Money
} from '@material-ui/icons';

import ListItemComponent from './listItem';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: '#FFF'
  },
  nested: {
    paddingLeft: theme.spacing(4),
    color: '#171D33'
  },
  icon: {
    minWidth: 56,
  },
  itemText: {
    fontFamily: "'Sarabun', sans-serif",
    fontStyle: 'normal',
    fontWeight: '600',
    letterSpacing: 0.5,
    color: '#171D33'
  }
}));

export default function NestedList() {
  const classes = useStyles();

  React.useEffect(() => {
    const node = loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css'),
    );

    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >

      <ListItemComponent
        path="/private/user"
        selected={window.location.pathname.includes('/private/user')}
        title="Usuarios"
        icon={<AccountCircle style={{ color: '#0069FF' }} fontSize="small" />}
      />

      <ListItemComponent
        path="/private/promotions"
        selected={window.location.pathname.includes('/private/promotions')}
        title="Promociones"
        icon={<CardGiftcard style={{ color: '#fc6603' }} fontSize="small" />}
      />

      {/* <ListItemComponent
        path="/private/coupons"
        selected={window.location.pathname.includes('/private/coupons')}
        title="Cupones"
        icon={<Icon style={{ color: '#D7DF01' }} fontSize="small" className="far fa-check-circle" />}
      /> */}

      <ListItemComponent
        path="/private/news"
        selected={window.location.pathname.includes('/private/news')}
        title="Novedades"
        icon={<Announcement style={{ color: '#648dae' }} fontSize="small" />}
      />

      <ListItemComponent
        path="/private/pointsSale"
        selected={window.location.pathname.includes('/private/pointsSale')}
        title="Puntos de venta"
        icon={<StoreMallDirectory style={{ color: '#29C397' }} fontSize="small" />}
      />

      <ListItemComponent
        path="/private/notifications"
        selected={window.location.pathname.includes('/private/notifications')}
        title="Notificaciones"
        icon={<NotificationsActive style={{ color: '#ffc52d' }} fontSize="small" />}
      />

      <ListItemComponent
        path="/private/mainPage"
        selected={window.location.pathname.includes('/private/mainPage')}
        title="Pantalla principal"
        icon={<ViewCarouselOutlined style={{ color: '#ff0000' }} fontSize="small" />}
      />

      <ListItemComponent
        path="/private/bingo"
        selected={window.location.pathname.includes('/private/bingo')}
        title="Bingo"
        icon={<Money style={{ color: '#648dae' }} fontSize="small" />}
      />
      {/*
      <ListItemComponent
        path="/private/routes"
        selected={window.location.pathname.includes('/private/routes')}
        title="Rutas"
        icon={<Explore style={{ color: '#f23' }} fontSize="small" />}
      /> */}

      {/* <ListItemComponent
        path="/private/sellers"
        selected={window.location.pathname.includes('/private/sellers')}
        title="Vendedores"
        icon={<PeopleAlt style={{ color: '#5f4b8b' }} fontSize="small" />}
      /> */}

    </List>
  );
}
