import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { store, persistor } from './store';

// importando rutas
import Routes from './routes/routesIndex';
import config from './config';

export default function () {
  const [isLoadingApp, setIsLoadingApp] = useState(true);

  useEffect(() => {
    // Configuro la url base de axios antes de cargar la web
    axios.defaults.baseURL = `${config.API_URL}`;
    axios.defaults.withCredentials = false;
    setIsLoadingApp(false);
  }, []);

  if (isLoadingApp) return null;


  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}
