import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addUser, getUsers } from '../../../actions/userActions';

import Form from './components/forms';
import useToast from '../../commons/toast';

export default function ({ history }) {
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const dispatch = useDispatch();
  const { Toast, call } = useToast();

  const onSubmit = (values) => {
    setLoadingSubmit(true);

    dispatch(addUser(values))
      .then(() => {
        history.push('/private/user');
        dispatch(getUsers());
        setLoadingSubmit(false);
      })
      .catch((err) => {
        call(err.data.message, 'warning');
        setLoadingSubmit(false);
      });
  };

  return (
    <>
      <Form
        history={history}
        onSubmit={onSubmit}
        title="Creando usuario:"
        loadingSubmit={loadingSubmit}
      />

      <Toast />
    </>

  );
}
