import api from "../utils/functions/api/index";
import endpoints from "../utils/functions/api/endpoints";

const { get } = api;

export const GET_CUSTOMER_STATISTICS = "GET_CUSTOMER_STATISTICS";

//Antes
// export const getCustomerStatistics = () => ({
//   type: GET_CUSTOMER_STATISTICS,
//   payload: get({ url: endpoints.stats })
//     .then((data) => data)
// });

//Despues
export const getCustomerStatistics = () => ({
  type: GET_CUSTOMER_STATISTICS,
  payload: get({ url: endpoints.stats }).then((data) => {
    return {
      customers: [],
      unregisteredCustomers: [],
      registeredCustomers: data,
    };
  }),
});
