import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { singleBingo, updateBingo, getBingos } from '../../../actions/bingoActions';

import Form from './components/forms/edit';

export default function ({ history, match }) {
  const bingoState = useSelector((state) => state.bingoReducer);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [dataExcel, setDataExcel] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(singleBingo(match.params.id));
  }, [dispatch, match]);

  const onSubmit = (values) => {
    values.clients = dataExcel
    setLoadingSubmit(true);
    dispatch(updateBingo(match.params.id, values))
      .then(() => {
        history.push('/private/bingo');
        dispatch(getBingos());
        setLoadingSubmit(false);
      })
      .catch(() => setLoadingSubmit(false));
  };

  return (
    <Form
      history={history}
      onSubmit={onSubmit}
      bingo={bingoState.singleBingo}
      title="Editando Bingo"
      loading={bingoState.isFetchingSingle}
      loadingSubmit={loadingSubmit}
      getExcel={setDataExcel}
    />
  );
}
