/* eslint-disable no-useless-escape */
import React, { useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import MaterialTable from 'material-table';

import {
  Edit, Visibility, Delete, RestorePage
} from '@material-ui/icons';

import colors from '../../../assets/colors';
import tableIcons from './utilsTable/tableIcons';
import TitleContainer from './utilsTable/titleWithFetchData';

import { parseDate, filterDate } from '../../../utils/functions/index';
import { deletePromotion } from '../../../actions/promotionsActions';

const columns = [
  {
    title: 'Nombre ', field: 'name',
  },
  // {
  //   title: 'Clientes Blive',
  //   field: 'clientsBlive',
  //   render: (rowData) => `${rowData.clientsBlive && rowData.clientsBlive.join('-').slice(0, 36)}`,
  // },
  {
    title: 'Estado',
    field: 'active',
    render: (rowData) => rowData.active ? 'Disponible' : 'No disponible/Vencido',
    lookup: { true: 'Disponible', false: 'No disponible/Vencido' }
  },
  {
    title: 'Número Categorización',
    field: 'numberCategorization',
  },
  {
    title: 'Fecha de Vencimiento',
    field: 'expirationDate',
    render: (rowData) => parseDate(rowData.expirationDate),
    customFilterAndSearch: (term, rowData) => filterDate(term, rowData.expirationDate),
  },
  {
    title: 'Fecha de Creacion',
    field: 'creationDate',
    render: (rowData) => parseDate(rowData.creationDate),
    customFilterAndSearch: (term, rowData) => filterDate(term, rowData.creationDate),
  },
];

export default ({
  data,
  loading,
  noRefresh,
  fetchData,
  history,
  title
}) => {
  const dispatch = useDispatch();
  const [loadingDisabled, setDisabled] = useState(false);

  return (
    <MaterialTable
      style={{ marginBottom: 16 }}
      icons={tableIcons}
      columns={columns}
      data={data}
      isLoading={loading}
      locale="es"
      title={(
        <TitleContainer
          title={title}
          noRefresh={noRefresh}
          fetchData={fetchData}
        />
      )}
      actions={[
        {
          icon: () => <Visibility style={{ color: colors.palette.dark }} />,
          tooltip: 'Ver promoción',
          onClick: (event, rowData) => history.push(`/private/singlePromotion/${rowData._id}`),
        },
        {
          icon: () => <Edit style={{ color: colors.palette.dark }} />,
          tooltip: 'Editar promoción',
          onClick: (event, rowData) => {
            history.push(`/private/editPromotion/${rowData._id}`);
          }
        },
        (rowData) => ({
          disabled: loadingDisabled,
          icon: () => rowData.active ? <Delete style={{ color: colors.palette.dark }} /> : <RestorePage style={{ color: colors.palette.dark }} />,
          tooltip: rowData.active ? 'Deshabilitar promoción' : 'Habilitar promoción',
          // onClick: () => (rowData.active || !rowData.active) && dispatch(deletePromotion(rowData._id)),
          onClick: () => {
            if (rowData.active || !rowData.active) {
              setDisabled(true);
              dispatch(deletePromotion(rowData._id))
                .then(() => setDisabled(false));
            }
          },
        })
      ]}

      options={{
        pageSize: 10,
        pageSizeOptions: [10, 20, 30, 40],
        actionsColumnIndex: -1,
        filtering: true,
        exportButton: true,
        headerStyle: {
          fontStyle: 'normal',
          fontWeight: '700',
          letterSpacing: 0.2,
          fontSize: '0.75rem',
          lineHeight: '1.3125rem',
          color: colors.palette.dark
        },
        cellStyle: {
          fontFamily: "'Sarabun', sans-serif",
          fontStyle: 'normal',
          fontWeight: '500',
          letterSpacing: 0.2,
          color: colors.palette.dark
        },
        rowStyle: {
          fontFamily: "'Sarabun', sans-serif",
          fontStyle: 'normal',
          fontWeight: '500',
          letterSpacing: 0.2,
          color: '#171D33',
        },
        search: false,
        // eslint-disable-next-line no-shadow
        exportCsv: (columns, dataExport) => {
          const test = [[
            'Nombre',
            'Clientes Blive',
            'Estado',
            'Fecha de Vencimiento',
            'Fecha de Creación',
          ],
          ...dataExport.map((column) => [
            column.name ? `\"${column.name}\"` : '---',
            column.clientsBlive ? `\"${column.clientsBlive}\"` : '---',
            column.active ? `\"${column.active}\"` : 'No disponible/Vencido',
            column.expirationDate ? `\"${moment(column.expirationDate).format('DD/MM/YYYY')}\"` : '---',
            column.creationDate ? `\"${moment(column.creationDate).format('DD/MM/YYYY')}\"` : '---',
          ])
          ];

          const encoding = 'data:text/csv;charset=utf-8,%EF%BB%BF';
          let csvContent = 'Promociones,\n';
          csvContent += `${test.map((e) => e.join(',')).join('\n')}`;
          const link = document.createElement('a');
          link.setAttribute('href', encoding + escape(csvContent));
          link.setAttribute('download', `${title}.csv`);

          document.body.appendChild(link); // Required for FF

          link.click();
        }
      }}
      localization={{
        toolbar: {
          searchPlaceholder: 'Buscar...',
          exportTitle: 'Exportar',
          exportName: 'Exportar a CSV'
        },
        header: {
          actions: 'Acciones'
        },
        pagination: {
          labelRowsSelect: 'filas'
        },
        body: {
          emptyDataSourceMessage: 'No hay promociones que mostrar'
        }
      }}
    />
  );
};
