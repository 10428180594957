import React from 'react';
import {
  makeStyles, FormControl, InputLabel, MenuItem, Select
} from '@material-ui/core';
import { Controller } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '68%',
    minWidth: 280,
  },
  errorInput: {
    color: 'red',
    fontSize: 12,
    margin: 0,
    paddingLeft: '10px',
    paddingBottom: '10px',
    paddingTop: 0
  },
}));

export default function MultipleSelect({
  defaultValue, control, name, rules, title, errors, values
}) {
  const classes = useStyles();

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id="dclientsBlive">{title}</InputLabel>
      <Controller
        defaultValue={defaultValue}
        control={control}
        name={name}
        rules={rules}
        as={(
          <Select
            variant="outlined"
            label={title}
            error={!!errors?.[name]?.message}
          >
            {values.map((value) => (
              <MenuItem key={value} value={value}>{value}</MenuItem>
            ))}
          </Select>
        )}
      />
    </FormControl>
  );
}
