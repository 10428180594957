import {
  POINT_SALE_LOGS,
  SINGLE_POINT_SALE_LOGS,
  PROMOTION_LOGS,
  SINGLE_PROMOTION_LOGS,
} from '../actions/logsActions';

const INITIAL_STATE = {
  isFetching: false,
  didInvalidate: false,
  isSingleFetching: false,
  didSingleInvalidate: false,
  pointSale: {
    logs: [],
    daily: [],
  },
  singlePointSale: {
    logs: [],
    daily: [],
  },
  promotionLogs: {
    logs: [],
    daily: [],
  },
  singlePromotion: {
    logs: [],
    daily: [],
  },
};

const providers = (state = INITIAL_STATE, action) => {
  switch (action.type) {

  case `${POINT_SALE_LOGS}_REJECTED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: true,
    };
  case `${POINT_SALE_LOGS}_PENDING`:
    return {
      ...state,
      isFetching: true,
      didInvalidate: false,
    };
  case `${POINT_SALE_LOGS}_FULFILLED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: false,
      pointSale: action.payload,
    };
  case `${SINGLE_POINT_SALE_LOGS}_REJECTED`:
    return {
      ...state,
      isSingleFetching: false,
      didSingleInvalidate: true,
    };
  case `${SINGLE_POINT_SALE_LOGS}_PENDING`:
    return {
      ...state,
      isSingleFetching: true,
      didSingleInvalidate: false,
    };
  case `${SINGLE_POINT_SALE_LOGS}_FULFILLED`:
    return {
      ...state,
      isSingleFetching: false,
      didSingleInvalidate: false,
      singlePointSale: action.payload,
    };

  case `${PROMOTION_LOGS}_REJECTED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: true,
    };
  case `${PROMOTION_LOGS}_PENDING`:
    return {
      ...state,
      isFetching: true,
      didInvalidate: false,
    };
  case `${PROMOTION_LOGS}_FULFILLED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: false,
      promotionLogs: action.payload,
    };
  case `${SINGLE_PROMOTION_LOGS}_REJECTED`:
    return {
      ...state,
      isSingleFetching: false,
      didSingleInvalidate: true,
    };
  case `${SINGLE_PROMOTION_LOGS}_PENDING`:
    return {
      ...state,
      isSingleFetching: true,
      didSingleInvalidate: false,
    };
  case `${SINGLE_PROMOTION_LOGS}_FULFILLED`:
    return {
      ...state,
      isSingleFetching: false,
      didSingleInvalidate: false,
      singlePromotion: action.payload,
    };

  default:
    return state;
  }
};

export default providers;
