import React from "react";
import { Button } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import styles from "./styles";

export default ({
  headersTable,
  dataTable,
  keyTable,
  nameCSV,
  text,
  disabled,
}) => {
  const classes = styles();
  const downloadCSVData = () => {
    let result = headersTable + "\n";
    dataTable.forEach((item) => {
      keyTable.forEach((key, i) => {
        if (key === "date" || key === "registrationDate") {
          const convertDate = new Date(item[key]);
          const dayStr =
            convertDate.getDate() < 10
              ? "0" + convertDate.getDate()
              : convertDate.getDate();
          const monthStr =
            convertDate.getMonth() + 1 < 10
              ? "0" + (convertDate.getMonth() + 1)
              : convertDate.getMonth() + 1;
          const yearStr = convertDate.getFullYear().toString().slice(2);
          item[key] = `${dayStr}/${monthStr}/${yearStr}`;
        }
        result += item[key] + ",";
        if (i === keyTable.length - 1) result += "\n";
      });
    });

    let element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(result)
    );
    element.setAttribute("download", `${nameCSV}.csv`);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  return (
    <div>
      <Button
        variant='contained'
        className={classes.button}
        onClick={() => downloadCSVData()}
        startIcon={<CloudUploadIcon />}
        disabled={disabled}
      >
        {text}
      </Button>
    </div>
  );
};
