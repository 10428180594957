import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import {
  Card, CardContent, Typography, IconButton, CardActions
} from '@material-ui/core';

import { ArrowBack } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import { singleUser, deleteUser, getUsers } from '../../../actions/userActions';
import Loading from '../../commons/loading';
import ButtonCustom from '../../commons/button';
import ButtonDialog from '../../commons/button/dialog';

const useStyles = makeStyles({
  container: {
    width: '85%',
    margin: '0 auto'
  },
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
    textAlign: 'center'
  },

  infoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    paddingTop: 3
  },
  info: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flex: 1,
    border: 'ridge',
    padding: '30px',
    width: '40vh',
    paddingLeft: '20vh'
  },
  principal: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  edit: {
    marginTop: 12,
    margin: '0 auto',
    display: 'flex'
  }
});

export default function ({ match, history }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(((state) => state.userReducer));

  useEffect(() => {
    dispatch(singleUser(match.params.id));
  }, [dispatch, match]);

  const handleEdit = () => history.push(`/private/editUser/${match.params.id}`);
  const handleDelete = async () => {
    await deleteUser(match.params.id);
    await dispatch(getUsers());
    history.push('/private/users');
  };

  if (user.isFetchingSingle) {
    return (
      <Loading />
    );
  }

  return (
    <div className={classes.container}>
      <Card className={classes.root}>
        <CardContent>
          <IconButton
            onClick={() => history.goBack()}
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <ArrowBack className={classes.icon} />
          </IconButton>
          <Typography className={classes.title} color="textSecondary" gutterBottom>Detalle de Usuario</Typography>
          <div className={classes.principal}>
            <div className={classes.info}>
              <div className={classes.infoContainer}>
                <Typography variant="h6" component="p">Nombre completo: &nbsp;</Typography>
                <Typography variant="subtitle1" component="p" className={classes.text}>{user.singleUser.fullName}</Typography>
              </div>

              <div className={classes.infoContainer}>
                <Typography variant="h6" component="p">Correo Electrónico: &nbsp;</Typography>
                <Typography variant="subtitle1" component="p" className={classes.text}>{user.singleUser.email}</Typography>
              </div>

              <div className={classes.infoContainer}>
                <Typography variant="h6" component="p">Rol asignado: &nbsp;</Typography>
                <Typography variant="subtitle1" component="p" className={classes.text}>{user.singleUser.role}</Typography>
              </div>

              <div className={classes.infoContainer}>
                <Typography variant="h6" component="p">Fecha de creación: &nbsp;</Typography>
                <Typography variant="subtitle1" component="p" className={classes.text}>{moment(user.singleUser.date).format('DD/MM/YYYY')}</Typography>
              </div>

            </div>
          </div>
        </CardContent>
        <CardActions>
          <div className={classes.edit}>
            <ButtonCustom text="Editar" color="default" edit action={handleEdit} />
            <ButtonDialog
              action={handleDelete}
              title="Deshabilitar Usuario?"
              description="Tenga en cuenta que una vez borrado, no habrá vuelta atrás!"
              active
            />
          </div>
        </CardActions>
      </Card>
    </div>
  );


}
