import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Route, Switch, Redirect } from 'react-router-dom';

import Drawer from '../layout/commons/drawer/drawer';
import Header from '../layout/commons/header/header';

import PuntosVenta from '../layout/ui/puntosVenta';
import SinglePointSale from '../layout/ui/puntosVenta/Single';
import AddPointSale from '../layout/ui/puntosVenta/Add';
import EditPointSale from '../layout/ui/puntosVenta/Edit';

import Coupons from '../layout/ui/coupon';
import SingleCoupon from '../layout/ui/coupon/Single';
import AddCoupon from '../layout/ui/coupon/Add';
import EditCoupon from '../layout/ui/coupon/Edit';

import User from '../layout/ui/user/index';
import SingleUser from '../layout/ui/user/Single';
import EditUser from '../layout/ui/user/Edit';
import AddUser from '../layout/ui/user/Add';

import Promotions from '../layout/ui/promotions/index';
import AddPromotion from '../layout/ui/promotions/Add';
import EditPromotion from '../layout/ui/promotions/Edit';
import SinglePromotion from '../layout/ui/promotions/Single';

import News from '../layout/ui/news/index';
import AddNew from '../layout/ui/news/Add';
import EditNew from '../layout/ui/news/Edit';
import SingleNew from '../layout/ui/news/Single';

import Notifications from '../layout/ui/notifications/index';
import AddNotification from '../layout/ui/notifications/Add';
import EditNotification from '../layout/ui/notifications/Edit';
import SingleNotification from '../layout/ui/notifications/Single';

import MainPage from '../layout/ui/mainPage';

import Bingo from '../layout/ui/bingo';
import SingleBingo from '../layout/ui/bingo/Single';
import EditBingo from '../layout/ui/bingo/Edit';
import AddBingo from '../layout/ui/bingo/Add';

// import Routes from '../layout/ui/routes/index';
// import AddRoute from '../layout/ui/routes/Add';
// import EditRoute from '../layout/ui/routes/Edit';
// import SingleRoute from '../layout/ui/routes/Single';

// import Sellers from '../layout/ui/sellers';
// import AddSeller from '../layout/ui/sellers/Add';
// import EditSeller from '../layout/ui/sellers/Edit';
// import SingleSeller from '../layout/ui/sellers/Single';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  root: {
    display: 'flex',
    flex: 1
  },
  toolbar: theme.mixins.toolbar,
}));

const PrivateRoutes = function () {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header />
      <Drawer />

      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Switch>
          <Route exact path="/private/user" component={User} />
          <Route exact path="/private/addUser" component={AddUser} />
          <Route exact path="/private/editUser/:id" component={EditUser} />
          <Route exact path="/private/singleUser/:id" component={SingleUser} />

          {/* <Route exact path="/private/coupons" component={Coupons} />
          <Route exact path="/private/addCoupon" component={AddCoupon} />
          <Route exact path="/private/editCoupon/:id" component={EditCoupon} />
          <Route exact path="/private/singleCoupon/:id" component={SingleCoupon} /> */}

          <Route exact path="/private/promotions" component={Promotions} />
          <Route exact path="/private/addPromotion" component={AddPromotion} />
          <Route exact path="/private/editPromotion/:id" component={EditPromotion} />
          <Route exact path="/private/singlePromotion/:id" component={SinglePromotion} />

          <Route exact path="/private/pointsSale" component={PuntosVenta} />
          <Route exact path="/private/addPointSale" component={AddPointSale} />
          <Route exact path="/private/pointsSale/:id" component={SinglePointSale} />
          <Route exact path="/private/editPointSale/:id" component={EditPointSale} />

          <Route exact path="/private/news" component={News} />
          <Route exact path="/private/addNew" component={AddNew} />
          <Route exact path="/private/editNew/:id" component={EditNew} />
          <Route exact path="/private/new/:id" component={SingleNew} />

          <Route exact path="/private/notifications" component={Notifications} />
          <Route exact path="/private/addNotification" component={AddNotification} />
          <Route exact path="/private/editNotification/:id" component={EditNotification} />
          <Route exact path="/private/notification/:id" component={SingleNotification} />

          <Route exact path="/private/mainPage" component={MainPage} />
          
          <Route exact path="/private/bingo" component={Bingo} />
          <Route exact path="/private/addBingo" component={AddBingo} />
          <Route exact path="/private/editBingo/:id" component={EditBingo} />
          <Route exact path="/private/singleBingo/:id" component={SingleBingo} />

          {/* <Route exact path="/private/routes" component={Routes} />
          <Route exact path="/private/addRoute" component={AddRoute} />
          <Route exact path="/private/editRoute/:id" component={EditRoute} />
          <Route exact path="/private/route/:id" component={SingleRoute} /> */}

          {/* <Route exact path="/private/sellers" component={Sellers} />
          <Route exact path="/private/addSeller" component={AddSeller} />
          <Route exact path="/private/editSeller/:id" component={EditSeller} />
          <Route exact path="/private/seller/:id" component={SingleSeller} /> */}

          <Route exact path="*">
            <Redirect to="/private/user" />
          </Route>

        </Switch>
      </main>
    </div>
  );
};

export default PrivateRoutes;
