import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { ArrowBack } from '@material-ui/icons';
import {
  Card, CardContent, Typography, IconButton, CircularProgress, CardActions, makeStyles, Button
} from '@material-ui/core';


import { getDownloadUrl } from '../../../actions/firebase';
import { fetchSinglePromotionLogs } from '../../../actions/logsActions';
import {
  singlePromotion, deletePromotion, clean
} from '../../../actions/promotionsActions';

import Loading from '../../commons/loading';
import ButtonCustom from '../../commons/button';
import ButtonDialog from '../../commons/button/dialog';
import img_error from '../../../assets/images/err/error_image.jpg';
import { LogContainer, LogCard } from '../../commons/logs';

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  children: {
    width: '100%'
  },
  infoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  containerData: {
    display: 'flex',
    margin: '0 auto',
    width: '80%',
    marginTop: 24,
    justifyContent: 'space-evenly'
  },
  containerImg: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
    marginTop: 18,
  },
  root: {
    minWidth: 900,
    maxWidth: 1300,
    margin: '0 auto',
  },
  title: {
    fontSize: 24,
    textAlign: 'center'
  },
  principal: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  img: {
    display: 'block',
    width: '35vh',
    height: 242,
    margin: '0 12px'
  },
  menuButton: {
    marginLeft: 12
  },
  description: {
    color: 'hsla(0, 0%, 0%, 0.54)',
    fontSize: 16,
    textTransform: 'capitalize'
  },
  info: {
    margin: 12,
    fontSize: 18
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'center'
  }
});

export default function ({ match, history }) {
  const classes = useStyles();
  const { id } = match.params;

  const dispatch = useDispatch();
  const promotionSate = useSelector(((state) => state.promotionsReducer));

  const [image, setImage] = useState(null);
  const [loadingImage, setLoadingImage] = useState(true);
  const [secondaryImage, setSecondaryImage] = useState(null);
  const [loadingSecondaryImage, setLoadingSecondaryImage] = useState(true);
  
  const [showAllClientCodes, setShowAllClientCodes ] = useState(false);

  useEffect(() => {
    dispatch(singlePromotion(id));
    // TODO: Rompe
    // dispatch(fetchSinglePromotionLogs(id));
    return function cleanUp() {
      dispatch(clean());
    };
  }, [dispatch, id]);

  useEffect(() => {
    if (promotionSate.singlePromotion.imgUrl) {
      getDownloadUrl(promotionSate.singlePromotion.imgUrl)
        .then((img) => {
          setImage(img);
          setLoadingImage(false);
        })
        .catch(() => setLoadingImage(false));
    } else {
      setLoadingImage(false);
    }

    if (promotionSate.singlePromotion.imgSecondary) {
      getDownloadUrl(promotionSate.singlePromotion.imgSecondary)
        .then((img) => {
          setSecondaryImage(img);
          setLoadingSecondaryImage(false);
        })
        .catch(() => setLoadingSecondaryImage(false));
    } else {
      setLoadingSecondaryImage(false);
    }

    return function cleanUp() {
      setImage(null);
      setSecondaryImage(null);
    };
  }, [promotionSate]);

  const handleDeleteCoupon = async () => {
    await dispatch(deletePromotion(id));
    history.push('/private/promotions');
  };
  const logsState = useSelector(((state) => state.logsReducer));

  const editCoupon = () => history.push(`/private/editPromotion/${id}`);

  if (promotionSate.isFetchingSingle) return <Loading />;

  return (
    <>
      <div className={classes.container}>
        <Card className={classes.root}>
          <CardContent>
            <IconButton
              onClick={() => history.goBack()}
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
            >
              <ArrowBack />
            </IconButton>
            <Typography className={classes.title} color="textSecondary" gutterBottom>Detalle de la Promoción</Typography>
            <div className={classes.infoContainer}>

              <div className={classes.containerImg}>
                {loadingImage ? <CircularProgress /> : <img className={classes.img} src={!image && !loadingImage ? img_error : image} alt="img" />}
                {loadingSecondaryImage ? <CircularProgress /> : <img className={classes.img} src={!secondaryImage && !loadingSecondaryImage ? img_error : secondaryImage} alt="img" />}
              </div>

              <div className={classes.containerData}>
                <div className={classes.children}>
                  <div className={classes.info}>
                    Nombre: &nbsp;
                    <span className={classes.description}>{promotionSate.singlePromotion.name}</span>
                  </div>
                  <div className={classes.info}>
                    Disponibilidad &nbsp;
                    <span className={classes.description}>{promotionSate.singlePromotion.active ? 'Se encuentra activo' : 'Se encuentra desabilitado/vencido. '}</span>
                  </div>
                </div>

                <div className={classes.children}>
                  <div className={classes.info}>
                    Fecha de Vencimiento: &nbsp;
                    <span className={classes.description}>{moment(promotionSate.singlePromotion.expirationDate).format('DD/MM/YYYY')}</span>
                  </div>
                  <div className={classes.info}>
                    Fecha de creación: &nbsp;
                    <span className={classes.description}>{moment(promotionSate.singlePromotion.creationDate).format('DD/MM/YYYY')}</span>
                  </div>
                </div>


                {/* <div className={classes.children}>
                  <div className={classes.info}>
                    Clientes Blive: &nbsp;
                    { promotionSate.singlePromotion.clientsBlive && (
                      <>
                        {
                        promotionSate.singlePromotion.clientsBlive
                          .slice(0, (
                            showAllClientCodes
                              ? promotionSate.singlePromotion.clientsBlive.length
                              : 3
                          ))
                          .map((client) => <span className={classes.description} key={client}>{client}-</span>)
                        }
                        { showAllClientCodes ? null : <span className={classes.description}>...</span>}
                        { 
                          showAllClientCodes
                            ? <Button color="default" onClick={() => setShowAllClientCodes(false)}>Ver menos</Button> 
                            : <Button color="default" onClick={() => setShowAllClientCodes(true)}>Ver todos ({promotionSate.singlePromotion.clientsBlive.length})</Button> 
                        }
                      </>
                    )}
                  </div>
                </div> */}

              </div>
            </div>
          </CardContent>
          <CardActions className={classes.btnContainer}>
            <ButtonCustom edit text="Editar" color="default" action={editCoupon} />
            <ButtonDialog
              action={handleDeleteCoupon}
              active={promotionSate.singlePromotion && (promotionSate.singlePromotion.active)}
              title={promotionSate.singlePromotion.active ? 'Deshabilitar Promoción?' : 'Habilitar Promoción'}
              description="Tenga en cuenta que una vez borrado, no habrá vuelta atrás!"
            />
          </CardActions>
        </Card>
      </div>
      <LogContainer isFeching={logsState.isSingleFetching}>
        <LogCard
          title={`${logsState.singlePromotion.daily.filter((rec) => rec.action === 'AddFavorite').length} puntos`}
          subtitle="de venta la añadieron hoy"
        />
        <LogCard
          title={`${logsState.singlePromotion.logs.filter((rec) => rec.action === 'AddFavorite').length} puntos`}
          subtitle="de venta la añadieron en total"
        />
      </LogContainer>
    </>
  );
}
