import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar } from '@material-ui/core';

import TextRegular from '../text/text';
import IconUser from './iconUser';
import colors from '../../../assets/colors';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: colors.palette.dark
  },
  menuButton: {
    marginRight: 36,
    color: '#FFF'
  },
  hide: {
    display: 'none'
  },
  button: {
    root: {
      color: '#FFF',
    },
    input: {
      color: '#FFF',
    }
  }
}));

export default () => {
  const classes = useStyles();

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <div style={{ flex: 1 }}>
          <TextRegular
            text="Administrador Cuponera"
            size={18}
            color="#FFF"
            type="primary"
          />
        </div>

        <IconUser />
      </Toolbar>
    </AppBar>
  );
};
