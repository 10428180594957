import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';

import loginReducer from './loginReducer';
import logsReducer from './logsReducer';
import couponsReducer from './couponsReducer';
import puntosVentaReducer from './puntosVentaReducer';
import userReducer from './userReducer';
import promotionsReducer from './promotionsReducer';
import noticesReducer from './noticesReducer';
import notificationsReducer from './notificationsReducer';
import routesReducer from './routesReducer';
import sellersReducer from './sellersReducer';
import bingoReducer from './bingoReducer';
import statsReducer from './statsReducer';

const allReducers = combineReducers({
  loginReducer,
  couponsReducer,
  promotionsReducer,
  puntosVentaReducer,
  userReducer,
  logsReducer,
  noticesReducer,
  notificationsReducer,
  routesReducer,
  sellersReducer,
  bingoReducer,
  statsReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'SIGNOUT_REQUEST') {
    Object.keys(state).forEach((key) => storage.removeItem(`persist:${key}`));
    const newState = undefined;

    return allReducers(newState, action);
  }

  return allReducers(state, action);
};

export default rootReducer;
