import React from 'react';
import { makeStyles, Fab } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import colors from '../../../assets/colors';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    marginBottom: 16
  },
  fab: {
    backgroundColor: colors.palette.primary,
  },
});

export default function AddIcon({ onClick }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Fab
        aria-label="Agregar"
        onClick={onClick}
        className={classes.fab}
      >
        <Add className={classes.extendedIcon} />
      </Fab>
    </div>
  );
}
