/* eslint-disable use-isnan */
/* eslint-disable radix */

import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ArrowBack } from '@material-ui/icons';
import {
  TextField,
  Button,
  Card,
  CardContent,
  IconButton,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';

import moment from 'moment';
import ImageUploader from 'react-images-upload';

import Select from '../../../../commons/form/select';
import Loading from '../../../../commons/loading';
import { uploadImage, getDownloadUrl } from '../../../../../actions/firebase';

import AddCategories from '../../../../commons/excel';
import Picker from '../categories';
import campos from '../categories/categories';
import colors from '../../../../../assets/colors';

const useStyles = makeStyles((theme) => ({
  errorInput: {
    color: 'red',
    fontSize: 12,
    margin: 0,
    paddingLeft: '10px',
    paddingBottom: '10px',
    paddingTop: 0
  },
  input: {
    width: '100vh',
    margin: theme.spacing(1),
  },
  fatherDiv: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '3%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  title: {
    fontSize: 'x-large',
    fontFamily: 'unset'
  },
  title2: {
    fontFamily: 'unset',
    textAlign: 'center',
    fontSize: 18
  },
  button: {
    width: '30vh',
    background: colors.palette.primary,
    fontFamily: 'unset',
    color: '#fafafa',
    '&:hover': {
      background: colors.palette.secondary,
      color: colors.palette.dark
    }
  },
  root: {
    minWidth: 255,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  imgContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    marginTop: 16,
    maxHeight: 200,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  categories: {
    display: 'flex',
    justifyContent: 'center',
    margin: 6
  },
  inputsRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly'
  },
  clentsBliveError: {
    margin: 0,
    marginTop: 32,
    fontWeight: 'bold',
    fontSize: 14,
    color: 'red',
    whiteSpace: 'pre-wrap',
  },
}));

export default ({
  title,
  onSubmit,
  promotion,
  loading,
  isEdit,
  history,
  loadingSubmit
}) => {
  const classes = useStyles();
  const {
    control,
    handleSubmit,
    errors,
    setValue,
  } = useForm();

  // imagen principal
  const [picture, setPicture] = useState(false);
  const [imgFirebase, setImgFirebase] = useState(false);
  const [loadingImg, setLoadingImg] = useState(false);
  const [errorImg, setErrorImg] = useState(false);
  const [uploadedMainPicture, setUploadedMainPicture] = useState(false);

  // imagen secundaria (single view)
  const [secondaryPicture, setSecondaryPicture] = useState(false);
  const [secondaryImgFirebase, setSecondaryImgFirebase] = useState(false);
  const [loadingSecondaryImg, setLoadingSecondaryImg] = useState(false);
  const [errorSecondaryImg, setErrorSecondaryImg] = useState(false);
  const [uploadSecondaryPicture, setUplaodSecondaryPicture] = useState(false);

  const [loadingSend, setLoadingSend] = useState(false);
  const [estado, setEstado] = useState(true);
  const [clientsBlive, setClientsBlive] = useState([]);
  const [clientsBliveError, setClientsBliveError] = useState('');

  React.useEffect(() => {
    if (promotion && isEdit) {
      setEstado(promotion.active);
      if (promotion.clientsBlive && promotion.clientsBlive.length) setClientsBlive(promotion.clientsBlive);
      setValue('expirationDate', moment(promotion.expirationDate).add(1, 'days').format('YYYY-MM-DD'));
      setErrorImg(false);

      setValue('name', promotion.name);
      setValue('numberCategorization', promotion.numberCategorization);
    }
  }, [isEdit, promotion, setValue]);

  React.useEffect(() => {

    if (promotion && promotion.imgUrl) {
      setImgFirebase(promotion.imgUrl);


      getDownloadUrl(promotion.imgUrl)
        .then((response) => {
      
          setPicture(response);
          setLoadingImg(false);
        })
        .catch(() => setLoadingImg(false));
    }

    if (promotion && promotion.imgSecondary) {
      setSecondaryImgFirebase(promotion.imgSecondary);
      getDownloadUrl(promotion.imgSecondary)
        .then((response) => {
          setSecondaryPicture(response);
          setLoadingSecondaryImg(false);
        })
        .catch(() => setLoadingSecondaryImg(false));
    }
  }, [promotion, loading]);

  const handleSubmitForm = async (values) => {
    setLoadingSend(true);

    if (!clientsBlive.length) {
      setClientsBliveError('Categorías requeridas');
      return;
    }

    if (picture && secondaryPicture && imgFirebase && secondaryImgFirebase && !uploadedMainPicture && !uploadSecondaryPicture) {
      // Ninguna de las imagenes es editada
      const imgGuardadaPrincipal = imgFirebase;
      const imgGuardadaSecundaria = secondaryImgFirebase;

      onSubmit({
        ...values,
        imgUrl: imgGuardadaPrincipal,
        imgSecondary: imgGuardadaSecundaria,
        active: estado,
        clientsBlive: [...new Set(clientsBlive)],
      });
      setLoadingSend(false);
    } else if (picture && secondaryPicture && uploadedMainPicture && uploadSecondaryPicture) {
      // Ambas imagenes se editaron
      const imgGuardadaPrincipal = await uploadImage(picture.slice(23), 'promotions');
      const imgGuardadaSecundaria = await uploadImage(secondaryPicture.slice(23), 'promotions');

      onSubmit({
        ...values,
        imgUrl: imgGuardadaPrincipal.metadata.fullPath,
        imgSecondary: imgGuardadaSecundaria.metadata.fullPath,
        active: estado,
        clientsBlive: [...new Set(clientsBlive)],
      });
      setLoadingSend(false);
    } else if (picture && secondaryPicture && uploadedMainPicture && !uploadSecondaryPicture) {
      // Se editó solo la primera imagen (main)
      const imgGuardadaPrincipal = await uploadImage(picture.slice(23), 'coupons');

      onSubmit({
        ...values,
        imgUrl: imgGuardadaPrincipal.metadata.fullPath,
        imgSecondary: secondaryImgFirebase,
        active: estado,
        clientsBlive: [...new Set(clientsBlive)],
      });
      setLoadingSend(false);

    } else if (picture && secondaryPicture && !uploadedMainPicture && uploadSecondaryPicture) {
      // Se editó solo la segunda imagen (description)
      const imgGuardadaSecundaria = await uploadImage(secondaryPicture.slice(23), 'coupons');

      onSubmit({
        ...values,
        imgUrl: imgFirebase,
        imgSecondary: imgGuardadaSecundaria.metadata.fullPath,
        active: estado,
        clientsBlive: [...new Set(clientsBlive)],
      });
      setLoadingSend(false);
    } else {
      setErrorImg(true);
      setErrorSecondaryImg(true);
      setLoadingSend(false);
    }
  };

  const onDrop = (file) => {
    if (!file[0]) return;
    setLoadingImg(true);
    setErrorImg(false);

    const reader = new FileReader();
    reader.readAsDataURL(file[0]);
    setUploadedMainPicture(true);

    reader.onloadend = async () => {
      const base64 = reader.result;
      setLoadingImg(false);
      setPicture(base64);
    };
  };

  const onDropSecondary = (file) => {
    if (!file[0]) return;
    setLoadingSecondaryImg(true);
    setErrorSecondaryImg(false);

    const reader = new FileReader();
    reader.readAsDataURL(file[0]);
    setUplaodSecondaryPicture(true);

    reader.onloadend = async () => {
      const base64 = reader.result;
      setLoadingSecondaryImg(false);
      setSecondaryPicture(base64);
    };
  };

  if (loading) return (<Loading />);

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <IconButton
          onClick={() => history.goBack()}
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
        >
          <ArrowBack className={classes.icon} />
        </IconButton>
        <div className={classes.fatherDiv}>
          <div className={classes.title}>{title}</div>

          <form onSubmit={handleSubmit(handleSubmitForm)}>
            <Controller
              className={classes.input}
              name="name"
              as={(
                <TextField
                  placeholder="Nombre"
                  label="Nombre de Promoción"
                  variant="outlined"
                  error={!!errors?.name?.message}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              rules={{
                required: isEdit ? false : 'La promoción debe tener un nombre',
              }}
              control={control}
            />
            <p className={classes.errorInput}>{errors?.name?.message}</p>

            <Select
              control={control}
              defaultValue={isEdit ? promotion.numberCategorization : ''}
              errors={errors}
              title="Numero de categorizacion"
              name="numberCategorization"
              rules={{
                required: isEdit ? false : 'La promoción debe tener una categorización',
              }}
              values={[11, 12, 13, 14, 15, 16, 17, 21, 22, 23, 24, 25, 26, 27, 31, 32, 33, 34, 35, 36, 37, 41, 42, 43, 44, 45, 46, 47]}
            />
            <p className={classes.errorInput}>{errors?.numberCategorization?.message}</p>

            <Controller
              className={classes.input}
              as={(
                <TextField
                  variant="outlined"
                  label="Fecha de Vencimiento"
                  type="date"
                  error={!!errors?.expirationDate?.message}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              control={control}
              name="expirationDate"
              rules={{
                required: 'Ingrese una fecha válida',
              }}
            />
            <p className={classes.errorInput}>{errors?.expirationDate?.message}</p>
            {/* // TODO: No funciona */}
            {/* <Picker field={estado} setField={setEstado} names={campos.estado} title="Estado" /> */}


            <div className={classes.inputsRow}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {clientsBliveError ? (
                  <p className={classes.clentsBliveError}>
                    {clientsBliveError}
                  </p>
                ) : null}
                <AddCategories initialCodes={clientsBlive} onChange={setClientsBlive} setError={setClientsBliveError} />
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div className={classes.inputsRow}>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <p className={classes.imgTitle}>Imagen principal</p>
                    {picture && (
                      <div className={classes.imgContainer}>
                        <img src={picture} alt="imagen" style={{ width: 180, height: 125 }} />
                      </div>
                    )}

                    {loadingImg && (
                      <p style={{
                        margin: 0, marginTop: 32, fontWeight: 'bold', fontSize: 16
                      }}
                      >Cargando imagen...
                      </p>
                    )}
                    {errorImg && (
                      <p style={{
                        margin: 0, marginTop: 32, fontWeight: 'bold', fontSize: 16, color: 'red'
                      }}
                      >Imagen requerida
                      </p>
                    )}
                    <ImageUploader
                      withIcon
                      singleImage
                      buttonText="Elegir imagen"
                      onChange={onDrop}
                      imgExtension={['.jpg']}
                      maxFileSize={5242880}
                      fileTypeError="debe ser .jpg"
                      label="Max file size: 5mb, accepted: .jpg"
                    />
                  </div>
                </div>
              </div>

              <div className={classes.inputsRow}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <p className={classes.imgTitle}>Imagen Vista Individual</p>
                  {secondaryPicture && (
                    <div className={classes.imgContainer}>
                      <img src={secondaryPicture} alt="imagen" style={{ width: 180, height: 125 }} />
                    </div>
                  )}

                  {loadingSecondaryImg && (
                    <p style={{
                      margin: 0, marginTop: 32, fontWeight: 'bold', fontSize: 16
                    }}
                    >Cargando imagen...
                    </p>
                  )}
                  {errorSecondaryImg && (
                    <p style={{
                      margin: 0, marginTop: 32, fontWeight: 'bold', fontSize: 16, color: 'red'
                    }}
                    >Agregar una imagen...
                    </p>
                  )}
                  <ImageUploader
                    withIcon
                    singleImage
                    buttonText="Elegir imagen"
                    onChange={onDropSecondary}
                    imgExtension={['.jpg']}
                    maxFileSize={5242880}
                    fileTypeError="debe ser .jpg"
                    label="Max file size: 5mb, accepted: .jpg"
                  />
                </div>
              </div>
            </div>

            <div className={classes.fatherDiv}>
              <Button
                className={classes.button}
                variant="outlined"
                size="large"
                type="submit"
                style={{ marginTop: 32 }}
                disabled={loadingSubmit || loadingSend}
              >
                { loadingSubmit || loadingSend ? <CircularProgress size={20} color="inherit" /> : 'Guardar' }
              </Button>
            </div>

          </form>
        </div>
      </CardContent>
    </Card>
  );
};
