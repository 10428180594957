import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  primary: {
    fontFamily: "'Sarabun', sans-serif",
    fontStyle: 'normal',
    fontWeight: '900',
    letterSpacing: 0.5,
    margin: 0,
    fontSize: ({ size }) => size,
    color: ({ color }) => color,
  },
  secondary: {
    fontFamily: "'Sarabun', sans-serif",
    fontStyle: 'normal',
    fontWeight: ({ selected }) => selected ? '800' : '500',
    letterSpacing: 0.2,
    margin: 0,
    fontSize: ({ size }) => size,
    color: ({ color }) => color,
  }
}));

export default ({
  text,
  size,
  color,
  type,
  selected
}) => {
  const classes = useStyles({ size, color, selected });

  return (<p className={classes[type]}>{text}</p>);
};
