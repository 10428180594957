import axios from 'axios';

const api = {
  get: (options) => new Promise((resolve, reject) => {
    axios.get(options.url)
      .then((res) => resolve(res.data))
      .catch((e) => reject(e.response));
  }),

  post: (options) => new Promise((resolve, reject) => {
    axios.post(options.url, options.body)
      .then((res) => resolve(res.data))
      .catch((e) => reject(e.response));
  }),

  put: (options) => new Promise((resolve, reject) => {
    axios.put(options.url, options.body)
      .then((res) => resolve(res.data))
      .catch((e) => reject(e.response));
  }),

  deletes: (options) => new Promise((resolve, reject) => {
    axios.delete(options.url)
      .then((res) => resolve(res.data))
      .catch((e) => reject(e.response));
  }),

};

export default api;
