import {
  GET_NOTIFICATIONS, SINGLE_NOTIFICATION
} from '../actions/notificationActions';

const INITIAL_STATE = {
  isFetching: false,
  didInvalidate: false,
  isFetchingSingle: false,
  didInvalidateSingle: false,
  codeError: null,
  list: [],
  single: {},
};

const noticeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {

  case `${GET_NOTIFICATIONS}_REJECTED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: true,
      codeError: action.payload.codeError
    };
  case `${GET_NOTIFICATIONS}_PENDING`:
    return {
      ...state,
      isFetching: true,
      didInvalidate: false,
    };
  case `${GET_NOTIFICATIONS}_FULFILLED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: false,
      list: action.payload.list
    };


  case `${SINGLE_NOTIFICATION}_REJECTED`:
    return {
      ...state,
      isFetchingSingle: false,
      didInvalidateSingle: true,
      codeError: action.payload.codeError
    };
  case `${SINGLE_NOTIFICATION}_PENDING`:
    return {
      ...state,
      isFetchingSingle: true,
      didInvalidateSingle: false,
    };
  case `${SINGLE_NOTIFICATION}_FULFILLED`:
    return {
      ...state,
      isFetchingSingle: false,
      didInvalidateSingle: false,
      single: action.payload.single
    };

  default:
    return state;
  }
};

export default noticeReducer;
