import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

//* Lo utilizamos para hacer los pedidos a la API en el momento en el que se monta el componente.

export default function useFetchData(action, dataCargada) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!dataCargada) {
      dispatch(action());
    }
  }, [dataCargada, action, dispatch]);
}
