import React from 'react';

import {
  makeStyles, Card, CardContent, CircularProgress
} from '@material-ui/core';

import colors from '../../../assets/colors';

export const useStyles = makeStyles({
  root: {
    minWidth: 600,
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: "column",
    marginTop: 16,
    alignItems: "center",
    marginBottom: 60,
  },
  card: {
    alignSelf: 'center',
    textAlign: 'center',
    width: 650,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    marginBottom: 12,
  },
  cardPicture: {
    alignSelf: 'flex-start',
    height: 120,
    width: 180,
  },
  cardButtons: {
    flex: 1,
    justifyContent: "flex-end",
    display: "flex",
    flexDirection: "row",
    paddingRight: 14,
  },

  title: {
    fontWeight: 200,
    margin: 0,
    marginBottom: 6,
  },
  carouselControls: {
    display: 'flex',
    flexDirection: "row",
    justifyContent: "space-between",
    width: 650,
    marginTop: 12,
  },
  btn: {
    backgroundColor: colors.palette.primary,
    '&:hover': {
      backgroundColor: '#e0e0e0',
      color: colors.palette.dark
    }
  },
  status: {
    fontSize: 18,
    lineHeight: "38px",
  },
  addBtn: {
    backgroundColor: "#ea2e2eff",
    color: "white",
    "&:hover": {
      backgroundColor: "#ff6666",
    }
  }
});

export const Container = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {children}
    </div>
  );
}