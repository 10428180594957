import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import EditIcon from "@material-ui/icons/Edit";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import VisibilityIcon from "@material-ui/icons/Visibility";
import moment from "moment";
import styles from "./styles";
import Alert from "../../../../../commons/Alert/index";
import { sendCredentialsToPointSale } from "../../../../../../actions/puntosVentaActions";

export default function BasicTable({ data, history }) {
  const classes = styles();
  const dispatch = useDispatch();
  const [alert, setAlert] = useState({ open: false, message: "" });

  const sendCredentials = async (id) => {
    try {
      await dispatch(sendCredentialsToPointSale(id));
      setAlert({ open: true, message: "Credenciales enviadas correctamente." });
    } catch {
      setAlert({
        open: true,
        message: "El usuario ya se encuentra registrado.",
      });
    }
  };

  function truncateString(str, limit, useWordBoundary) {
    if (str.length < limit) {
      return str;
    }
    const subString = str.substr(0, limit);
    return (
      (useWordBoundary
        ? subString.substr(0, subString.lastIndexOf(" "))
        : subString) + "..."
    );
  }

  return data?.length ? (
    <TableContainer className={classes.table} component={Paper}>
      <Table aria-label='simple table'>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell className={classes.tableTitleCell}></TableCell>

            <TableCell className={classes.tableTitleCell}></TableCell>

            <TableCell className={classes.tableTitleCell}></TableCell>

            <TableCell className={classes.tableTitleCell}>
              Cliente Blive
            </TableCell>
            <TableCell className={classes.tableTitleCell}>
              Cliente FEMSA
            </TableCell>
            <TableCell className={classes.tableTitleCell}>Registrado</TableCell>
            <TableCell className={classes.tableTitleCell}>Franquicia</TableCell>
            <TableCell className={classes.tableTitleCell}>Canal</TableCell>
            <TableCell className={classes.tableTitleCell}>
              Razón social
            </TableCell>
            <TableCell className={classes.tableTitleCell}>Localidad</TableCell>
            <TableCell className={classes.tableTitleCell}>Domicilio</TableCell>
            <TableCell className={classes.tableTitleCell}>
              Fecha de creación
            </TableCell>
            <TableCell className={classes.tableTitleCell}>
              Fecha de registro
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {data.map((row) => (
            <TableRow className={classes.row} key={row._id}>
              <TableCell
                component='th'
                scope='row'
                className={[classes.icon, classes.centeredCell]}
              >
                <WhatsAppIcon
                  style={{ cursor: "pointer", width: "15px" }}
                  onClick={() => sendCredentials(row._id)}
                />
              </TableCell>

              <TableCell
                component='th'
                scope='row'
                className={[classes.icon, classes.centeredCell]}
              >
                <EditIcon
                  style={{ cursor: "pointer", width: "15px" }}
                  onClick={() =>
                    history.push(`/private/editPointSale/${row._id}`)
                  }
                />
              </TableCell>

              <TableCell
                component='th'
                scope='row'
                className={[classes.icon, classes.centeredCell]}
              >
                <VisibilityIcon
                  style={{ cursor: "pointer", width: "15px" }}
                  onClick={() => history.push(`/private/pointsSale/${row._id}`)}
                />
              </TableCell>

              <TableCell
                component='th'
                scope='row'
                className={[classes.tableCell, classes.centeredCell]}
              >
                {row.clientBlive}
              </TableCell>
              <TableCell
                component='th'
                scope='row'
                className={[classes.tableCell, classes.centeredCell]}
              >
                {row.clientFemsa}
              </TableCell>
              <TableCell
                component='th'
                scope='row'
                className={[classes.tableCell, classes.centeredCell]}
              >
                {row.isAutenticated ? "Si" : "No"}
              </TableCell>
              <TableCell
                component='th'
                scope='row'
                className={[classes.tableCell, classes.centeredCell]}
              >
                {row.franchise}
              </TableCell>
              <TableCell
                component='th'
                scope='row'
                className={classes.tableCell}
              >
                {truncateString(row.channel, 16)}
              </TableCell>
              <TableCell
                component='th'
                scope='row'
                className={classes.tableCell}
              >
                {truncateString(row.businessName, 16)}
              </TableCell>
              <TableCell
                component='th'
                scope='row'
                className={classes.tableCell}
              >
                {row.localidad}
              </TableCell>
              <TableCell
                component='th'
                scope='row'
                className={classes.tableCell}
              >
                {truncateString(row.address, 16)}
              </TableCell>
              <TableCell
                component='th'
                scope='row'
                className={[classes.tableCell, classes.centeredCell]}
              >
                {moment(row.date).format("DD/MM/YY")}
              </TableCell>
              <TableCell
                component='th'
                scope='row'
                className={[classes.tableCell, classes.centeredCell]}
              >
                {row.registrationDate
                  ? moment(row.registrationDate).format("DD/MM/YY")
                  : "No tiene"}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {alert.open && (
        <Alert message={alert.message} open={alert.open} setAlert={setAlert} />
      )}
    </TableContainer>
  ) : (
    <div className={classes.notFound}>No se encontró resultados</div>
  );
}
