import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Form from './components/form';
import useToast from '../../commons/toast';

import { addPromotion, allPromotions } from '../../../actions/promotionsActions';

export default function ({ history }) {
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const dispatch = useDispatch();
  const { Toast, call } = useToast();

  const onSubmit = (values) => {
    setLoadingSubmit(true);

    dispatch(addPromotion(values))
      .then(() => {
        history.push('/private/promotions');
        dispatch(allPromotions());
        setLoadingSubmit(false);
      })
      .catch((res) => {
        call(res.data.message, 'warning');
        setLoadingSubmit(false);
      });
  };

  return (
    <>
      <Form
        history={history}
        onSubmit={onSubmit}
        title="Crear Promoción: "
        loadingSubmit={loadingSubmit}
      />
      <Toast />
    </>
  );
}
