import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles({
  table: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  tableHead: {
    backgroundColor: "#68011fe8",
  },
  tableTitleCell: {
    color: "#FFFFFF",
    padding: "10px",
    textAlign: "center",
  },
  tableCell: {
    padding: "10px",
    textAlign: "center",
  },
  centeredCell: {
    textAlign: "center",
  },
  icon: {
    padding: "5px",
  },
  notFound: {
    display: "flex",
    justifyContent: "center",
    fontSize: "18px",
    color: "red",
    fontWeight: "bold",
  },
  row: {
    // "&:hover, &:focus": {
    //     backgroundColor: '#b2172b14',
    //     cursor: 'pointer'
    // },
  },
});

export default styles;
