import api from '../utils/functions/api/index';
import endpoints from '../utils/functions/api/endpoints';

const { post } = api;

export const LOGIN_USER = 'LOGIN_USER';
export const SIGNOUT_REQUEST = 'SIGNOUT_REQUEST';

export const loginUser = (body) => ({
  type: LOGIN_USER,
  payload: post({ url: endpoints.loginUser, body })
    .then((res) => ({ userLogueado: res }))
    .catch(() => ({ userLogueado: {} }))
});

export const signoutUser = () => (dispatch) => {
  dispatch({
    type: SIGNOUT_REQUEST,
  });
};
