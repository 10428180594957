import api from '../utils/functions/api/index';
import endpoints from '../utils/functions/api/endpoints';

const {
  get, post, put, deletes
} = api;

export const ALL_COUPONS = 'ALL_COUPONS';
export const SINGLE_COUPON = 'SINGLE_COUPON';
export const ADD_COUPON = 'SINGLE_COUPON';

export const couponsAll = () => ({
  type: ALL_COUPONS,
  payload: get({ url: endpoints.couponsAll })
    .then((res) => ({ allCoupons: res }))
});

export const couponSingle = (id) => ({
  type: SINGLE_COUPON,
  payload: get({ url: endpoints.couponSingle(id) })
    .then((res) => ({ singleCoupon: res }))
});

export const addCoupon = (body) => ({
  type: ADD_COUPON,
  payload: post({ url: endpoints.createCoupon, body })
});

export const editCoupon = (id, body) => (
  put({ url: endpoints.editCoupon(id), body })
);

export const deleteCoupon = async (id) => {
  const fetch = await deletes({ url: endpoints.deleteCoupon(id) });
  return fetch;
};

export const clean = () => ({
  type: SINGLE_COUPON,
  payload: async () => ({ singleCoupon: {} })
});
