import {
  GET_SELLERS, SINGLE_SELLER, UPDATE_SELLER
} from '../actions/sellersAction';

const INITIAL_STATE = {
  isFetching: false,
  didInvalidate: false,
  allSellers: [],
  codeError: null,
  isFetchingSingle: false,
  didInvalidateSingle: false,
  singleSeller: {},
  updateSeller: {},
};

const sellersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {

  case `${GET_SELLERS}_REJECTED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: true,
      codeError: action.payload.codeError
    };
  case `${GET_SELLERS}_PENDING`:
    return {
      ...state,
      isFetching: true,
      didInvalidate: false,
    };
  case `${GET_SELLERS}_FULFILLED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: false,
      allSellers: action.payload.allSellers
    };

  case `${SINGLE_SELLER}_REJECTED`:
    return {
      ...state,
      isFetchingSingle: false,
      didInvalidateSingle: true,
      codeError: action.payload.codeError
    };
  case `${SINGLE_SELLER}_PENDING`:
    return {
      ...state,
      isFetchingSingle: true,
      didInvalidateSingle: false,
    };
  case `${SINGLE_SELLER}_FULFILLED`:
    return {
      ...state,
      isFetchingSingle: false,
      didInvalidateSingle: false,
      singleSeller: action.payload.singleSeller
    };

  case `${UPDATE_SELLER}_REJECTED`:
    return {
      ...state,
      isFetchingSingle: false,
      didInvalidateSingle: true,
      codeError: action.payload.codeError
    };
  case `${UPDATE_SELLER}_PENDING`:
    return {
      ...state,
      isFetchingSingle: true,
      didInvalidateSingle: false,
    };
  case `${UPDATE_SELLER}_FULFILLED`:
    return {
      ...state,
      isFetchingSingle: false,
      didInvalidateSingle: false,
      updateSeller: action.payload.updateSeller
    };

  default:
    return state;
  }
};

export default sellersReducer;
