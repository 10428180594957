import api from '../utils/functions/api/index';
import endpoints from '../utils/functions/api/endpoints';

const {
  get, post, put, deletes
} = api;

export const GET_BINGOS = 'GET_BINGOS';
export const SINGLE_BINGO = 'SINGLE_BINGO';
export const UPDATE_BINGO = 'UPDATE_BINGO';
export const ADD_BINGO = 'ADD_BINGO';

export const getBingos = () => ({
  type: GET_BINGOS,
  payload: get({ url: endpoints.bingosAll })
    .then((res) => ({ bingosAll: res })),
});

export const singleBingo = (id) => ({
  type: SINGLE_BINGO,
  payload: get({ url: endpoints.bingoSingle(id) })
    .then((res) => ({ singleBingo: res }))
});

export const updateBingo = (id, body) => ({
  type: UPDATE_BINGO,
  payload: put({ url: endpoints.editBingo(id), body })
    .then((response) => ({ Bingo: response.data }))
});

export const addBingo = (body) => ({
  type: ADD_BINGO,
  payload: post({ url: endpoints.createBingo, body })
});

export const deleteBingo = async (id) => {
  const fetch = await deletes({ url: endpoints.deleteBingo(id) });
  return fetch;
};