import api from '../utils/functions/api/index';
import endpoints from '../utils/functions/api/endpoints';

const {
  get, post, put, deletes
} = api;

export const ALL_PROMOTIONS = 'ALL_PROMOTIONS';
export const SINGLE_PROMOTION = 'SINGLE_PROMOTION';
export const ADD_PROMOTION = 'ADD_PROMOTION';

export const allPromotions = () => ({
  type: ALL_PROMOTIONS,
  payload: get({ url: endpoints.promotionsAll })
    .then((res) => ({ allPromotions: res }))
});

export const singlePromotion = (id) => ({
  type: SINGLE_PROMOTION,
  payload: get({ url: endpoints.promotionSingle(id) })
    .then((res) => ({ singlePromotion: res }))
});

export const addPromotion = (body) => ({
  type: ADD_PROMOTION,
  payload: post({ url: endpoints.createPromotion, body })
});

export const editPromotion = (id, body) => (
  put({ url: endpoints.editPromotion(id), body })
);

export const deletePromotion = (id) => async (dispatch) => {
  const fetch = deletes({ url: endpoints.deletePromotion(id) });
  const request = get({ url: endpoints.promotionsAll })
    .then((res) => ({ allPromotions: res }));

  request
    .then(() => {
      dispatch({
        type: ALL_PROMOTIONS,
        payload: request,
      });
    });

  return fetch;
};

export const clean = () => ({
  type: SINGLE_PROMOTION,
  payload: async () => ({ singlePromotion: {} })
});
