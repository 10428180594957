import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CircularProgress, Card, IconButton } from '@material-ui/core';
import { Add, Edit, Clear, ExpandLess, ExpandMore } from '@material-ui/icons';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import Loading from "../../commons/loading";

import { useStyles, Container } from "./styles";

import api from "../../../utils/functions/api";

import { uploadImage, getDownloadUrl } from '../../../actions/firebase';

export default () => {

  const classes = useStyles();

  const [ carousel, setCarousel ] = useState(null);
  const [ currentImage, setCurrentImage ] = useState(0);
  const [ saving, setSaving ] = useState(false);

  const [ initialCarousel, setInitialCarousel ] = useState(null);

  const updateImage = useCallback((index) => {
    index != currentImage && setCurrentImage(index);
  }, [currentImage])

  function fetchCarousel() {
    api.get({ url: "/landing/carousel", body: {
      pictures: [ "hola", "estoy", "testeando"]
    }})
      .then(refs => {
        return Promise.all(
          refs.map(ref => (
            getDownloadUrl(ref))
              .then((src) => ({
                src,
                ref,
              }))
          )
        )
      })
      .then((pictures) => {
        setCarousel(pictures);
        setInitialCarousel(pictures);
        setCurrentImage(0);
      })
    // return new Promise((res, rej) => {
    //   setTimeout(() => {
    //     setCarousel([
    //       { src: "https://taxo.es/wp-content/uploads/2020/01/keith-haring-pintura-2.jpg" },
    //       { src: "https://i.pinimg.com/originals/7d/6f/d3/7d6fd3c9257d2e2cda9aa37db1ba080c.jpg" },
    //     ]);
    //   }, 2000);

    // });
  }

  useEffect(() => {
    fetchCarousel();
  }, []);

  useEffect(() => {
    if (carousel && (currentImage+1 > carousel.length)) setCurrentImage(carousel.length-1);
  }, [currentImage, carousel]);

  function selectImage() {
    return new Promise((res, rej) => {

      const fileInput = document.createElement("input");
      fileInput.setAttribute("type", "file");
      fileInput.setAttribute("accept", "image/jpeg");
      
      fileInput.addEventListener("change", () => {
        if (!fileInput.files[0]) {
          rej("No file");
          return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(fileInput.files[0]);

        reader.onloadend = async () => {
          const base64 = reader.result;
          res(base64);
        };
        
      })
      
      fileInput.click();
    });

  }

  function addImage() {
    selectImage()
      .then(b64 => {
        setCarousel(carousel => {
          setCurrentImage(carousel.length);
          return [
            ...carousel,
            { src: b64 }
          ];
        });

      });
  }

  function editImage(i) {
    selectImage()
      .then(b64 => {
        setCarousel(carousel => [
          ...carousel.slice(0, i),
          { src: b64 },
          ...carousel.slice(i+1, carousel.length)
        ]);

      });
  }

  const removeImage = useCallback((i) => {
    setCarousel(carousel => [
      ...carousel.slice(0, i),
      ...carousel.slice(i+1, carousel.length)
    ]);

    if (currentImage == i) setCurrentImage(Math.max(i - 1, 0))

  }, [currentImage]);


  function moveImageUp(i) {

    setCarousel(carousel => [
      ...carousel.slice(0, i-1),
      ...carousel.slice(i, i+1),
      ...carousel.slice(i-1, i),
      ...carousel.slice(i+1, carousel.length)
    ]);

    setCurrentImage(Math.max(i - 1, 0))

  }


  function moveImageDown(i) {

    setCarousel(carousel => {
      setCurrentImage(Math.min(i + 1, carousel.length))
      return [
        ...carousel.slice(0, i),
        ...carousel.slice(i+1, i+2),
        ...carousel.slice(i, i+1),
        ...carousel.slice(i+2, carousel.length)
      ]
    });
  }

  const save = useCallback(() => {
    // await uploadImage(picture.slice(23), 'promotions');
    
    setSaving(true);

    Promise.all(carousel.map((picture) => (
      picture.ref 
        ? picture.ref 
        : uploadImage(picture.src.slice(23), "carousel")
           .then(snap => snap.metadata.fullPath)
    )))
      .then((pictures) => {
        api.put({
          url: "/landing/carousel",
          body: { pictures },
        })
          .then(() => {

            setCarousel(null);
            setSaving(false);
            fetchCarousel();
          });
      })
      .catch(err => {
        console.log(err);
        setSaving(false);
      });

  }, [carousel]);

  //carousel/c4ea4a8f-01f1-415e-8769-fe42bab4eb60.jpg;

  const madeChanges = useMemo(() => {
    return initialCarousel && carousel && (initialCarousel.length !== carousel.length || !initialCarousel.every((pic, i) => pic.ref == carousel[i].ref));
  }, [carousel, initialCarousel])

  if (!carousel || saving) return <Loading/>;
  
  return (
    <Container>
      <h1>Pagina principal</h1>
      <Carousel 
        width={650}
        showThumbs={false}
        showIndicators={false}
        infiniteLoop={true}
        showStatus={false}
        renderArrowNext={() => null}
        renderArrowPrev={() => null}
        selectedItem={currentImage}
        onChange={updateImage}
      >
        {
          carousel.map(({src}, i) => (
            <div key={i}>
              <img height={500} src={src} />
            </div>
          ))
        }
      </Carousel>
      {
        carousel.length 
          ? (
          <div className={classes.carouselControls}>
            <Button className={classes.btn} onClick={() => setCurrentImage(i => i-1)}>Anterior</Button>
            <span className={classes.status}>{currentImage+1} / {carousel.length}</span>
            <Button className={classes.btn} onClick={() => setCurrentImage(i => i+1)}>Siguiente</Button>
          </div>
        )
        : null
      }
      <br/>
      <br/>
      {
        carousel.map(({src}, i) => (
          <Card key={i} className={classes.card} style={{ backgroundColor: i == currentImage ? "#d9d9d9" : "#ffffff"}}>
            <img onClick={() => setCurrentImage(i)} className={classes.cardPicture} src={src} />
            <div className={classes.cardButtons}>
              {
                i ? (
                  <IconButton aria-label="Mover arriba" onClick={() => moveImageUp(i)}>
                    <ExpandLess />
                  </IconButton>
                )
                : null
              }
              {
                i < carousel.length-1 ? (
                  <IconButton aria-label="Mover abajo" onClick={() => moveImageDown(i)}>
                    <ExpandMore />
                  </IconButton>
                )
                : null
              }
              
              <IconButton onClick={() => editImage(i)} aria-label="Editar">
                <Edit />
              </IconButton>
              <IconButton onClick={() => removeImage(i)} aria-label="Eliminar">
                <Clear />
              </IconButton>
            </div>
          </Card>
        ))
      }

      <IconButton className={classes.addBtn} aria-label="Añadir" onClick={addImage}>
        <Add />
      </IconButton>

      <br/>
      {
        madeChanges && carousel.length 
          ? <Button className={classes.btn} onClick={save}>Guardar cambios</Button>
          : null
      }
      <br/>
      <br/>
    </Container>
  )

}