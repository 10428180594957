import React from 'react';

import {
  makeStyles, Card, CardContent, CircularProgress
} from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    minWidth: 900,
    maxWidth: 1300,
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: 16,
    marginBottom: 60,
  },
  card: {
    alignSelf: 'center',
    marginLeft: 16,
    padding: '16px 24px',
    maxWidth: 270,
    textAlign: 'center',
  },
  title: {
    fontWeight: 200,
    margin: 0,
    marginBottom: 6,
  },
  subtitle: {
    fontWeight: 100,
    margin: 0,
  },

});

export function LogContainer({ isFetching, children }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {isFetching ? <CircularProgress /> : children}
    </div>
  );
}

export function LogCard({ title, subtitle }) {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardContent>
        <h1 className={classes.title}>{title}</h1>
        <h3 className={classes.subtitle}>{subtitle}</h3>
      </CardContent>
    </Card>
  );
}
