import firebase from 'firebase/app';
import { v4 } from 'uuid';
import config from '../config/index';

require('firebase/storage');
require('firebase/auth');
global.XMLHttpRequest = require('xhr2');

if (!firebase.apps.length) {
  firebase.initializeApp(config.firebaseConfig);
}

const auth = firebase.auth();
auth.signInAnonymously();

export const uploadImage = (base64, path = '') => new Promise((resolve, reject) => {
  const name = v4();
  const storageRef = firebase.storage();
  storageRef.refFromURL(`gs://canal-coca-cola.appspot.com/${path}/${name}.jpg`);
  const file = storageRef.ref().child(`${path}/${name}.jpg`);

  file
    .putString(base64, 'base64')
    .then((snapshot) => {
      // snapshot.ref.getDownloadURL()
      //   .then((url) => {
      //     console.log('esta es la url', url);
      //   })
      //   .catch((error) => {
      //   // Handle any errors
      //     console.error(error);
      //   });


      return resolve(snapshot);
    })
    .catch((error) => {
      console.error('este es el error', error);
      reject(error);
    });
});

export const uploadImageAndGetUrl = (base64, path = '') => new Promise((resolve, reject) => {
  const name = v4();
  const storageRef = firebase.storage();
  storageRef.refFromURL(`gs://canal-coca-cola.appspot.com/${path}/${name}.jpg`);
  const file = storageRef.ref().child(`${path}/${name}.jpg`);

  file
    .putString(base64, 'base64')
    .then((snapshot) => {
      snapshot.ref.getDownloadURL()
        .then((url) => resolve({ snapshot, url }))
        .catch((error) => console.error(error));
    })
    .catch((error) => {
      console.error('este es el error', error);
      reject(error);
    });
});

export const getDownloadUrl = (fileName) => new Promise((resolve, reject) => {
  const storageRef = firebase.storage().ref();
  const file = storageRef.child(fileName);
  file
    .getDownloadURL()
    .then((url) => resolve(url))
    .catch((error) => reject(error));
});
