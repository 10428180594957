/* eslint-disable use-isnan */
/* eslint-disable radix */
import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import ImageUploader from 'react-images-upload';
import moment from 'moment';

import { ArrowBack } from '@material-ui/icons';
import {
  makeStyles, TextField, Button, Card, CardContent, IconButton, CircularProgress
} from '@material-ui/core';

import Loading from '../../../../commons/loading';
import colors from '../../../../../assets/colors';
import Picker from '../../../promotions/components/categories';

import { uploadImage, getDownloadUrl } from '../../../../../actions/firebase';

const useStyles = makeStyles((theme) => ({
  errorInput: {
    color: 'red',
    fontSize: 12,
    margin: 0,
    paddingLeft: '10px',
    paddingBottom: '10px',
    paddingTop: 0
  },
  input: {
    width: '100vh',
    margin: theme.spacing(1),
  },
  fatherDiv: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '3%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  title: {
    height: '10vh',
    fontSize: 'x-large',
    fontFamily: 'unset'
  },
  button: {
    width: '30vh',
    background: colors.palette.primary,
    fontFamily: 'unset',
    color: '#fafafa',
    '&:hover': {
      background: colors.palette.secondary,
      color: colors.palette.dark
    }
  },
  root: {
    minWidth: 255,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  imgContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    marginTop: 16,
    maxHeight: 200,
  },
  inputsRow: {
    display: 'flex',
    margin: '0px 12px',
    flexDirection: 'row',
    justifyContent: 'space-evenly'
  },
}));

export default ({
  title,
  onSubmit,
  coupon,
  loading,
  isEdit,
  history,
  loadingSubmit
}) => {
  const {
    control, handleSubmit, errors, setValue
  } = useForm();

  const classes = useStyles();

  const [picture, setPicture] = useState(false);
  const [imgFirebase, setImgFirebase] = useState(false);
  const [loadingImg, setLoadingImg] = useState(false);
  const [errorImg, setErrorImg] = useState(false);
  const [uploadedMainPicture, setUploadedMainPicture] = useState(false);

  const [secondaryPicture, setSecondaryPicture] = useState(false);
  const [secondaryImgFirebase, setSecondaryImgFirebase] = useState(false);
  const [loadingSecondaryImg, setLoadingSecondaryImg] = useState(false);
  const [errorSecondaryImg, setErrorSecondaryImg] = useState(false);
  const [uploadedDescriptionPicture, setUploadedDescriptionPicture] = useState(false);

  const [loadingSend, setLoadingSend] = useState(false);
  const [disponible, setDisponible] = useState(true);
  const campos = [
    { name: 'Habilitado', value: true },
    { name: 'Deshabilitado', value: false },
  ];

  React.useEffect(() => {
    if (!picture && coupon && coupon.imgUrl) {
      setImgFirebase(coupon.imgUrl);
      getDownloadUrl(coupon.imgUrl)
        .then((response) => {
          setPicture(response);
          setLoadingImg(false);
        })
        .catch(() => setLoadingImg(false));
    }

    if (!secondaryPicture && coupon && coupon.imgSecondary) {
      setSecondaryImgFirebase(coupon.imgSecondary);
      getDownloadUrl(coupon.imgSecondary)
        .then((response) => {
          setSecondaryPicture(response);
          setLoadingSecondaryImg(false);
        })
        .catch(() => setLoadingSecondaryImg(false));
    }

    if (coupon && isEdit) {
      const dateNow = moment().format('YYYY-MM-DD');
      setValue('name', coupon.name);
      setValue('expirationDate', moment(coupon.expirationDate).add(1, 'days').format('YYYY-MM-DD'));
      if (moment(dateNow).isAfter(moment(coupon.expirationDate).format('YYYY-MM-DD'))) {
        setDisponible(false);
      }
    }
    return function clean() {
      setDisponible(true);
    };
  }, [coupon, picture, secondaryPicture, isEdit, setValue]);

  const handleSubmitForm = async (values) => {
    setLoadingSend(true);
    if (picture && secondaryPicture && imgFirebase && secondaryImgFirebase && !uploadedMainPicture && !uploadedDescriptionPicture) {
      // Ninguna de las imagenes es editada
      const imgGuardadaPrincipal = imgFirebase;
      const imgGuardadaSecundaria = secondaryImgFirebase;

      onSubmit({
        ...values,
        imgUrl: imgGuardadaPrincipal,
        imgSecondary: imgGuardadaSecundaria,
        enabled: disponible,
      });
      setLoadingSend(false);
    } else if (picture && secondaryPicture && uploadedMainPicture && uploadedDescriptionPicture) {
      console.log('entre por acá, ambas editadas');
      // Ambas imagenes se editaron
      const imgGuardadaPrincipal = await uploadImage(picture.slice(23), 'coupons');
      const imgGuardadaSecundaria = await uploadImage(secondaryPicture.slice(23), 'coupons');

      onSubmit({
        ...values,
        imgUrl: imgGuardadaPrincipal.metadata.fullPath,
        imgSecondary: imgGuardadaSecundaria.metadata.fullPath,
        enabled: disponible,
      });
      setLoadingSend(false);
    } else if (picture && secondaryPicture && uploadedMainPicture && !uploadedDescriptionPicture) {
      // Se editó solo la primera imagen (main)
      console.log('entre por acá, solo editando el primero');
      const imgGuardadaPrincipal = await uploadImage(picture.slice(23), 'coupons');

      onSubmit({
        ...values,
        imgUrl: imgGuardadaPrincipal.metadata.fullPath,
        imgSecondary: secondaryImgFirebase,
        enabled: disponible,
      });
      setLoadingSend(false);
    } else if (picture && secondaryPicture && !uploadedMainPicture && uploadedDescriptionPicture) {
      // Se editó solo la segunda imagen (description)
      console.log('entre por acá, solo editando el segundo');
      const imgGuardadaSecundaria = await uploadImage(secondaryPicture.slice(23), 'coupons');
      setLoadingSend(false);
      onSubmit({
        ...values,
        imgUrl: imgFirebase,
        imgSecondary: imgGuardadaSecundaria.metadata.fullPath,
        enabled: disponible,
      });
      setLoadingSend(false);
    } else {
      setErrorImg(true);
      setErrorSecondaryImg(true);
      setLoadingSend(false);
    }
  };

  const onDrop = (file) => {
    if (!file[0]) return;

    setLoadingImg(true);
    setErrorImg(false);

    const reader = new FileReader();
    reader.readAsDataURL(file[0]);
    setUploadedMainPicture(true);

    reader.onloadend = async () => {
      const base64 = reader.result;
      setLoadingImg(false);
      setPicture(base64);
    };
  };

  const onDropSecondary = (file) => {
    if (!file[0]) return;
    setLoadingSecondaryImg(true);
    setErrorSecondaryImg(false);

    const reader = new FileReader();
    reader.readAsDataURL(file[0]);
    setUploadedDescriptionPicture(true);

    reader.onloadend = async () => {
      const base64 = reader.result;
      setLoadingSecondaryImg(false);
      setSecondaryPicture(base64);
    };
  };

  if (loading) return (<Loading />);

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <IconButton
          onClick={() => history.goBack()}
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
        >
          <ArrowBack className={classes.icon} />
        </IconButton>
        <div className={classes.fatherDiv}>
          <div className={classes.title}>{title}</div>

          <form onSubmit={handleSubmit(handleSubmitForm)}>
            <Controller
              className={classes.input}
              as={(
                <TextField
                  placeholder="Nombre"
                  label="Nombre"
                  variant="outlined"
                  error={!!errors?.name?.message}
                />
              )}
              control={control}
              name="name"
              InputLabelProps={{
                shrink: true,
              }}
              rules={{
                required: 'Campo requerido',
              }}
            />
            <p className={classes.errorInput}>{errors?.name?.message}</p>

            <Controller
              className={classes.input}
              as={(
                <TextField
                  variant="outlined"
                  label="Fecha de Vencimiento"
                  type="date"
                  error={!!errors?.expirationDate?.message}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              control={control}
              name="expirationDate"
              rules={{
                required: 'Campo requerido',
              }}
            />
            <p className={classes.errorInput}>{errors?.expirationDate?.message}</p>

            <Picker field={disponible} setField={setDisponible} names={campos} title="Estado" />

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div className={classes.inputsRow}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <p className={classes.imgTitle}>Imagen principal</p>
                  {picture && (
                    <div className={classes.imgContainer}>
                      <img src={picture} alt="imagen" style={{ width: 180, height: 125 }} />
                    </div>
                  )}

                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {loadingImg && (
                      <p style={{
                        margin: 0, marginTop: 32, fontWeight: 'bold', fontSize: 16
                      }}
                      >Cargando imagen...
                      </p>
                    )}
                    {errorImg && (
                      <p style={{
                        margin: 0, marginTop: 32, fontWeight: 'bold', fontSize: 16, color: 'red'
                      }}
                      >Agregar una imagen...
                      </p>
                    )}
                    <ImageUploader
                      withIcon
                      singleImage
                      buttonText="Elegir imagen"
                      onChange={onDrop}
                      imgExtension={['.jpg']}
                      maxFileSize={5242880}
                      label="Max file size: 5mb, accepted: .jpg"
                    />
                  </div>
                </div>
              </div>

              <div className={classes.inputsRow}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <p className={classes.imgTitle}>Imagen Vista Individual</p>
                  {secondaryPicture && (
                    <div className={classes.imgContainer}>
                      <img src={secondaryPicture} alt="imagen" style={{ width: 180, height: 125 }} />
                    </div>
                  )}

                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {loadingSecondaryImg && (
                      <p style={{
                        margin: 0, marginTop: 32, fontWeight: 'bold', fontSize: 16
                      }}
                      >Cargando imagen...
                      </p>
                    )}
                    {errorSecondaryImg && (
                      <p style={{
                        margin: 0, marginTop: 32, fontWeight: 'bold', fontSize: 16, color: 'red'
                      }}
                      >Agregar una imagen...
                      </p>
                    )}
                    <ImageUploader
                      withIcon
                      singleImage
                      buttonText="Elegir imagen"
                      onChange={onDropSecondary}
                      imgExtension={['.jpg']}
                      maxFileSize={5242880}
                      label="Max file size: 5mb, accepted: .jpg"
                    />
                  </div>
                </div>
              </div>
            </div>


            <div className={classes.fatherDiv}>
              <Button
                className={classes.button}
                variant="outlined"
                size="large"
                type="submit"
                style={{ marginTop: 32 }}
                disabled={loadingSubmit || loadingSend}
              >
                { loadingSubmit || loadingSend ? <CircularProgress size={20} color="inherit" /> : 'Enviar' }
              </Button>

            </div>
          </form>
        </div>

      </CardContent>
    </Card>

  );


};
