import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { ArrowBack } from '@material-ui/icons';
import {
  Card, CardContent, Typography, IconButton, CircularProgress, CardActions, makeStyles
} from '@material-ui/core';


import { getDownloadUrl } from '../../../actions/firebase';
import {
  singleNotification
} from '../../../actions/notificationActions';

import Loading from '../../commons/loading';
import ButtonCustom from '../../commons/button';
import img_error from '../../../assets/images/err/error_image.jpg';

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  children: {
    width: '100%'
  },
  infoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  containerData: {
    display: 'flex',
    margin: '0 auto',
    width: '80%',
    marginTop: 24,
    justifyContent: 'space-evenly'
  },
  containerImg: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-around',
    marginBottom: 20,
    minHeight: 242,
    marginTop: 12,
  },
  root: {
    minWidth: 900,
    maxWidth: 1300,
    margin: '0 auto',
  },
  title: {
    fontSize: 24,
    textAlign: 'center'
  },
  principal: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  img: {
    display: 'block',
    minWidth: '35vh',
    maxWidth: '50vh',
    minHeight: 242,
    maxHeight: 400,
    margin: '0 12px',
  },
  menuButton: {
    marginLeft: 12
  },
  description: {
    color: 'hsla(0, 0%, 0%, 0.54)',
    fontSize: 16,
    textTransform: 'capitalize'
  },
  info: {
    margin: 12,
    fontSize: 18
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'center'
  }
});

export default function ({ match, history }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const notifications = useSelector(((state) => state.notificationsReducer));

  const [image, setImage] = useState(null);
  const [loadingImage, setLoadingImage] = useState(true);

  const { id } = match.params;

  useEffect(() => {
    dispatch(singleNotification(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (notifications.single.image) {
      getDownloadUrl(notifications.single.image)
        .then((img) => {
          setImage(img);
          setLoadingImage(false);
        })
        .catch(() => setLoadingImage(false));
    }

    return function cleanUp() {
      setImage(null);
    };
  }, [notifications]);

  const editNotifications = () => history.push(`/private/editNotification/${id}`);
  if (notifications.isFetchingSingle) return <Loading />;

  return (
    <div className={classes.container}>
      <Card className={classes.root}>
        <CardContent>
          <IconButton
            onClick={() => history.goBack()}
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <ArrowBack />
          </IconButton>
          <Typography className={classes.title} color="textSecondary" gutterBottom>Detalle de Notificación</Typography>
          <div className={classes.infoContainer}>

            <div className={classes.containerImg}>
              {loadingImage ? <CircularProgress /> : <img className={classes.img} src={!image && !loadingImage ? img_error : image} alt="img" />}
            </div>

            <div className={classes.containerData}>
              <div className={classes.children}>
                <div className={classes.info}>
                  Título: &nbsp;
                  <span className={classes.description}>{notifications.single.title}</span>
                </div>
              </div>

              <div className={classes.children}>
                <div className={classes.info}>
                  Fecha de publicación: &nbsp;
                  <span className={classes.description}>{moment(notifications.single.date).format('DD/MM/YYYY')}</span>
                </div>
              </div>

            </div>
          </div>
        </CardContent>
        <CardActions className={classes.btnContainer}>
          <ButtonCustom edit text="Editar" color="default" action={editNotifications} />
        </CardActions>
      </Card>
    </div>
  );
}
