import api from '../utils/functions/api/index';
import endpoints from '../utils/functions/api/endpoints';

export const GET_NOTICES = 'GET_NOTICES';
export const SINGLE_NOTICE = 'SINGLE_NOTICE';
export const UPDATE_NOTICE = 'UPDATE_NOTICE';
export const ADD_NOTICE = 'ADD_NOTICE';

const {
  get, post, put, deletes
} = api;

export const getAllNotices = () => ({
  type: GET_NOTICES,
  payload: (get({ url: endpoints.noticeAll })
    .then((response) => ({ allNotices: response }))),
});

export const singleNotice = (id) => ({
  type: SINGLE_NOTICE,
  payload: (get({ url: endpoints.singleNew(id) })
    .then((res) => ({ singleNotice: res })))
});

export const addNotice = (body) => ({
  type: ADD_NOTICE,
  payload: post({ url: endpoints.createNew, body })
});

export const updateNotice = (id, body) => (
  put({ url: endpoints.editNew(id), body })
);

export const deleteNotice = (id) => async (dispatch) => {
  const fetch = await deletes({ url: endpoints.deleteNew(id) });
  const request = get({ url: endpoints.noticeAll })
    .then((res) => ({ allNotices: res }));

  request
    .then(() => {
      dispatch({
        type: GET_NOTICES,
        payload: request,
      });
    });

  return fetch;
};
