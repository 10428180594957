import React, { useState, useEffect } from 'react';

import {
  TextField, Button, Card, CardContent, IconButton, CircularProgress
} from '@material-ui/core';

import { ArrowBack } from '@material-ui/icons';
import { useForm, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { uploadImage, getDownloadUrl } from '../../../../../actions/firebase';

import Loading from '../../../../commons/loading';
import useToast from '../../../../commons/toast';
import colors from '../../../../../assets/colors';
import Select from '../../../puntosVenta/components/selects/select';
import ImageUploader from 'react-images-upload';
import AddCategories from '../../../../commons/excel';
import UploadExcel from '../../../../commons/excelBingo';

const useStyles = makeStyles((theme) => ({
  errorInput: {
    color: 'red',
    fontSize: 12,
    margin: 0,
    paddingLeft: '10px',
    paddingBottom: '10px',
    paddingTop: 0
  },
  input: {
    width: '100vh',
    margin: theme.spacing(1),
  },
  fatherDiv: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '3%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  title: {
    height: '10vh',
    fontSize: 'x-large',
    fontFamily: 'unset'
  },
  button: {
    width: '30vh',
    background: colors.palette.primary,
    fontFamily: 'unset',
    color: '#fafafa',
    '&:hover': {
      background: colors.palette.secondary,
      color: colors.palette.dark
    }
  },
  root: {
    minWidth: 255,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },

  missionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center' 
  },
  
  missionQuadrant: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
}));

// name, quadrants, clients, dateExpiry

export default ({
  title,
  onSubmit,
  bingo,
  loading,
  history,
  getExcel
}) => {
  const { Toast, call } = useToast();
  const classes = useStyles();
  const [clientsBliveError, setClientsBliveError] = useState(false);
  const [clientsBlive, setClientsBlive] = useState([]);
  const [picture, setPicture] = useState(false);
  const [pictureTwo, setPictureTwo] = useState(false)
  const [pictureThree, setPictureThree] = useState(false)
  const [pictureFour, setPictureFour] = useState(false)
  const [pictureFive, setPictureFive] = useState(false)
  const [pictureSix, setPictureSix] = useState(false)
  const [pictureSeven, setPictureSeven] = useState(false)
  const [pictureEight, setPictureEight] = useState(false)
  const [pictureNine, setPictureNine] = useState(false)
  const [pictureTen, setPictureTen] = useState(false)
  const [pictureLineOne, setPictureLineOne] = useState(false)
  const [pictureLineTwo, setPictureLineTwo] = useState(false)
  const [imgFirebase, setImgFirebase] = useState({mission_1: '', mission_2: '', mission_3: '', mission_4: '', mission_5: '', mission_6:'', mission_7:'', mission_8: '', mission_9:'', mission_10:''})

  
  // const [loadingImg, setLoadingImg] = useState(false);
  const [errorImg, setErrorImg] = useState(false);
  const [uploadedMainPicture, setUploadedMainPicture] = useState(false);

  const [loadingUploadImg, setLoadingUploadImg] = useState(false);

  const [loadingImg, setLoadingImg] = useState({
    imgDrop: null,
    imgDropTwo: null,
    imgDropThree: null,
    imgDropFour: null,
    imgDropFive: null,
    imgDropSix: null,
    imgDropSeven: null,
    imgDropEight: null,
    imgDropNine: null,
    imgDropTen: null,
    imgDropLineOne: null,
    imgDropLineTwo: null,
  })

  const {
    control, handleSubmit, errors,
  } = useForm();

  if (loading) {
    return (<Loading />);
  }

  const handleSubmitForm = async (data) => {
    // ev.preventDefault();
    setLoadingUploadImg(true)
    let errorValues = {
      imgDrop: 'mision 1',
      imgDropTwo: 'mision 2',
      imgDropThree: 'mision 3',
      imgDropFour: 'mision 4',
      imgDropFive: 'mision 5',
      imgDropSix: 'mision 6',
      imgDropSeven: 'mision 7',
      imgDropEight: 'mision 8',
      imgDropNine: 'mision 9',
      imgDropTen: 'mision 10',
      imgDropLineOne: 'Linea 1',
      imgDropLineTwo: 'Linea 2',
    }
    let withoutImg = []
    Object.keys(loadingImg).forEach((keyImg) => {
      if (loadingImg[keyImg] === null) {
        withoutImg.push(keyImg)
        // -return call('Faltan Cargar Imagenes', 'error')
      }
    })
    if (withoutImg.length > 0) {
      let errorStr = ''
      withoutImg.forEach((key) => {
        errorStr += errorValues[key] + " "
      })
      call(`Faltan cargar imagenes de ${errorStr}`, 'error')
      setLoadingUploadImg(false)
    } else {
      if (!clientsBliveError && picture && pictureTwo && pictureThree && pictureFour && pictureFive && pictureSix && pictureSeven && pictureEight && pictureNine && pictureTen && pictureLineOne && pictureLineTwo){
        const imgOne = await uploadImage(picture.slice(23), 'bingos')
        const imgTwo = await uploadImage(pictureTwo.slice(23), 'bingos')
        const imgThree = await uploadImage(pictureThree.slice(23), 'bingos')
        const imgFour = await uploadImage(pictureFour.slice(23), 'bingos')
        const imgFive = await uploadImage(pictureFive.slice(23), 'bingos')
        const imgSix = await uploadImage(pictureSix.slice(23), 'bingos')
        const imgSeven = await uploadImage(pictureSeven.slice(23), 'bingos')
        const imgEight = await uploadImage(pictureEight.slice(23), 'bingos')
        const imgNine = await uploadImage(pictureNine.slice(23), 'bingos')
        const imgTen = await uploadImage(pictureTen.slice(23), 'bingos')
        const lineOne = await uploadImage(pictureLineOne.slice(23), 'bingos')
        const lineTwo = await uploadImage(pictureLineTwo.slice(23), 'bingos')
        
        setImgFirebase({
          mission_1: imgOne.metadata.fullPath,
          mission_2: imgTwo.metadata.fullPath,
          mission_3: imgThree.metadata.fullPath,
          mission_4: imgFour.metadata.fullPath,
          mission_5: imgFive.metadata.fullPath,
          mission_6: imgSix.metadata.fullPath,
          mission_7: imgSeven.metadata.fullPath,
          mission_8: imgEight.metadata.fullPath,
          mission_9: imgNine.metadata.fullPath,
          mission_10: imgTen.metadata.fullPath,
        })
      
        onSubmit({
          ...data,
          mission_1: imgOne.metadata.fullPath,
          mission_2: imgTwo.metadata.fullPath,
          mission_3: imgThree.metadata.fullPath,
          mission_4: imgFour.metadata.fullPath,
          mission_5: imgFive.metadata.fullPath,
          mission_6: imgSix.metadata.fullPath,
          mission_7: imgSeven.metadata.fullPath,
          mission_8: imgEight.metadata.fullPath,
          mission_9: imgNine.metadata.fullPath,
          mission_10: imgTen.metadata.fullPath,
          clients: clientsBlive,
          line_1: lineOne.metadata.fullPath,
          line_2: lineTwo.metadata.fullPath
        
        });
        //Solo dejará de cargar cuando termine de guardar todo en la DB
        // setLoadingUploadImg(false)
      } 
      // else {
      //   setLoadingUploadImg(false)
      // }
    }

  }
 

  const onDrop = (file) => {
    if (!file[0]) return;
    setLoadingImg({ 
      ...loadingImg, 
      imgDrop : true 
    });
    setErrorImg(false);

    const reader = new FileReader();
    reader.readAsDataURL(file[0]);
    setUploadedMainPicture(true);

    reader.onloadend = async () => {
    const base64 = reader.result;
      setLoadingImg({
        ...loadingImg,
        imgDrop: false
      });
    setPicture(base64);
    };
  };

  const onDropTwo = (file) => {
    if (!file[0]) return;
    setLoadingImg({ 
      ...loadingImg, 
      imgDropTwo : true 
    });
    setErrorImg(false);

    const reader = new FileReader();
    reader.readAsDataURL(file[0]);
    setUploadedMainPicture(true);

    reader.onloadend = async () => {
    const base64 = reader.result;
    setLoadingImg({ 
      ...loadingImg, 
      imgDropTwo : false 
    });
    setPictureTwo(base64);
    };
  };

  const onDropThree = (file) => {
    if (!file[0]) return;
    setLoadingImg({ 
      ...loadingImg, 
      imgDropThree : true 
    })
    setErrorImg(false);

    const reader = new FileReader();
    reader.readAsDataURL(file[0]);
    setUploadedMainPicture(true);

    reader.onloadend = async () => {
    const base64 = reader.result;
    setLoadingImg({ 
      ...loadingImg, 
      imgDropThree : false 
    })
    setPictureThree(base64);
    };
  };

  const onDropFour = (file) => {
    if (!file[0]) return;
    setLoadingImg({ 
      ...loadingImg, 
      imgDropFour :true 
    })
    setErrorImg(false);

    const reader = new FileReader();
    reader.readAsDataURL(file[0]);
    setUploadedMainPicture(true);

    reader.onloadend = async () => {
    const base64 = reader.result;
    setLoadingImg({ 
      ...loadingImg, 
      imgDropFour :false 
    })
    setPictureFour(base64);
    };
  };

  const onDropFive = (file) => {
    if (!file[0]) return;
    setLoadingImg({ 
      ...loadingImg, 
      imgDropFive :true 
    })
    setErrorImg(false);

    const reader = new FileReader();
    reader.readAsDataURL(file[0]);
    setUploadedMainPicture(true);

    reader.onloadend = async () => {
    const base64 = reader.result;
    setLoadingImg({ 
      ...loadingImg, 
      imgDropFive :false 
    })
    setPictureFive(base64);
    };
  };

  const onDropSix = (file) => {
    if (!file[0]) return;
    setLoadingImg({ 
      ...loadingImg, 
      imgDropSix:true
    })
    setErrorImg(false);

    const reader = new FileReader();
    reader.readAsDataURL(file[0]);
    setUploadedMainPicture(true);

    reader.onloadend = async () => {
    const base64 = reader.result;
    setLoadingImg({ 
      ...loadingImg, 
      imgDropSix:false
    })
    setPictureSix(base64);
    };
  };

  const onDropSeven = (file) => {
    if (!file[0]) return;
    setLoadingImg({ 
      ...loadingImg, 
      imgDropSeven:true 
    })
    setErrorImg(false);

    const reader = new FileReader();
    reader.readAsDataURL(file[0]);
    setUploadedMainPicture(true);

    reader.onloadend = async () => {
    const base64 = reader.result;
    setLoadingImg({ 
      ...loadingImg, 
      imgDropSeven:false 
    })
    setPictureSeven(base64);
    };
  };

  const onDropEight = (file) => {
    if (!file[0]) return;
    setLoadingImg({ 
      ...loadingImg, 
      imgDropEight:true 
    })
    setErrorImg(false);

    const reader = new FileReader();
    reader.readAsDataURL(file[0]);
    setUploadedMainPicture(true);

    reader.onloadend = async () => {
    const base64 = reader.result;
    setLoadingImg({ 
      ...loadingImg, 
      imgDropEight:false 
    })
    setPictureEight(base64);
    };
  };

  const onDropNine = (file) => {
    if (!file[0]) return;
    setLoadingImg({ 
      ...loadingImg, 
      imgDropNine:true 
    })
    setErrorImg(false);

    const reader = new FileReader();
    reader.readAsDataURL(file[0]);
    setUploadedMainPicture(true);

    reader.onloadend = async () => {
    const base64 = reader.result;
    setLoadingImg({ 
      ...loadingImg, 
      imgDropNine:false 
    })
    setPictureNine(base64);
    };
  };

  const onDropTen = (file) => {
    if (!file[0]) return;
    setLoadingImg({ 
      ...loadingImg, 
      imgDropTen:true 
    })
    setErrorImg(false);

    const reader = new FileReader();
    reader.readAsDataURL(file[0]);
    setUploadedMainPicture(true);

    reader.onloadend = async () => {
    const base64 = reader.result;
    setLoadingImg({ 
      ...loadingImg, 
      imgDropTen:false 
    })
    setPictureTen(base64);
    };
  };

  const onDropLineOne = (file) => {
    if (!file[0]) return;
    setLoadingImg({ 
      ...loadingImg, 
      imgDropLineOne:true 
    })
    setErrorImg(false);

    const reader = new FileReader();
    reader.readAsDataURL(file[0]);
    setUploadedMainPicture(true);

    reader.onloadend = async () => {
    const base64 = reader.result;
    setLoadingImg({ 
      ...loadingImg, 
      imgDropLineOne:false 
    })
    setPictureLineOne(base64);
    };
  };

  const onDropLineTwo = (file) => {
    if (!file[0]) return;
    setLoadingImg ({ 
      ...loadingImg, 
      imgDropLineTwo:true 
    })
    setErrorImg(false);

    const reader = new FileReader();
    reader.readAsDataURL(file[0]);
    setUploadedMainPicture(true);

    reader.onloadend = async () => {
    const base64 = reader.result;
    setLoadingImg({ 
      ...loadingImg, 
      imgDropLineTwo:false 
    })
    setPictureLineTwo(base64);
    };
  };

  return (
    <>
    <Toast />
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <IconButton
          onClick={() => history.goBack()}
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
        >
          <ArrowBack className={classes.icon} />
        </IconButton>
        <div className={classes.fatherDiv}>
          <div className={classes.title}>{title}</div>
            {/* <form onSubmit={(ev) => validateUpload(ev)}> */}
            <form onSubmit={handleSubmit(handleSubmitForm)}>
            <Controller
              className={classes.input}
              as={(
                <TextField
                  placeholder="Nombre"
                  label="Nombre del bingo"
                  variant="outlined"
                  error={!!errors?.name?.message}
                />
              )}
              control={control}
              name="name"
              rules={{
                required: 'Campo requerido',
              }}
            />
            <p className={classes.errorInput}>{errors?.name?.message}</p>

            <Controller
              className={classes.input}
              as={(
                <TextField
                  variant="outlined"
                  label="Fecha de Vencimiento"
                  type="date"
                  error={!!errors?.expirationDateBingo?.message}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              control={control}
              name="dateExpiry"
              rules={{
                required: 'Ingrese una fecha válida',
              }}
            />
            <p className={classes.errorInput}>{errors?.passwordConfirm?.message}</p>
            
            <div className={classes.inputsRow}>

              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <p className={classes.imgTitle}>Clientes Blive</p>
                {clientsBliveError && (<p className={classes.clentsBliveError}>{clientsBliveError}</p>)}

                {/* <AddCategories initialCodes={clientsBlive} onChange={setClientsBlive} setError={setClientsBliveError} getDataExcel={getExcel} /> */}
                <UploadExcel initialClients={clientsBlive} onChange={setClientsBlive} setError={setClientsBliveError} getDataExcel={getExcel} />

                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      {picture && (
                        <div className={classes.imgContainer}>
                          <img src={picture} alt="imagen" style={{ width: 180, height: 125 }} />
                        </div>
                      )}

                      <ImageUploader
                          withIcon
                          singleImage
                          buttonText="Elegir Mision 1"
                          onChange={onDrop}
                          imgExtension={['.jpg']}
                          maxFileSize={5242880}
                          fileTypeError="debe ser .jpg"
                          label="Max file size: 5mb, accepted: .jpg"
                        />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      {pictureTwo && (
                        <div className={classes.imgContainer}>
                          <img src={pictureTwo} alt="imagen" style={{ width: 180, height: 125 }} />
                        </div>
                      )}

                      <ImageUploader
                          withIcon
                          singleImage
                          buttonText="Elegir Mision 2"
                          onChange={onDropTwo}
                          imgExtension={['.jpg']}
                          maxFileSize={5242880}
                          fileTypeError="debe ser .jpg"
                          label="Max file size: 5mb, accepted: .jpg"
                        />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      {pictureThree && (
                        <div className={classes.imgContainer}>
                          <img src={pictureThree} alt="imagen" style={{ width: 180, height: 125 }} />
                        </div>
                      )}

                      <ImageUploader
                          withIcon
                          singleImage
                          buttonText="Elegir Mision 3"
                          onChange={onDropThree}
                          imgExtension={['.jpg']}
                          maxFileSize={5242880}
                          fileTypeError="debe ser .jpg"
                          label="Max file size: 5mb, accepted: .jpg"
                        />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      {pictureFour && (
                        <div className={classes.imgContainer}>
                          <img src={pictureFour} alt="imagen" style={{ width: 180, height: 125 }} />
                        </div>
                      )}

                      <ImageUploader
                          withIcon
                          singleImage
                          buttonText="Elegir Mision 4"
                          onChange={onDropFour}
                          imgExtension={['.jpg']}
                          maxFileSize={5242880}
                          fileTypeError="debe ser .jpg"
                          label="Max file size: 5mb, accepted: .jpg"
                        />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      {pictureFive && (
                        <div className={classes.imgContainer}>
                          <img src={pictureFive} alt="imagen" style={{ width: 180, height: 125 }} />
                        </div>
                      )}

                      <ImageUploader
                          withIcon
                          singleImage
                          buttonText="Elegir Mision 5"
                          onChange={onDropFive}
                          imgExtension={['.jpg']}
                          maxFileSize={5242880}
                          fileTypeError="debe ser .jpg"
                          label="Max file size: 5mb, accepted: .jpg"
                        />
                    </div>
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      {pictureSix && (
                        <div className={classes.imgContainer}>
                          <img src={pictureSix} alt="imagen" style={{ width: 180, height: 125 }} />
                        </div>
                      )}

                      <ImageUploader
                          withIcon
                          singleImage
                          buttonText="Elegir Mision 6"
                          onChange={onDropSix}
                          imgExtension={['.jpg']}
                          maxFileSize={5242880}
                          fileTypeError="debe ser .jpg"
                          label="Max file size: 5mb, accepted: .jpg"
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      {pictureSeven && (
                        <div className={classes.imgContainer}>
                          <img src={pictureSeven} alt="imagen" style={{ width: 180, height: 125 }} />
                        </div>
                      )}

                      <ImageUploader
                          withIcon
                          singleImage
                          buttonText="Elegir Mision 7"
                          onChange={onDropSeven}
                          imgExtension={['.jpg']}
                          maxFileSize={5242880}
                          fileTypeError="debe ser .jpg"
                          label="Max file size: 5mb, accepted: .jpg"
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      {pictureEight && (
                        <div className={classes.imgContainer}>
                          <img src={pictureEight} alt="imagen" style={{ width: 180, height: 125 }} />
                        </div>
                      )}

                      <ImageUploader
                          withIcon
                          singleImage
                          buttonText="Elegir Mision 8"
                          onChange={onDropEight}
                          imgExtension={['.jpg']}
                          maxFileSize={5242880}
                          fileTypeError="debe ser .jpg"
                          label="Max file size: 5mb, accepted: .jpg"
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      {pictureNine && (
                        <div className={classes.imgContainer}>
                          <img src={pictureNine} alt="imagen" style={{ width: 180, height: 125 }} />
                        </div>
                      )}

                      <ImageUploader
                          withIcon
                          singleImage
                          buttonText="Elegir Mision 9"
                          onChange={onDropNine}
                          imgExtension={['.jpg']}
                          maxFileSize={5242880}
                          fileTypeError="debe ser .jpg"
                          label="Max file size: 5mb, accepted: .jpg"
                        />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      {pictureTen && (
                        <div className={classes.imgContainer}>
                          <img src={pictureTen} alt="imagen" style={{ width: 180, height: 125 }} />
                        </div>
                      )}

                      <ImageUploader
                          withIcon
                          singleImage
                          buttonText="Elegir Mision 10"
                          onChange={onDropTen}
                          imgExtension={['.jpg']}
                          maxFileSize={5242880}
                          fileTypeError="debe ser .jpg"
                          label="Max file size: 5mb, accepted: .jpg"
                        />
                    </div>
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      {pictureLineOne && (
                        <div className={classes.imgContainer}>
                          <img src={pictureLineOne} alt="imagen" style={{ width: 180, height: 125 }} />
                        </div>
                      )}

                      <ImageUploader
                          withIcon
                          singleImage
                          buttonText="Elegir Linea 1"
                          onChange={onDropLineOne}
                          imgExtension={['.jpg']}
                          maxFileSize={5242880}
                          fileTypeError="debe ser .jpg"
                          label="Max file size: 5mb, accepted: .jpg"
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      {pictureLineTwo && (
                        <div className={classes.imgContainer}>
                          <img src={pictureLineTwo} alt="imagen" style={{ width: 180, height: 125 }} />
                        </div>
                      )}

                      <ImageUploader
                          withIcon
                          singleImage
                          buttonText="Elegir Linea 2"
                          onChange={onDropLineTwo}
                          imgExtension={['.jpg']}
                          maxFileSize={5242880}
                          fileTypeError="debe ser .jpg"
                          label="Max file size: 5mb, accepted: .jpg"
                        />
                    </div>
                  </div>

                </div>
              </div>


            <div className={classes.fatherDiv}>
              <Button
                className={classes.button}
                variant="outlined"
                size="large"
                type="submit"
                style={{ marginTop: 32 }}
                disabled={loadingUploadImg}
              >
                  {loadingUploadImg ? <CircularProgress size={20} color="inherit" /> : 'Guardar' }
              </Button>
            </div>
          </form>
        </div>

      </CardContent>
    </Card>
  </>
  );
};
