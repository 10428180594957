import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../assets/colors';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center'
  },
  load: {
    color: colors.palette.white
  }
});

export default function () {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <CircularProgress className={classes.load} size={24} />
    </div>
  );
}
