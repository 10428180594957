import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { ArrowBack } from '@material-ui/icons';
import {
  Card, CardContent, Typography, IconButton, CircularProgress, CardActions, makeStyles
} from '@material-ui/core';


import {
  couponSingle, deleteCoupon, clean, couponsAll
} from '../../../actions/couponsActions';
import { getDownloadUrl } from '../../../actions/firebase';

import Loading from '../../commons/loading';
import ButtonCustom from '../../commons/button';
import ButtonDialog from '../../commons/button/dialog';
import img_error from '../../../assets/images/err/error_image.jpg';

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  infoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  containerData: {
    display: 'flex',
    margin: '0 auto',
    width: '80%',
    marginTop: 24,
    justifyContent: 'space-evenly'
  },
  containerImg: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
    marginTop: 18,
  },
  root: {
    minWidth: 900,
    maxWidth: 1300,
    margin: '0 auto',
  },
  title: {
    fontSize: 24,
    textAlign: 'center'
  },
  principal: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  img: {
    display: 'block',
    width: '35vh',
    height: 242,
    margin: '0 12px'
  },
  menuButton: {
    marginLeft: 12
  },
  description: {
    color: 'hsla(0, 0%, 0%, 0.54)',
    fontSize: 16,
    textTransform: 'capitalize'
  },
  info: {
    margin: 12,
    fontSize: 18
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'center'
  }
});

export default function ({ match, history }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const coupon = useSelector(((state) => state.couponsReducer));
  const cuponId = match.params.id;

  const [image, setImage] = useState(null);
  const [loadingImage, setLoadingImage] = useState(true);

  const [secondaryImage, setSecondaryImage] = useState(null);
  const [loadingSecondaryImage, setLoadingSecondaryImage] = useState(true);

  useEffect(() => {
    dispatch(couponSingle(cuponId));
    return function cleanUp() {
      dispatch(clean());
    };
  }, [dispatch, cuponId]);

  useEffect(() => {
    if (coupon.singleCoupon.imgUrl) {
      getDownloadUrl(coupon.singleCoupon.imgUrl)
        .then((img) => {
          setImage(img);
          setLoadingImage(false);
        })
        .catch(() => setLoadingImage(false));
    } else {
      setLoadingImage(false);
    }

    if (coupon.singleCoupon.imgSecondary) {
      getDownloadUrl(coupon.singleCoupon.imgSecondary)
        .then((img) => {
          setSecondaryImage(img);
          setLoadingSecondaryImage(false);
        })
        .catch(() => setLoadingSecondaryImage(false));
    } else {
      setLoadingSecondaryImage(false);
    }
  }, [coupon]);

  const handleDeleteCoupon = async () => {
    await deleteCoupon(cuponId);
    await dispatch(couponsAll());
    history.push('/private/coupons');
  };

  const editCoupon = () => history.push(`/private/editCoupon/${cuponId}`);
  if (coupon.isFetchingSingle) return <Loading />;

  return (
    <div className={classes.container}>
      <Card className={classes.root}>
        <CardContent>
          <IconButton
            onClick={() => history.goBack()}
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <ArrowBack />
          </IconButton>
          <Typography className={classes.title} color="textSecondary" gutterBottom>Detalle del cupon</Typography>
          <div className={classes.infoContainer}>

            <div className={classes.containerImg}>
              {loadingImage ? <CircularProgress /> : <img className={classes.img} src={!image && !loadingImage ? img_error : image} alt="img" />}
              {loadingSecondaryImage ? <CircularProgress /> : <img className={classes.img} src={!secondaryImage && !loadingSecondaryImage ? img_error : secondaryImage} alt="img" />}
            </div>

            <div className={classes.containerData}>
              <div>
                <div className={classes.info}>
                  Nombre: &nbsp;
                  <span className={classes.description}>{coupon.singleCoupon.name}</span>
                </div>
                <div className={classes.info}>
                  Disponibilidad: &nbsp;
                  <span className={classes.description}>{coupon.singleCoupon.disponible ? 'Se encuentra activo' : 'Se encuentra desabilitado/vencido. '}</span>
                </div>
              </div>

              <div>
                <div className={classes.info}>
                  Fecha de Vencimiento: &nbsp;
                  <span className={classes.description}>{moment(coupon.singleCoupon.expirationDate).format('DD/MM/YYYY')}</span>
                </div>
                <div className={classes.info}>
                  Fecha de creación: &nbsp;
                  <span className={classes.description}>{moment(coupon.singleCoupon.creationDate).format('DD/MM/YYYY')}</span>
                </div>
              </div>

            </div>
          </div>
        </CardContent>
        <CardActions className={classes.btnContainer}>
          <ButtonCustom text="Editar" color="default" edit action={editCoupon} />
          <ButtonDialog
            action={handleDeleteCoupon}
            active={coupon.singleCoupon && (coupon.singleCoupon.disponible)}
            title={coupon.singleCoupon.disponible ? 'Deshabilitar Cupón?' : 'Habilitar Cupón'}
            description="Tenga en cuenta que una vez borrado, no habrá vuelta atrás!"
          />
        </CardActions>
      </Card>
    </div>
  );


}
