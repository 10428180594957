import {
  ALL_PUNTOS_VENTA,
  ONE_PUNTO_VENTA,
} from "../actions/puntosVentaActions";

const INITIAL_STATE = {
  isFetching: false,
  didInvalidate: false,
  puntosDeVenta: [],
  totalPages: 0,
  currentPage: 1,
  singlePuntoVenta: {},
  isFetchingSingle: false,
  didInvalidateSingle: false,
  error: null,
};

const providers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${ALL_PUNTOS_VENTA}_REJECTED`:
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        error: action.payload.data,
      };
    case `${ALL_PUNTOS_VENTA}_PENDING`:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    case `${ALL_PUNTOS_VENTA}_FULFILLED`:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        puntosDeVenta: action.payload.puntosDeVenta,
        totalPages: action.payload.totalPages,
        currentPage: action.payload.currentPage,
      };

    case `${ONE_PUNTO_VENTA}_REJECTED`:
      return {
        ...state,
        isFetchingSingle: false,
        didInvalidateSingle: true,
      };
    case `${ONE_PUNTO_VENTA}_PENDING`:
      return {
        ...state,
        isFetchingSingle: true,
        didInvalidateSingle: false,
      };
    case `${ONE_PUNTO_VENTA}_FULFILLED`:
      return {
        ...state,
        isFetchingSingle: false,
        didInvalidateSingle: false,
        singlePuntoVenta: action.payload.puntoVenta,
      };

    default:
      return state;
  }
};

export default providers;
