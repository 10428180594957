import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';

const Alert = ({ message, open, setAlert }) => {
    const handleClose = () => {
        setAlert({ open: false, message: '' });
    };

    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
                message={message}
            />
        </div>
    );
}

export default Alert