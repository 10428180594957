import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addBingo } from '../../../actions/bingoActions';

import Form from './components/forms/index';
import useToast from '../../commons/toast';

export default function ({ history }) {
  const [dataExcel, setDataExcel] = useState(null);
  const dispatch = useDispatch();
  const { Toast, call } = useToast();

  const headerTable = ["CREADOS", "ERRORES ENCONTRADOS"];

  const downloadCSVData = (dataTable) => {
    // MOCK dataTableMOCK
    let result = headerTable + "\n";
    if (dataTable.found.length > dataTable.notFound.length) {
      dataTable.found.forEach((id, i) => {
        if (dataTable.notFound[i]) {
          result += id + " , " + dataTable.notFound[i] + "\n";
        } else {
          result += id + " , " + " - " + "\n";
        }
      });
    } else {
      dataTable.notFound.forEach((id, i) => {
        if (dataTable.found[i]) {
          result += dataTable.found[i] + ", " + id + "\n";
        } else {
          result += " - " + " , " + id + "\n";
        }
      });
    }
    let element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(result)
    );
    //MOCK
    element.setAttribute("download", `${dataTable.bingo.name}mock.csv`);
    // DEV
    // element.setAttribute("download", `${dataTable.bingo.name}.csv`);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };
  
  const onSubmit = (values) => {
    values.clients = dataExcel;
    dispatch(addBingo(values))
      .then((data) => {
        console.log("addBingo.then()", data.value);
        //DEV
        downloadCSVData(data.value);
        history.push("/private/bingo");
      })
      .catch((err) => {
        call(err.data, "warning");
      });
  };

  return (
    <>
      <Form
        history={history}
        onSubmit={onSubmit}
        title="Creando bingo:"
          getExcel={setDataExcel}
      />

      <Toast />
    </>

  );
}
