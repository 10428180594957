import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import ImageUploader from 'react-images-upload';

import { ArrowBack } from '@material-ui/icons';
import {
  TextField,
  Button,
  Card,
  CardContent,
  IconButton,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';

import { uploadImage, getDownloadUrl } from '../../../../../actions/firebase';

import UploadExcel from '../../../../commons/excel';
import Loading from '../../../../commons/loading';
import Picker from '../../../promotions/components/categories';
import colors from '../../../../../assets/colors';

const useStyles = makeStyles((theme) => ({
  errorInput: {
    color: 'red',
    fontSize: 12,
    margin: 0,
    paddingLeft: '10px',
    paddingBottom: '10px',
    paddingTop: 0
  },
  input: {
    width: '100vh',
    margin: theme.spacing(1),
  },
  select: {
    width: '100vh',
  },
  fatherDiv: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '3%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  title: {
    fontSize: 'x-large',
    fontFamily: 'unset'
  },
  title2: {
    fontFamily: 'unset',
    textAlign: 'center',
    fontSize: 18
  },
  button: {
    width: '30vh',
    background: colors.palette.primary,
    fontFamily: 'unset',
    color: '#fafafa',
    '&:hover': {
      background: colors.palette.secondary,
      color: colors.palette.dark
    }
  },
  root: {
    minWidth: 255,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  imgContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    marginTop: 16,
    maxHeight: 200,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  categories: {
    display: 'flex',
    justifyContent: 'center',
    margin: 6
  },
  inputsRow: {
    display: 'flex',
    margin: '0px 12px',
    flexDirection: 'row',
    justifyContent: 'space-evenly'
  },
  clentsBliveError: {
    margin: 0,
    marginTop: 32,
    fontWeight: 'bold',
    fontSize: 14,
    color: 'red',
    whiteSpace: 'pre-wrap',
  },
  imgTitle: {
    fontSize: 16,
    fontWeight: '600',
    marginBottom: 0,
    marginTop: 32
  }
}));

export default ({
  title,
  onSubmit,
  notice,
  loading,
  isEdit,
  history,
  loadingSubmit
}) => {
  const classes = useStyles();
  const {
    control,
    handleSubmit,
    errors,
    setValue,
  } = useForm();

  const [loadingSend, setLoadingSend] = useState(false);

  const [mainPicture, setMainPicture] = useState(false);
  const [mainPictureFirebase, setMainPictureFirebase] = useState(false);
  const [loadingMainPicture, setLoadingMainPicture] = useState(false);
  const [errorMainPicture, setErrorMainPicture] = useState(false);
  const [uploadedMainPicture, setUploadedMainPicture] = useState(false);

  const [descriptionPicture, setDescriptionPicture] = useState(false);
  const [descriptionPictureFirebase, setDescriptionPictureFirebase] = useState(false);
  const [loadingDescriptionPicture, setLoadingDescriptionPicture] = useState(false);
  const [errorDescriptionPicture, setErrorDescriptionPicture] = useState(false);
  const [uploadedDescriptionPicture, setUploadedDescriptionPicture] = useState(false);

  const [clientsBlive, setClientsBlive] = useState([]);
  const [clientsBliveError, setClientsBliveError] = useState('');

  const [estado, setEstado] = useState(true);
  const campos = [
    { name: 'Habilitado', value: true },
    { name: 'Deshabilitado', value: false },
  ];

  useEffect(() => {
    if (notice && isEdit) {
      setValue('name', notice.name);
      setErrorMainPicture(false);
      setErrorDescriptionPicture(false);
    }
    if (notice && notice.date) setEstado(notice.enabled);
    if (notice && notice.clientsBlive && notice.clientsBlive.length > 0) setClientsBlive(notice.clientsBlive);
  }, [isEdit, notice, setValue]);

  useEffect(() => {
    if (notice && !mainPicture && !descriptionPicture) {
      if (notice.mainPicture && notice.descriptionPicture) {
        setMainPictureFirebase(notice.mainPicture);
        setDescriptionPictureFirebase(notice.descriptionPicture);

        getDownloadUrl(notice.mainPicture)
          .then((response) => {
            setMainPicture(response);
            setLoadingMainPicture(false);
          })
          .catch(() => setLoadingMainPicture(false));

        getDownloadUrl(notice.descriptionPicture)
          .then((response) => {
            setDescriptionPicture(response);
            setLoadingDescriptionPicture(false);
          })
          .catch(() => setLoadingDescriptionPicture(false));
      }
    }
  }, [notice, mainPicture, descriptionPicture]);

  const handleSubmitForm = async (values) => {
    setLoadingSend(true);

    // chequeamops que existan los clients blive
    if (!clientsBlive.length) {
      setClientsBliveError('Categorías requeridas');
      return;
    }

    if (mainPicture && mainPictureFirebase && descriptionPicture && descriptionPictureFirebase && !uploadedMainPicture && !uploadedDescriptionPicture) {
      // Ninguna de las imagenes es editada
      const imgGuardadaPrincipal = mainPictureFirebase;
      const imgGuardadaSecundaria = descriptionPictureFirebase;

      onSubmit({
        ...values,
        mainPicture: imgGuardadaPrincipal,
        descriptionPicture: imgGuardadaSecundaria,
        enabled: estado,
        clientsBlive
      });
      setLoadingSend(false);
    } else if (mainPicture && descriptionPicture && uploadedMainPicture && uploadedDescriptionPicture) {
      console.log('entre por acá, ambas editadas');
      // Ambas imagenes se editaron

      const base64mainPicture = mainPicture.slice(23);
      const base64descriptionPicture = descriptionPicture.slice(23);
      const imgGuardadaPrincipal = await uploadImage(base64mainPicture, 'notices');
      const imgGuardadaSecundaria = await uploadImage(base64descriptionPicture, 'notices');

      onSubmit({
        ...values,
        mainPicture: imgGuardadaPrincipal.metadata.fullPath,
        descriptionPicture: imgGuardadaSecundaria.metadata.fullPath,
        enabled: estado,
        clientsBlive
      });
    } else if (mainPicture && descriptionPicture && uploadedMainPicture && !uploadedDescriptionPicture) {
      // Se editó solo la primera imagen (main)
      console.log('entre por acá, solo editando el primero');

      const base64mainPicture = mainPicture.slice(23);
      const base64descriptionPicture = descriptionPictureFirebase;
      const imgGuardadaPrincipal = await uploadImage(base64mainPicture, 'notices');

      onSubmit({
        ...values,
        mainPicture: imgGuardadaPrincipal.metadata.fullPath,
        descriptionPicture: base64descriptionPicture,
        enabled: estado,
        clientsBlive
      });
    } else if (mainPicture && descriptionPicture && !uploadedMainPicture && uploadedDescriptionPicture) {
      // Se editó solo la segunda imagen (description)
      console.log('entre por acá, solo editando el segundo');

      const base64descriptionPicture = descriptionPicture.slice(23);
      const imgGuardadaSecundaria = await uploadImage(base64descriptionPicture, 'notices');
      const imgGuardadaPrincipal = mainPictureFirebase;

      onSubmit({
        ...values,
        mainPicture: imgGuardadaPrincipal,
        descriptionPicture: imgGuardadaSecundaria.metadata.fullPath,
        enabled: estado,
        clientsBlive
      });
    } else {
      setErrorMainPicture(true);
      setErrorDescriptionPicture(true);
      setLoadingSend(false);
    }
  };

  const onDropMainPicture = (file) => {
    if (!file[0]) return;

    setLoadingMainPicture(true);
    setErrorMainPicture(false);

    const reader = new FileReader();
    reader.readAsDataURL(file[0]);
    setUploadedMainPicture(true);

    reader.onloadend = async () => {
      const base64 = reader.result;
      setLoadingMainPicture(false);
      setMainPicture(base64);
    };
  };

  const onDropDescriptionPicture = (file) => {
    if (!file[0]) return;

    setLoadingDescriptionPicture(true);
    setErrorDescriptionPicture(false);

    const reader = new FileReader();
    reader.readAsDataURL(file[0]);
    setUploadedDescriptionPicture(true);

    reader.onloadend = async () => {
      const base64 = reader.result; //.slice(23);
      setLoadingDescriptionPicture(false);
      setDescriptionPicture(base64);
    };
  };

  if (loading) return (<Loading />);

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <IconButton
          onClick={() => history.goBack()}
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
        >
          <ArrowBack className={classes.icon} />
        </IconButton>
        <div className={classes.fatherDiv}>
          <div className={classes.title}>{title}</div>

          <form onSubmit={handleSubmit(handleSubmitForm)}>
            <Controller
              className={classes.input}
              name="name"
              as={(
                <TextField
                  placeholder="Nombre"
                  label="Nombre de la noticia"
                  variant="outlined"
                  error={!!errors?.name?.message}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              rules={{
                required: isEdit ? false : 'Campo requerido',
              }}
              control={control}
            />
            <p className={classes.errorInput}>{errors?.name?.message}</p>

            <Picker field={estado} setField={setEstado} names={campos} title="Estado" />

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div className={classes.inputsRow}>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <p className={classes.imgTitle}>Clientes Blive</p>
                  {clientsBliveError && (<p className={classes.clentsBliveError}>{clientsBliveError}</p>)}
                  <UploadExcel initialCodes={clientsBlive} onChange={setClientsBlive} setError={setClientsBliveError} />
                </div>
              </div>

              <div className={classes.inputsRow}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <p className={classes.imgTitle}>Imagen Principal</p>
                  {mainPicture && (
                    <div className={classes.imgContainer}>
                      <img src={mainPicture} alt="imagen" style={{ maxWidth: 200, height: 125 }} />
                    </div>
                  )}
                  {loadingMainPicture && (
                    <p style={{
                      margin: 0, marginTop: 32, fontWeight: 'bold', fontSize: 16
                    }}
                    >Cargando imagen...
                    </p>
                  )}
                  {errorMainPicture && (
                    <p style={{
                      margin: 0, marginTop: 32, fontWeight: 'bold', fontSize: 16, color: 'red'
                    }}
                    >Imagen requerida
                    </p>
                  )}
                  <ImageUploader
                    withIcon
                    singleImage
                    buttonText="Elegir imagen"
                    onChange={onDropMainPicture}
                    imgExtension={['.jpg']}
                    maxFileSize={5242880}
                    fileTypeError="debe ser .jpg"
                    label="Max file size: 5mb, accepted: .jpg"
                  />
                </div>
              </div>

              <div className={classes.inputsRow}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <p className={classes.imgTitle}>Imagen descripcion</p>
                  {descriptionPicture && (
                    <div className={classes.imgContainer}>
                      <img src={descriptionPicture} alt="imagen" style={{ maxWidth: 200, height: 125 }} />
                    </div>
                  )}

                  {loadingDescriptionPicture && (
                    <p style={{
                      margin: 0, marginTop: 32, fontWeight: 'bold', fontSize: 16
                    }}
                    >Cargando imagen...
                    </p>
                  )}
                  {errorDescriptionPicture && (
                    <p style={{
                      margin: 0, marginTop: 32, fontWeight: 'bold', fontSize: 16, color: 'red'
                    }}
                    >Imagen requerida
                    </p>
                  )}
                  <ImageUploader
                    withIcon
                    singleImage
                    buttonText="Elegir imagen"
                    onChange={onDropDescriptionPicture}
                    imgExtension={['.jpg']}
                    maxFileSize={5242880}
                    fileTypeError="debe ser .jpg"
                    label="Max file size: 5mb, accepted: .jpg"
                  />
                </div>
              </div>
            </div>

            <div className={classes.fatherDiv}>
              <Button
                className={classes.button}
                variant="outlined"
                size="large"
                type="submit"
                style={{ marginTop: 32 }}
                disabled={loadingSubmit || loadingSend}
              >
                { loadingSubmit || loadingSend ? <CircularProgress size={20} color="inherit" /> : 'Guardar' }
              </Button>
            </div>

          </form>
        </div>
      </CardContent>
    </Card>
  );
};
