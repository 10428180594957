import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateNotice, getAllNotices, singleNotice } from '../../../actions/noticesAction';

import ErrorHandling from '../../commons/errorHandling';
import Form from './components/form';

export default function ({ history, match }) {
  const dispatch = useDispatch();
  const newState = useSelector(((state) => state.noticesReducer));

  const promId = match.params.id;
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  useEffect(() => {
    dispatch(singleNotice(promId));
    // eslint-disable-next-line
  }, []);

  const onSubmit = (values) => {
    setLoadingSubmit(true);
    const {
      name, mainPicture, descriptionPicture, enabled, clientsBlive
    } = values;

    updateNotice(match.params.id, {
      name, mainPicture, descriptionPicture, enabled, clientsBlive
    })
      .then(() => {
        history.push('/private/news');
        dispatch(getAllNotices());
        setLoadingSubmit(false);
      })
      .catch(() => setLoadingSubmit(false));
  };

  if (newState.didInvalidateSingle) {
    return <ErrorHandling />;
  }

  return (
    <Form
      onSubmit={onSubmit}
      history={history}
      isEdit
      notice={newState.singleNotice}
      title="Editando Noticia"
      loading={newState.isFetchingSingle}
      loadingSubmit={loadingSubmit}
    />
  );
}
