import { createStore, applyMiddleware } from 'redux';
import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from './reducers/index';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['loginReducer'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middlewares = [thunk, promise];

if (process.env.NODE_ENV !== 'production') middlewares.push(createLogger());

const store = createStore(
  persistedReducer,
  applyMiddleware(...middlewares)
);
const persistor = persistStore(store);

export {
  store, persistor
};
