import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Add from '../../commons/button/add';
import Table from '../../commons/tables/notificationTable';
import ErrorHandling from '../../commons/errorHandling';
import useFetchData from '../../../utils/hooks/useFetchData';

import { getNotifications } from '../../../actions/notificationActions';

export default function NotificationIndex({ history }) {
  const notifications = useSelector((state) => state.notificationsReducer);
  const dispatch = useDispatch();

  useFetchData(getNotifications, notifications.list.length);

  if (notifications.didInvalidate) {
    return <ErrorHandling error={400} />;
  }

  return (
    <>
      <Table
        data={notifications.list.length ? notifications.list : []}
        loading={notifications.isFetching}
        fetchData={() => dispatch(getNotifications())}
        history={history}
        title="Notificaciones"
      />
      <Add onClick={() => history.push('/private/addNotification')} />
    </>
  );
}
