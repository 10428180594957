/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import ImageUploader from 'react-images-upload';

import { ArrowBack } from '@material-ui/icons';

import {
  TextField,
  Button,
  Card,
  CardContent,
  IconButton,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';

import { getDownloadUrl, uploadImageAndGetUrl } from '../../../../../actions/firebase';

import UploadExcel from '../../../../commons/excel';
import Picker from '../../../promotions/components/categories';
import Loading from '../../../../commons/loading';
import colors from '../../../../../assets/colors';

const useStyles = makeStyles((theme) => ({
  errorInput: {
    color: 'red',
    fontSize: 12,
    margin: 0,
    paddingLeft: '10px',
    paddingBottom: '10px',
    paddingTop: 0
  },
  input: {
    width: '100%',
    margin: theme.spacing(1),
  },
  fatherDiv: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '3%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  title: {
    fontSize: 'x-large',
    fontFamily: 'unset'
  },
  title2: {
    fontFamily: 'unset',
    textAlign: 'center',
    fontSize: 18
  },
  button: {
    width: '30vh',
    background: colors.palette.primary,
    fontFamily: 'unset',
    color: '#fafafa',
    '&:hover': {
      background: colors.palette.secondary,
      color: colors.palette.dark
    }
  },
  root: {
    minWidth: 255,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  imgContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    marginTop: 16,
    maxHeight: 200,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  inputsRow: {
    display: 'flex',
    margin: '0px 12px',
    flexDirection: 'row',
    justifyContent: 'space-evenly'
  },
  imgTitle: {
    fontSize: 16,
    fontWeight: '600',
    marginBottom: 0,
    marginTop: 32
  },
  check: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  clentsBliveError: {
    margin: 0,
    marginTop: 32,
    fontWeight: 'bold',
    fontSize: 14,
    color: 'red',
    whiteSpace: 'pre-wrap',
  },
}));

export default ({
  title,
  onSubmit,
  notice,
  loading,
  isEdit,
  history,
  loadingSubmit
}) => {
  const classes = useStyles();
  const {
    control,
    handleSubmit,
    errors,
    setValue,
  } = useForm();

  const [loadingSend, setLoadingSend] = useState(false);

  const [mainPicture, setMainPicture] = useState(false);
  const [mainPictureFirebase, setMainPictureFirebase] = useState(false);
  const [loadingMainPicture, setLoadingMainPicture] = useState(false);
  const [errorMainPicture, setErrorMainPicture] = useState(false);
  const [uploadedMainPicture, setUploadedMainPicture] = useState(false);

  // codigo de clientes blive
  const [clientsBlive, setClientsBlive] = useState([]);
  const [clientsBliveError, setClientsBliveError] = useState('');
  const [check, setCheck] = useState(true);

  useEffect(() => {
    if (notice && isEdit) {
      setValue('title', notice.title);
      setValue('description', notice.description);
      setErrorMainPicture(false);
    }

    if (notice && isEdit && notice.clientsBlive) {
      if (notice && notice.clientsBlive && notice.clientsBlive.length > 0) setClientsBlive(notice.clientsBlive);
    }
  }, [isEdit, notice, setValue]);

  useEffect(() => {
    if (notice && !mainPicture) {
      if (notice.image) {
        setMainPictureFirebase(notice.image);
        getDownloadUrl(notice.image)
          .then((response) => {
            setMainPicture(response);
            setLoadingMainPicture(false);
          })
          .catch(() => setLoadingMainPicture(false));
      }
    }
  }, [notice, mainPicture]);

  const handleSubmitForm = async (values) => {
    setLoadingSend(true);

    if (mainPicture && !uploadedMainPicture) {
      const imgGuardadaPrincipal = mainPictureFirebase;

      onSubmit({
        ...values,
        image: imgGuardadaPrincipal,
        clients: clientsBlive,
        enabled: check,
        check
      });
      setLoadingSend(false);
    } else if (mainPicture && uploadedMainPicture) {
      const imgGuardadaPrincipal = await uploadImageAndGetUrl(mainPicture.slice(23), 'notifications');
      onSubmit({
        ...values,
        image: imgGuardadaPrincipal.snapshot.metadata.fullPath,
        imageUrl: imgGuardadaPrincipal.url,
        clients: clientsBlive,
        enabled: check,
        check
      });
    } else {
      setErrorMainPicture(true);
      setLoadingSend(false);
    }
  };

  const onDropMainPicture = (file) => {
    if (!file[0]) {
      return;
    }
    setLoadingMainPicture(true);
    setErrorMainPicture(false);

    const reader = new FileReader();
    reader.readAsDataURL(file[0]);
    setUploadedMainPicture(true);

    reader.onloadend = async () => {
      const base64 = reader.result; //.slice(23);
      setLoadingMainPicture(false);
      setMainPicture(base64);
    };
  };

  //* data para chequear si se va a envair o no la notificación al crearse
  const dataState = [
    { name: 'Enviar', value: true },
    { name: 'No enviar', value: false },
  ];

  if (loading) return (<Loading />);

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <IconButton
          onClick={() => history.goBack()}
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
        >
          <ArrowBack className={classes.icon} />
        </IconButton>
        <div className={classes.fatherDiv}>
          <div className={classes.title}>{title}</div>

          <form onSubmit={handleSubmit(handleSubmitForm)} style={{ maxWidth: 600, alignSelf: 'center', margin: '0 auto' }}>
            <Controller
              className={classes.input}
              name="title"
              as={(
                <TextField
                  placeholder="Título"
                  label="Título de notificación"
                  variant="outlined"
                  error={!!errors?.title?.message}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              rules={{
                required: isEdit ? false : 'Ingresa un título',
              }}
              control={control}
            />
            <p className={classes.errorInput}>{errors?.title?.message}</p>

            <Controller
              className={classes.input}
              name="description"
              as={(
                <TextField
                  placeholder="Desccripción"
                  label="Descripción de notificación"
                  variant="outlined"
                  error={!!errors?.description?.message}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              rules={{
                required: isEdit ? false : 'Ingresa un título',
              }}
              control={control}
            />
            <p className={classes.errorInput}>{errors?.description?.message}</p>

            {
              !isEdit && (<Picker field={check} setField={setCheck} names={dataState} title="Estado" />)
            }

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

              <div className={classes.inputsRow}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <p className={classes.imgTitle}>Clientes Blive</p>
                  {clientsBliveError && (<p className={classes.clentsBliveError}>{clientsBliveError}</p>)}
                  <UploadExcel initialCodes={clientsBlive} onChange={setClientsBlive} setError={setClientsBliveError} />
                </div>
              </div>

              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className={classes.inputsRow}>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <p className={classes.imgTitle}>Imagen principal</p>
                    {mainPicture && (
                      <div className={classes.imgContainer}>
                        <img src={mainPicture} alt="imagen" style={{ maxWidth: 200, height: 125, padding: 8 }} />
                      </div>
                    )}

                    {loadingMainPicture && (
                      <p style={{
                        margin: 0, marginTop: 32, fontWeight: 'bold', fontSize: 16
                      }}
                      >Cargando imagen...
                      </p>
                    )}
                    {errorMainPicture && (
                      <p style={{
                        margin: 0, marginTop: 32, fontWeight: 'bold', fontSize: 16, color: 'red'
                      }}
                      >Imagen requerida
                      </p>
                    )}
                    <ImageUploader
                      withIcon
                      singleImage
                      buttonText="Elegir imagen"
                      onChange={onDropMainPicture}
                      imgExtension={['.jpg']}
                      maxFileSize={5242880}
                      fileTypeError="debe ser .jpg"
                      label="Max file size: 5mb, accepted: .jpg"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className={classes.fatherDiv}>
              <Button
                className={classes.button}
                variant="outlined"
                size="large"
                type="submit"
                style={{ marginTop: 32 }}
                disabled={loadingSubmit || loadingSend}
              >
                {loadingSubmit
                  ? <CircularProgress size={20} color="inherit" />
                  : 'Guardar'}
              </Button>

            </div>
          </form>
        </div>
      </CardContent>
    </Card>
  );
};
