import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBingos } from '../../../actions/bingoActions';

import Add from '../../commons/button/add';
import Table from '../../commons/tables/bingoTable';
import ErrorHandling from '../../commons/errorHandling';

export default function ({
  history,
}) {
  const bingoState = useSelector((state) => state.bingoReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBingos());
  }, [])

  if (bingoState.didInvalidate) {
    return <ErrorHandling error="network" />;
  }

  return (
    <div>
      <Table
        data={bingoState.bingosAll.length !== 0 ? bingoState.bingosAll : []}
        loading={bingoState.isFetching}
        fetchData={() => dispatch(getBingos())}
        history={history}
        title="Bingo"
      />
      <Add onClick={() => history.push('/private/addBingo')} />
    </div>
  );
}
