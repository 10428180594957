import {
  GET_ROUTES, SINGLE_ROUTE, UPDATE_ROUTE
} from '../actions/routesAction';

const INITIAL_STATE = {
  isFetching: false,
  didInvalidate: false,
  allRoutes: [],
  codeError: null,
  isFetchingSingle: false,
  didInvalidateSingle: false,
  singleRoute: {},
  updateRoute: {},
};

const routesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {

  case `${GET_ROUTES}_REJECTED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: true,
      codeError: action.payload.codeError
    };
  case `${GET_ROUTES}_PENDING`:
    return {
      ...state,
      isFetching: true,
      didInvalidate: false,
    };
  case `${GET_ROUTES}_FULFILLED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: false,
      allRoutes: action.payload.allRoutes
    };

  case `${SINGLE_ROUTE}_REJECTED`:
    return {
      ...state,
      isFetchingSingle: false,
      didInvalidateSingle: true,
      codeError: action.payload.codeError
    };
  case `${SINGLE_ROUTE}_PENDING`:
    return {
      ...state,
      isFetchingSingle: true,
      didInvalidateSingle: false,
    };
  case `${SINGLE_ROUTE}_FULFILLED`:
    return {
      ...state,
      isFetchingSingle: false,
      didInvalidateSingle: false,
      singleRoute: action.payload.singleRoute
    };

  case `${UPDATE_ROUTE}_REJECTED`:
    return {
      ...state,
      isFetchingSingle: false,
      didInvalidateSingle: true,
      codeError: action.payload.codeError
    };
  case `${UPDATE_ROUTE}_PENDING`:
    return {
      ...state,
      isFetchingSingle: true,
      didInvalidateSingle: false,
    };
  case `${UPDATE_ROUTE}_FULFILLED`:
    return {
      ...state,
      isFetchingSingle: false,
      didInvalidateSingle: false,
      updateRoute: action.payload.updateRoute
    };

  default:
    return state;
  }
};

export default routesReducer;
