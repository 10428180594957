import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Form from './components/form';
import { addNotification, getNotifications } from '../../../actions/notificationActions';

export default function ({ history }) {
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = (values) => {
    setLoadingSubmit(true);

    dispatch(addNotification(values))
      .then(() => {
        history.push('/private/notifications');
        dispatch(getNotifications());
        setLoadingSubmit(false);
      })
      .catch(() => setLoadingSubmit(false));
  };

  return (
    <Form
      history={history}
      onSubmit={onSubmit}
      title="Crear Notificación:"
      loadingSubmit={loadingSubmit}
    />
  );
}
