import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles({
  title: {
    color: "#171D33",
    fontSize: "1.25rem",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: 1.6,
    letterSpacing: "0.5px",
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
    color: "rgba(0, 0, 0, 0.64)",
    alignItems: "center",
  },
  paginationButton: {
    cursor: "pointer",
    padding: "10px",
    color: "rgba(0, 0, 0, 0.4)",
  },
  box: {
    padding: "10px",
    borderColor: "black",
  },

  form: {
    display: "flex",
    marginBottom: "10px",
    alignItems: "center",
  },
  inputBox: {
    display: "flex",
  },
  radioBox: {
    display: "flex",
    flexDirection: "column",
  },
  error: {
    position: "absolute",
    fontSize: 12,
    color: "red",
    marginLeft: "10px",
  },
  input: {
    display: "flex",
    alignSelf: "center",
  },
  cssLabel: {
    color: "#171D33",
  },

  notchedOutline: {
    borderWidth: "1px",
    borderColor: "#171D33 !important",
  },
});

export default styles;
