import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Form from './components/form';

import { getOnePuntoVenta, editPuntoVenta } from '../../../actions/puntosVentaActions';

export default function ({ history, match }) {
  const puntoVentaId = match.params.id;
  const dispatch = useDispatch();
  const singlePuntoVenta = useSelector(((state) => state.puntosVentaReducer));

  useEffect(() => {
    dispatch(getOnePuntoVenta(puntoVentaId));
  }, [dispatch, puntoVentaId]);

  const onSubmit = (inputs) => {
    dispatch(editPuntoVenta(puntoVentaId, inputs))
      .then(() => history.push(`/private/pointsSale/${puntoVentaId}`));
  };

  return (
    <Form
      data={singlePuntoVenta.singlePuntoVenta}
      title="Editar Punto de Venta"
      history={history}
      loading={singlePuntoVenta.isFetchingSingle}
      onSubmit={onSubmit}
      reducer={singlePuntoVenta}
      isEdit
    />
  );
}
