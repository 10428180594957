import React from "react";
import { useForm, Controller } from "react-hook-form";

import { ArrowBack } from "@material-ui/icons";
import {
  TextField,
  Button,
  Card,
  CardContent,
  IconButton,
  makeStyles,
  Grid,
  CircularProgress,
} from "@material-ui/core";

import colors from "../../../../../assets/colors";
import Select from "../selects/select";
import MultipleSelect from "../selects/multipleSelect";
import Loading from "../../../../commons/loading";

const useStyles = makeStyles((theme) => ({
  errorInput: {
    color: "red",
    fontSize: 12,
    margin: 0,
    paddingLeft: "10px",
    paddingBottom: "10px",
    paddingTop: 0,
  },
  input: {
    minWidth: "30vh",
    margin: theme.spacing(1),
  },
  fatherDiv: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  title: {
    height: "10vh",
    fontSize: "x-large",
    fontFamily: "unset",
  },
  button: {
    width: "30vh",
    background: colors.palette.primary,
    fontFamily: "unset",
    color: "#fafafa",
    "&:hover": {
      background: colors.palette.secondary,
      color: colors.palette.dark,
    },
  },
  root: {
    minWidth: 900,
    maxWidth: 1300,
    margin: "0 auto",
  },
  menuButton: {
    marginLeft: theme.spacing(1),
  },
  form: {
    flexGrow: 1,
  },
  div: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
}));

export default ({
  title,
  onSubmit,
  data,
  loading,
  history,
  isEdit,
  reducer,
  loadingSubmit,
}) => {
  const classes = useStyles();
  const { control, handleSubmit, errors } = useForm();

  if (loading) {
    return <Loading />;
  }

  return (
    <Card className={classes.root} variant='outlined'>
      <CardContent>
        <IconButton
          onClick={() => history.goBack()}
          edge='start'
          className={classes.menuButton}
          color='inherit'
          aria-label='menu'
        >
          <ArrowBack />
        </IconButton>
        <div className={classes.fatherDiv}>
          <div className={classes.title}>{title}</div>
          <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
            <Grid container spacing={1}>
              <Grid item xs={4} className={classes.div}>
                <Controller
                  className={classes.input}
                  as={
                    <TextField
                      placeholder='Cliente Blive'
                      label='Cliente Blive'
                      variant='outlined'
                      error={!!errors?.clientBlive?.message}
                      type='number'
                    />
                  }
                  control={control}
                  name='clientBlive'
                  defaultValue={isEdit ? data.clientBlive : ""}
                  rules={{
                    required: isEdit ? false : "Campo obligatorio",
                  }}
                />
                <p className={classes.errorInput}>
                  {errors?.clientBlive?.message}
                </p>
              </Grid>

              <Grid item xs={4} className={classes.div}>
                <Controller
                  className={classes.input}
                  as={
                    <TextField
                      placeholder='Cliente FEMSA'
                      label='Cliente FEMSA'
                      variant='outlined'
                      error={!!errors?.clientFemsa?.message}
                      type='number'
                    />
                  }
                  control={control}
                  name='clientFemsa'
                  defaultValue={isEdit ? data.clientFemsa : ""}
                  rules={{
                    required: isEdit ? false : "Campo obligatorio",
                  }}
                />
                <p className={classes.errorInput}>
                  {errors?.clientFemsa?.message}
                </p>
              </Grid>

              <Grid item xs={4} className={classes.div}>
                <Controller
                  className={classes.input}
                  as={
                    <TextField
                      placeholder='CUIT'
                      label='CUIT'
                      variant='outlined'
                      error={!!errors?.cuit?.message}
                      type='number'
                    />
                  }
                  control={control}
                  name='cuit'
                  defaultValue={isEdit ? data.cuit : ""}
                  rules={{
                    required: isEdit ? false : "Ingrese un CUIT válido",
                  }}
                />
                <p className={classes.errorInput}>{errors?.cuit?.message}</p>
              </Grid>

              <Grid item xs={4} className={classes.div}>
                <Controller
                  className={classes.input}
                  defaultValue={isEdit ? data.businessName : ""}
                  as={
                    <TextField
                      placeholder='Razón Social'
                      label='Razón Social'
                      variant='outlined'
                      error={!!errors?.businessName?.message}
                    />
                  }
                  control={control}
                  name='businessName'
                  rules={{
                    required: isEdit ? false : "Campo obligatorio",
                  }}
                />
                <p className={classes.errorInput}>
                  {errors?.businessName?.message}
                </p>
              </Grid>

              <Grid item xs={4} className={classes.div}>
                <Controller
                  className={classes.input}
                  as={
                    <TextField
                      placeholder='Domicilio'
                      label='Domicilio'
                      variant='outlined'
                      error={!!errors?.address?.message}
                    />
                  }
                  control={control}
                  name='address'
                  defaultValue={isEdit ? data.address : ""}
                  rules={{
                    required: isEdit ? false : "Ingrese un domicilio válido",
                  }}
                />
                <p className={classes.errorInput}>{errors?.address?.message}</p>
              </Grid>

              <Grid item xs={4} className={classes.div}>
                <Controller
                  className={classes.input}
                  as={
                    <TextField
                      placeholder='Localidad'
                      label='Localidad'
                      variant='outlined'
                      error={!!errors?.localidad?.message}
                    />
                  }
                  control={control}
                  name='localidad'
                  defaultValue={isEdit ? data.localidad : ""}
                  rules={{
                    required: isEdit ? false : "Ingrese una localidad válida",
                  }}
                />
                <p className={classes.errorInput}>
                  {errors?.localidad?.message}
                </p>
              </Grid>

              <Grid item xs={4} className={classes.div}>
                <Controller
                  className={classes.input}
                  as={
                    <TextField
                      placeholder='Franquicia'
                      label='Franquicia'
                      variant='outlined'
                      error={!!errors?.franchise?.message}
                    />
                  }
                  control={control}
                  name='franchise'
                  defaultValue={isEdit ? data.franchise : ""}
                  rules={{
                    required: isEdit ? false : "Ingrese una franquicia válida",
                  }}
                />
                <p className={classes.errorInput}>
                  {errors?.franchise?.message}
                </p>
              </Grid>

              <Grid item xs={4} className={classes.div}>
                <Controller
                  className={classes.input}
                  control={control}
                  defaultValue={isEdit ? data.numberPhone : ""}
                  name='numberPhone'
                  rules={{
                    required: isEdit ? false : "Ingrese una número válido",
                    minLength: 10,
                    maxLength: 15,
                  }}
                  as={
                    <TextField
                      type='number'
                      placeholder='Número de Teléfono'
                      label='Número de Teléfono'
                      variant='outlined'
                      error={!!errors?.numberPhone?.message}
                    />
                  }
                />
                <p className={classes.errorInput}>
                  {errors?.numberPhone?.message}
                </p>
                {errors?.numberPhone?.type === "minLength" && (
                  <p className={classes.errorInput}>
                    Ingrese un número mayor a 10 dígitos
                  </p>
                )}
                {errors?.numberPhone?.type === "maxLength" && (
                  <p className={classes.errorInput}>
                    Ingrese un número menor a 16 dígitos
                  </p>
                )}
              </Grid>
              {isEdit && (
                <Grid item xs={4} className={classes.div}>
                  <Controller
                    className={classes.input}
                    defaultValue={data.emailUser}
                    as={
                      <TextField
                        placeholder='Email'
                        label='Email'
                        variant='outlined'
                        error={!!errors?.emailUser?.message}
                      />
                    }
                    control={control}
                    name='emailUser'
                  />
                  <p className={classes.errorInput}>
                    {errors?.emailUser?.message}
                  </p>
                </Grid>
              )}

              <Grid item xs={4} className={classes.div}>
                <Controller
                  className={classes.input}
                  defaultValue={isEdit ? data.route : ""}
                  as={
                    <TextField
                      placeholder='Ruta'
                      label='Ruta'
                      variant='outlined'
                      error={!!errors?.route?.message}
                    />
                  }
                  control={control}
                  name='route'
                  rules={{
                    required: isEdit ? false : "Campo obligatorio",
                  }}
                />
                <p className={classes.errorInput}>{errors?.route?.message}</p>
              </Grid>

              <Grid item xs={4} className={classes.div}>
                <MultipleSelect
                  control={control}
                  defaultValue={
                    isEdit && data.deliveryDays
                      ? data.deliveryDays.map((e) => e.toLowerCase())
                      : []
                  }
                  errors={errors}
                  title='Dias de visita'
                  name='deliveryDays'
                  values={[
                    "lunes",
                    "martes",
                    "miercoles",
                    "jueves",
                    "viernes",
                    "sabado",
                    "domingo",
                  ]}
                  rules={{
                    required: isEdit ? false : "Seleccione un dia",
                  }}
                />
                <p className={classes.errorInput}>
                  {errors?.deliveryDays?.message}
                </p>
              </Grid>

              <Grid item xs={4} className={classes.div}>
                <Select
                  control={control}
                  defaultValue={isEdit ? data.atentionType : ""}
                  errors={errors}
                  title='Tipo de Atención'
                  name='atentionType'
                  rules={{
                    required: "Ingrese una tipo de atención válida",
                  }}
                  // rules={{
                  //   required: isEdit
                  //     ? false
                  //     : "Ingrese una tipo de atención válida",
                  // }}
                  values={["directa", "indirecta"]}
                />
              </Grid>

              <Grid item xs={4} className={classes.div}>
                <Select
                  control={control}
                  defaultValue={isEdit ? data.channel : ""}
                  errors={errors}
                  title='Canal'
                  name='channel'
                  rules={{
                    required: isEdit ? false : "Ingrese un canal válido",
                  }}
                  values={["almacenes", "kioscos", "self-service"]}
                />
              </Grid>

              <Grid item xs={4} className={classes.div}>
                <Select
                  control={control}
                  defaultValue={isEdit ? data.operativeUnit : ""}
                  errors={errors}
                  title='Unidad Operativa'
                  name='operativeUnit'
                  rules={{
                    required: isEdit ? false : "Ingrese un dato válido",
                  }}
                  values={["centro", "norte", "sur"]}
                />
              </Grid>

              <Grid item xs={4} className={classes.div}>
                <Select
                  control={control}
                  defaultValue={isEdit ? data.nse : ""}
                  errors={errors}
                  title='NSE'
                  name='nse'
                  rules={{
                    required: "Ingrese un dato válido",
                  }}
                  // rules={{
                  //   required: isEdit ? false : "Ingrese un dato válido",
                  // }}
                  values={["medio", "alto", "bajo"]}
                />
              </Grid>

              <Grid item xs={4} className={classes.div}>
                <Select
                  control={control}
                  defaultValue={isEdit ? data.segment : ""}
                  errors={errors}
                  title='Segmento'
                  name='segment'
                  rules={{
                    required: "Ingrese un dato válido",
                  }}
                  // rules={{
                  //   required: isEdit ? false : "Ingrese un dato válido",
                  // }}
                  values={[
                    "matanza",
                    "pareto",
                    "microzonas",
                    "resto",
                    "relevantes",
                  ]}
                />
              </Grid>

              <Grid item xs={4} className={classes.div}>
                <Select
                  control={control}
                  defaultValue={isEdit ? data.gec : ""}
                  errors={errors}
                  title='GEC'
                  name='gec'
                  rules={{
                    required: isEdit ? false : "Ingrese un dato válido",
                  }}
                  values={["bronce", "plata", "oro", "diamante"]}
                />
              </Grid>

              <Grid item xs={4} className={classes.div}>
                <Controller
                  className={classes.input}
                  as={
                    <TextField
                      placeholder='ecc_Coord_X'
                      label='ecc_Coord_X'
                      variant='outlined'
                      error={!!errors?.ecc_Coord_X?.message}
                    />
                  }
                  control={control}
                  name='ecc_Coord_X'
                  defaultValue={
                    isEdit && data.ubication ? data.ubication[0] : ""
                  }
                  rules={{
                    required: isEdit ? false : "Campo requerido",
                  }}
                />
                <p className={classes.errorInput}>
                  {errors?.ecc_Coord_X?.message}
                </p>
              </Grid>

              <Grid item xs={4} className={classes.div}>
                <Controller
                  className={classes.input}
                  as={
                    <TextField
                      placeholder='ecc_Coord_Y'
                      label='ecc_Coord_Y'
                      variant='outlined'
                      error={!!errors?.ecc_Coord_Y?.message}
                    />
                  }
                  control={control}
                  name='ecc_Coord_Y'
                  defaultValue={
                    isEdit && data.ubication ? data.ubication[1] : ""
                  }
                  rules={{
                    required: isEdit ? false : "Campo requerido",
                  }}
                />
                <p className={classes.errorInput}>
                  {errors?.ecc_Coord_Y?.message}
                </p>
              </Grid>
            </Grid>

            <div className={classes.fatherDiv}>
              <Button
                disabled={reducer.isFetching}
                className={classes.button}
                variant='outlined'
                size='large'
                type='submit'
                style={{ marginTop: 32 }}
              >
                {loadingSubmit ? (
                  <CircularProgress size={20} color='inherit' />
                ) : (
                  "Guardar"
                )}
              </Button>
            </div>
          </form>
        </div>
      </CardContent>
    </Card>
  );
};
