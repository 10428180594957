import axios from 'axios';
import api from '../utils/functions/api/index';
import endpoints from '../utils/functions/api/endpoints';
import { fromToday } from '../utils/functions/index';

const { get } = api;

export const POINT_SALE_LOGS = 'POINT_SALE_LOGS';
export const SINGLE_POINT_SALE_LOGS = 'SINGLE_POINT_SALE_LOGS';
export const PROMOTION_LOGS = 'PROMOTION_LOGS';
export const SINGLE_PROMOTION_LOGS = 'SINGLE_PROMOTION_LOGS';

export const fetchPoinSaleLogs = () => ({
  type: POINT_SALE_LOGS,
  payload: get({ url: endpoints.pointSaleLogs })
    .then((res) => ({ logs: res }))
    .catch(() => ({ logs: [], daily: [] })),
});

export const fetchSinglePoinSaleLogs = (psId) => ({
  type: SINGLE_POINT_SALE_LOGS,
  payload: get({ url: `/punto-venta/${psId}/logs?type=AddFavorite` })
    .then((res) => ({ logs: res }))
    .catch(() => ({ logs: [], daily: [] })),
});

// falta refactorear
export const fetchPromotionsLogs = () => ({
  type: PROMOTION_LOGS,
  payload: get({ url: endpoints.promotionsLogs })
    .then((logs) => axios.get({ url: `/promotions/logs?from=${fromToday()}&type=AddFavorite` })
      .then((daily) => ({ daily, logs })))
    .catch(() => ({ logs: [], daily: [] })),
});

// falta refactorear
export const fetchSinglePromotionLogs = (id) => ({
  type: SINGLE_PROMOTION_LOGS,
  payload: axios.get(`/promotions/${id}/logs?type=AddFavorite`)
    .then((res) => res.data)
    .then((logs) => axios.get(`/promotions/${id}/logs?from=${fromToday()}&type=AddFavorite`)
      .then((res) => res.data)
      .then((daily) => ({
        daily, logs
      })))
    .catch(() => ({ logs: [], daily: [] })),
});
