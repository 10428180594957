/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react';
import XLSX from 'xlsx';

import { makeStyles } from '@material-ui/core';
import uploadFile from './upload.svg';
import useToast from '../toast';

const useStyles = makeStyles(() => ({
  fileInput: {
    fontSize: 0,
    width: 0,
  },
  fileLabel: {
    padding: '6px 23px',
    background: '#3f4257',
    borderRadius: 30,
    color: 'white',
    fontWeight: 300,
    fontSize: 14,
    margin: '10px 0',
    transition: 'all 0.2s ease-in',
    cursor: 'pointer',
    outline: 'none',
    border: 'none',
    display: 'inline-block',
    lineHeight: '14px',
    '&:hover': {
      background: '#545972',
    }
  },
  fileImage: {
    width: 50,
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
    flexDirection: 'column',
    background: '#fff',
    boxShadow: '2px 2px 3px 0 rgba(0, 0, 0, 0.05)',
    position: 'relative',
    borderRadius: 10,
    padding: '20px',
    transition: 'all 0.3s ease-in',
    margin: '10px 0',
  },
  acceptedFiles: {
    fontSize: 12,
    margin: '8px 0 4px',
  },
  errorText: {
    margin: 0,
    marginTop: 32,
    fontWeight: 'bold',
    fontSize: 14,
    color: 'red',
    whiteSpace: 'pre-wrap',
  },
  codesPreview: {
    alignSelf: 'center',
    maxWidth: 170,
    fontSize: 10,
    textAlign: 'center'
  }
}));

export default function ({ onChange, initialClients, setError, getDataExcel }) {
  const classes = useStyles();
  const [client, setClients] = useState(null);
  const { Toast, call } = useToast();

  useEffect(() => {
    setClients(initialClients);
  }, [initialClients]);

  const validateRowExcel = (data) => {
    const validateHeaders = ['clientBlive','line_1', 'line_2','mission_1', 'mission_2','mission_3','mission_4','mission_5','mission_6','mission_7','mission_8','mission_9','mission_10']
    let withError = false
    if (data.length === 0){
       withError = true
       setError('El excel contiene errores.')
      return call("El excel debe tener un contenido.", 'warning')
    }
    data.forEach((objRaw) => {
      if (Object.keys(objRaw).length !== 13){
        withError = true
        setError('El excel contiene errores.')
        return call("Los nombres del excel son incorrectos.", 'warning')
      }else {
        Object.keys(objRaw).forEach((keyraw) => {
          if (!validateHeaders.includes(keyraw)){
            withError = true
            setError('El excel contiene errores.')
            return call("Los nombres del excel son incorrectos.", 'warning')
          }
        })
      }
    })
    if (!withError){
      getDataExcel(data)
      setError(false)
      call('Se cargo el archivo correctamente')
    }else{

    }
  }

  function onUpload(e) {
    e.preventDefault();
    const { files, value } = e.target;
    if (!value.match(/.xlsx/g)) return setError('Debe seleccionar un archivo xlsx');
    const reader = new FileReader();

    reader.onload = function (ev) {
      const workbook = XLSX.read(ev.target.result, { type: 'binary' });
      workbook.SheetNames.forEach(function (sheetName) {
        let XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
        validateRowExcel(XL_row_object)
        console.log('XL_row_object', XL_row_object)
      })
    };
    reader.readAsBinaryString(files[0]);
  }


  
  return (
    <>
      <Toast />
      <div className={classes.inputContainer}>

        <img src={uploadFile} alt="Subir un archivo" className={classes.fileImage} />
        <p className={classes.acceptedFiles}>{client && client.length ? 'Archivo seleccionado' : 'Acepted: .xlsx'}</p>

        <label htmlFor="inputFile" className={classes.fileLabel}>
          <input type="file" id="inputFile" onChange={onUpload} className={classes.fileInput} />
          Elegir archivo
        </label>
      </div>
    </>
  );
}
