import api from '../utils/functions/api/index';
import endpoints from '../utils/functions/api/endpoints';

const {
  get, post, put, deletes
} = api;

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const SINGLE_NOTIFICATION = 'SINGLE_NOTIFICATION';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const SEND_NOTIFICATION = 'SEND_NOTIFICATION';

export const getNotifications = () => ({
  type: GET_NOTIFICATIONS,
  payload: get({ url: endpoints.notificationsAll })
    .then((res) => ({ list: res })),
});

export const singleNotification = (id) => ({
  type: SINGLE_NOTIFICATION,
  payload: get({ url: endpoints.notificationsSingle(id) })
    .then((res) => ({ single: res }))
});

export const addNotification = (body) => ({
  type: ADD_NOTIFICATION,
  payload: post({ url: endpoints.createNotification, body })
});

export const updateNotification = (id, body) => (
  put({ url: endpoints.editNotification(id), body })
);

export const sendNotification = (id) => ({
  type: SEND_NOTIFICATION,
  payload: get({ url: endpoints.sendNotification(id), body: { id } })
});

export const deleteNotification = (id) => async (dispatch) => {
  const fetch = await deletes({ url: endpoints.deleteNotification(id) });
  const request = get({ url: endpoints.notificationsAll })
    .then((res) => ({ list: res }));

  request
    .then(() => {
      dispatch({
        type: GET_NOTIFICATIONS,
        payload: request,
      });
    });

  return fetch;
};
