import {
  GET_USERS, SINGLE_USER, UPDATE_USER
} from '../actions/userActions';

const INITIAL_STATE = {
  isFetching: false,
  didInvalidate: false,
  usersAll: [],
  codeError: null,
  isFetchingSingle: false,
  didInvalidateSingle: false,
  singleUser: {},
  updateUser: {},
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {

  case `${GET_USERS}_REJECTED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: true,
      codeError: action.payload.codeError
    };
  case `${GET_USERS}_PENDING`:
    return {
      ...state,
      isFetching: true,
      didInvalidate: false,
    };
  case `${GET_USERS}_FULFILLED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: false,
      usersAll: action.payload.usersAll
    };


  case `${SINGLE_USER}_REJECTED`:
    return {
      ...state,
      isFetchingSingle: false,
      didInvalidateSingle: true,
      codeError: action.payload.codeError
    };
  case `${SINGLE_USER}_PENDING`:
    return {
      ...state,
      isFetchingSingle: true,
      didInvalidateSingle: false,
    };
  case `${SINGLE_USER}_FULFILLED`:
    return {
      ...state,
      isFetchingSingle: false,
      didInvalidateSingle: false,
      singleUser: action.payload.singleUser
    };

  case `${UPDATE_USER}_REJECTED`:
    return {
      ...state,
      isFetchingSingle: false,
      didInvalidateSingle: true,
      codeError: action.payload.codeError
    };
  case `${UPDATE_USER}_PENDING`:
    return {
      ...state,
      isFetchingSingle: true,
      didInvalidateSingle: false,
    };
  case `${UPDATE_USER}_FULFILLED`:
    return {
      ...state,
      isFetchingSingle: false,
      didInvalidateSingle: false,
      updateUser: action.payload.updateUser
    };

  default:
    return state;
  }
};

export default userReducer;
