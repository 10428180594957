import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Drawer, Toolbar } from '@material-ui/core';

import ListDrawer from './listDrawer';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    backgroundColor: '#FFF'
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    flexDirection: 'column',
    backgroundColor: '#f3f3f3'
  },
  imgCompany: {
    height: 100,
    width: 100,
    borderRadius: '50%',
  },
  loadingContainer: {
    height: 100,
    width: 100,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFF'
  },
  username: {
    fontFamily: "'Sarabun', sans-serif",
    fontStyle: 'normal',
    fontWeight: '600',
    letterSpacing: 0.5,
    color: '#171D33'
  }
}));

export default () => {
  const classes = useStyles();

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Toolbar />

      <ListDrawer />
    </Drawer>
  );
};
