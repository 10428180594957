import React, { useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

export default function useToast() {
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState('info');
  const [text, setText] = useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setOpen(false);
  };

  return {
    Toast: () => (
      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
        <MuiAlert elevation={6} variant="filled" severity={severity} onClose={handleClose}>
          { text }
        </MuiAlert>
      </Snackbar>
    ),
    call: (toastText, toastSeverity) => {
      setSeverity(toastSeverity);
      setText(toastText);
      setOpen(true);
    }
  };
}
