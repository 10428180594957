import { ALL_PROMOTIONS, SINGLE_PROMOTION } from '../actions/promotionsActions';

const INITIAL_STATE = {
  isFetching: false,
  didInvalidate: false,
  allPromotions: [],
  isFetchingSingle: false,
  didInvalidateSingle: false,
  singlePromotion: {},
};

const providers = (state = INITIAL_STATE, action) => {
  switch (action.type) {

  case `${ALL_PROMOTIONS}_REJECTED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: true,
    };
  case `${ALL_PROMOTIONS}_PENDING`:
    return {
      ...state,
      isFetching: true,
      didInvalidate: false,
    };
  case `${ALL_PROMOTIONS}_FULFILLED`:
    return {
      ...state,
      isFetching: false,
      didInvalidate: false,
      allPromotions: action.payload.allPromotions
    };

  case `${SINGLE_PROMOTION}_REJECTED`:
    return {
      ...state,
      isFetchingSingle: false,
      didInvalidateSingle: true,
    };
  case `${SINGLE_PROMOTION}_PENDING`:
    return {
      ...state,
      isFetchingSingle: true,
      didInvalidateSingle: false,
      singlePromotion: {},
    };
  case `${SINGLE_PROMOTION}_FULFILLED`:
    return {
      ...state,
      isFetchingSingle: false,
      didInvalidateSingle: false,
      singlePromotion: action.payload.singlePromotion
    };

  default:
    return state;
  }
};

export default providers;
