import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Form from './components/form';
import { addNotice, getAllNotices } from '../../../actions/noticesAction';

export default function ({ history }) {
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = (values) => {
    setLoadingSubmit(true);

    dispatch(addNotice(values))
      .then(() => {
        history.push('/private/news');
        dispatch(getAllNotices());
        setLoadingSubmit(false);
      })
      .catch(() => setLoadingSubmit(false));
  };

  return (
    <Form
      history={history}
      onSubmit={onSubmit}
      title="Crear noticia:"
      loadingSubmit={loadingSubmit}
    />
  );
}
