/* eslint-disable no-useless-escape */
import React from 'react';
import MaterialTable from 'material-table';
import moment from 'moment';
import { Edit, Visibility } from '@material-ui/icons';

import useToast from '../toast';
import tableIcons from './utilsTable/tableIcons';
import TitleContainer from './utilsTable/titleWithFetchData';
import colors from '../../../assets/colors';

import { parseDate, filterDate } from '../../../utils/functions/index';

const columns = [
  { title: 'Titulo', field: 'name' },
  {
    title: 'Estado',
    field: 'enabled',
    render: (rowData) => rowData.enabled ? 'Habilitado' : 'Deshabilitado',
    lookup: { true: 'Habilitado', false: 'Deshabilitado' }
  },
  {
    title: 'Fecha',
    field: 'date',
    render: (rowData) => parseDate(rowData.date),
    customFilterAndSearch: (term, rowData) => filterDate(term, rowData.date),
  }
];

export default ({
  data,
  loading,
  noRefresh,
  fetchData,
  history,
  title
}) => {
  const { Toast } = useToast();
  return (
    <>
      <Toast />
      <MaterialTable
        style={{ marginBottom: 16 }}
        icons={tableIcons}
        columns={columns}
        data={data}
        isLoading={loading}
        locale="es"
        title={(
          <TitleContainer
            title={title}
            noRefresh={noRefresh}
            fetchData={fetchData}
          />
        )}
        actions={[
          {
            icon: () => <Visibility style={{ color: colors.palette.dark }} />,
            tooltip: 'Ver novedades',
            onClick: (event, rowData) => history.push(`/private/new/${rowData._id}`),
          },
          {
            icon: () => <Edit style={{ color: colors.palette.dark }} />,
            tooltip: 'Editar novedades',
            onClick: (event, rowData) => {
              history.push(`/private/editNew/${rowData._id}`);
            }
          },
        ]}
        options={{
          pageSize: 10,
          pageSizeOptions: [10, 20, 30, 40],
          actionsColumnIndex: -1,
          filtering: true,
          exportButton: true,
          headerStyle: {
            fontStyle: 'normal',
            fontWeight: '700',
            letterSpacing: 0.2,
            fontSize: '0.75rem',
            lineHeight: '1.3125rem',
            color: colors.palette.dark
          },
          cellStyle: {
            fontFamily: "'Sarabun', sans-serif",
            fontStyle: 'normal',
            fontWeight: '500',
            letterSpacing: 0.2,
            color: colors.palette.dark
          },
          rowStyle: {
            fontFamily: "'Sarabun', sans-serif",
            fontStyle: 'normal',
            fontWeight: '500',
            letterSpacing: 0.2,
            color: '#171D33',
          },
          search: false,
          // eslint-disable-next-line no-shadow
          exportCsv: (columns, dataExport) => {
            const test = [[
              'Titulo',
              'Fecha'
            ],
            ...dataExport.map((column) => [
              column.name ? `\"${column.name}\"` : '---',
              column.date ? `\"${moment(column.date).format('DD/MM/YYYY')}\"` : '---',
            ])
            ];

            const encoding = 'data:text/csv;charset=utf-8,%EF%BB%BF';
            let csvContent = 'sep=,\n';
            csvContent += `${test.map((e) => e.join(',')).join('\n')}`;
            const link = document.createElement('a');
            link.setAttribute('href', encoding + escape(csvContent));
            link.setAttribute('download', `${title}.csv`);
            document.body.appendChild(link); // Required for FF
            link.click();
          }
        }}
        localization={{
          toolbar: {
            searchPlaceholder: 'Buscar...',
            exportTitle: 'Exportar',
            exportName: 'Exportar a CSV'
          },
          header: {
            actions: 'Acciones'
          },
          pagination: {
            labelRowsSelect: 'filas'
          },
          body: {
            emptyDataSourceMessage: 'No hay novedades que mostrar'
          }
        }}
      />
    </>
  );
};
