export default {
  noticeAll: "/notices/all",
  createNew: "/notices/add",
  singleNew: (id) => `/notices/${id}`,
  editNew: (id) => `/notices/edit/${id}`,
  deleteNew: (id) => `/notices/changeStatus/${id}`,

  couponsAll: "/coupon/all-coupons",
  createCoupon: "/coupon/add",
  couponSingle: (id) => `/coupon/${id}`,
  editCoupon: (id) => `/coupon/edit/${id}`,
  deleteCoupon: (id) => `/coupon/delete/${id}`,

  notificationsAll: "/notifications",
  createNotification: "/notifications/add",
  sendNotification: (id) => `/notifications/send-notification/${id}`,
  notificationsSingle: (id) => `/notifications/${id}`,
  editNotification: (id) => `/notifications/edit/${id}`,
  deleteNotification: (id) => `/notifications/changeStatus/${id}`,

  promotionsAll: "/promotions/all",
  createPromotion: "/promotions/add",
  promotionSingle: (id) => `/promotions/${id}`,
  editPromotion: (id) => `/promotions/edit/${id}`,
  deletePromotion: (id) => `/promotions/delete/${id}`,

  psAll: (page) => `/punto-venta/all?page=${page}`,
  psAllById: (search) => `/punto-venta/all?${search}`,
  sendPsWs: "/psws",
  createPs: "/punto-venta/register",
  sendPsWsSingle: (id) => `/psws/${id}`,
  psSingle: (id) => `/punto-venta/${id}`,
  editPs: (id) => `/punto-venta/edit/${id}`,
  deletePS: (id) => `/punto-venta/delete/${id}`,

  routesAll: "/routes/all",
  createRoute: "/routes/add",
  routesSingle: (id) => `/routes/${id}`,
  editRoute: (id) => `/routes/edit/${id}`,
  deleteRoute: (id) => `/routes/changeStatus/${id}`,

  sellersAll: "/sellers/all",
  createSellers: "/sellers/add",
  sellersSingle: (id) => `/sellers/${id}`,
  editSeller: (id) => `/sellers/edit/${id}`,
  deleteSeller: (id) => `/sellers/changeStatus/${id}`,

  pointSaleLogs: "/punto-venta/logs?type=AddFavorite",
  promotionsLogs: "/promotions/logs?type=AddFavorite",

  userAll: "/backoffice/",
  loginUser: "/backoffice/login",
  createUser: "/backoffice/register",
  userSingle: (id) => `/backoffice/${id}`,
  editUser: (id) => `/backoffice/edit/${id}`,
  deleteUser: (id) => `/backoffice/delete/${id}`,

  bingosAll: "/bingos/all",
  createBingo: "/bingos/add",
  bingoSingle: (id) => `/bingos/${id}`,
  editBingo: (id) => `/bingos/edit/${id}`,
  deleteBingo: (id) => `/bingos/delete/${id}`,

  stats: "/stats/customer-statistics",
  statsAllRegisteredPointSale: "/stats/all-registered/",
  statsNotRegisteredPointSale: "/stats/not-registered/",
};
