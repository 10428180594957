import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { ArrowBack } from '@material-ui/icons';

import {
  Card, CardContent, Typography, IconButton, CircularProgress, CardActions, makeStyles
} from '@material-ui/core';


import { getDownloadUrl } from '../../../actions/firebase';
import { singleNotice, deleteNotice } from '../../../actions/noticesAction';

import Loading from '../../commons/loading';
import ButtonCustom from '../../commons/button';
import ButtonDialog from '../../commons/button/dialog';
import img_error from '../../../assets/images/err/error_image.jpg';

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  children: {
    width: '100%'
  },
  infoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  containerData: {
    display: 'flex',
    margin: '0 auto',
    width: '80%',
    marginTop: 24,
    justifyContent: 'space-evenly'
  },
  containerImg: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-around',
    marginBottom: 20,
    minHeight: 242,
    marginTop: 12,
  },
  root: {
    minWidth: 900,
    maxWidth: 1300,
    margin: '0 auto',
  },
  title: {
    fontSize: 24,
    textAlign: 'center'
  },
  principal: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  img: {
    display: 'block',
    width: '35vh',
    height: 242,
    margin: '0 12px',
  },
  menuButton: {
    marginLeft: 12
  },
  description: {
    color: 'hsla(0, 0%, 0%, 0.54)',
    fontSize: 16,
    textTransform: 'capitalize'
  },
  info: {
    margin: 12,
    fontSize: 18
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'center'
  }
});

export default function ({ match, history }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const newState = useSelector(((state) => state.noticesReducer));

  const [image, setImage] = useState(null);
  const [secondImage, setSecondImage] = useState(null);

  const [loadingImage, setLoadingImage] = useState(true);
  const [loadingSecondImage, setLoadingSecondImage] = useState(true);

  const { id } = match.params;

  useEffect(() => {
    dispatch(singleNotice(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (newState.singleNotice.mainPicture) {
      getDownloadUrl(newState.singleNotice.mainPicture)
        .then((img) => {
          setImage(img);
          setLoadingImage(false);
        })
        .catch(() => setLoadingImage(false));
    }
    if (newState.singleNotice.descriptionPicture) {
      getDownloadUrl(newState.singleNotice.descriptionPicture)
        .then((img) => {
          setSecondImage(img);
          setLoadingSecondImage(false);
        })
        .catch(() => setLoadingImage(false));
    }

    return function cleanUp() {
      setImage(null);
      setSecondImage(null);
    };
  }, [newState]);

  const handleDeleteNew = async () => {
    dispatch(deleteNotice(id));
    history.push('/private/news');
  };

  const editCoupon = () => history.push(`/private/editNew/${id}`);
  if (newState.isFetchingSingle) return <Loading />;

  return (
    <>
      <div className={classes.container}>
        <Card className={classes.root}>
          <CardContent>
            <IconButton
              onClick={() => history.goBack()}
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
            >
              <ArrowBack />
            </IconButton>
            <Typography className={classes.title} color="textSecondary" gutterBottom>Detalle de la Noticia</Typography>
            <div className={classes.infoContainer}>

              <div className={classes.containerImg}>
                {loadingImage ? <CircularProgress /> : <img className={classes.img} src={!image && !loadingImage ? img_error : image} alt="img" />}
                {loadingSecondImage ? <CircularProgress /> : <img className={classes.img} src={!secondImage && !loadingSecondImage ? img_error : secondImage} alt="img" />}
              </div>

              <div className={classes.containerData}>
                <div className={classes.children}>
                  <div className={classes.info}>
                    Título: &nbsp;
                    <span className={classes.description}>{newState.singleNotice.name}</span>
                  </div>
                  <div className={classes.info}>
                    Disponibilidad &nbsp;
                    <span className={classes.description}>{newState.singleNotice.enabled ? 'Se encuentra activa' : 'Se encuentra desabilitada. '}</span>
                  </div>
                </div>

                <div className={classes.children}>
                  <div className={classes.info}>
                    Fecha de publicación: &nbsp;
                    <span className={classes.description}>{moment(newState.singleNotice.date).format('DD/MM/YYYY')}</span>
                  </div>
                </div>

              </div>
            </div>
          </CardContent>
          <CardActions className={classes.btnContainer}>
            <ButtonCustom edit text="Editar" color="default" action={editCoupon} />
            <ButtonDialog
              action={handleDeleteNew}
              active={newState.singleNotice && (newState.singleNotice.enabled)}
              title={newState.singleNotice.enabled ? 'Deshabilitar Noticia?' : 'Habilitar Noticia'}
              description="Desea confirmar la operación?"
            />
          </CardActions>
        </Card>
      </div>
    </>
  );
}
